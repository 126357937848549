import React, { useState, useRef, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Button from './Button'
import { BeatLoader } from "react-spinners";
import { Menu, MenuItem } from '@material-ui/core'
import { FreckleTooltip } from './FreckleTooltip'

const moment = require('moment');

const Item = styled.div`
	width: 100%;
	height: 200px;
	background-color: #1f1f1f;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	transition: 0.2s;
	border-radius: 10px;
	overflow: hidden;

	&:hover {
		background-color: #222222;
	}
`

const placeHolderShimmer = keyframes`
 	0%{
        background-position: 0% 0%;
    }
    100%{
        background-position: 400% 0%;
    }
`

const PreviewImageContainer = styled.div`
	overflow: hidden;
	background-color: transparent;
	outline: none;
	border: none;
	height: 100%;
	width: 250px;
	background: linear-gradient(90deg, #a1a1a1, #a1a1a1, #8a8a8a, #a1a1a1, #a1a1a1);
	background-size: 400% 400%;
	background-position: 0% 0%;
	pointer-events: none;

	animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
`

const PreviewImage = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
`

const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	margin-left: 35px;
	flex: 1;
	pointer-events: none;
`

const RightPane = styled.div`
	height: 100%;
	width: 100px;
	flex: 0;
	pointer-events: none;
`

const ButtonContainer = styled.div`
	display: flex;
	padding: 0;
	margin: 0;
	margin-top: 20px;
	pointer-events: none;
`

const Buttons = styled.div`
	display: flex;
	gap: 10px;
	pointer-events: auto;
`

const Title = styled.h2`
	margin: 0;
	padding: 0px 0 10px 0;
	color: white;
	pointer-events: none;
`

const Subtitle = styled.h4`
	padding: 5px 0;
	margin: 0;
	font-weight: 500;
	opacity: 0.8;
	display: flex;
	align-items: center;
	pointer-events: none;
`

const Icon = styled.img`
	max-height: 10px;
	max-width: 10px;
	margin-right: 5px;
	opacity: 0.5;
	pointer-events: none;
`

const DetailsButtonIcon = styled.img`
	max-width: 20px;
	opacity: 0.5;
	pointer-events: none;
`

const DetailsButton = styled.button`
	border: none;
	outline: none;
	background-color: transparent;
	border-radius: 1000px;
	height: 40px;
	width: 40px;
	transition: 0.2s;
	margin-top: 10px;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	pointer-events: auto;

	&:hover {
		background-color: #4f4f4f;
	}
`

export default function TemplateItem(props){
	const { microsite } = props
	const publishEnabled = (microsite.publishChecksum !== microsite.saveChecksum || microsite.project.updatedAt > microsite.publishedAt)

	const [showingMenu, setShowingMenu] = useState(false)

	const menuButtonRef = useRef(null)

	const copyToClipboard = (str) => {
		const el = document.createElement('textarea');
		el.value = str;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);

		document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text": `${microsite.title} link copied!`, "type":"success"}}))
	}

	return(
		<Item 
		aria-label="clickarea"
		{...props}
		onClick={(e)=>{
			if(e.target.ariaLabel === "clickarea") {
				props.didSelectItem()
			}
		}}>
		<PreviewImageContainer>
			<PreviewImage src={`https://image.mux.com/${microsite.project.muxPlaybackId}/thumbnail.jpg?width=500&height=500`}/>
		</PreviewImageContainer>
		<Details>
			<Title>{ microsite.title }</Title>
			<Subtitle><Icon src="/videos-icon.svg"/> { microsite.project.title }</Subtitle>
			<Subtitle><Icon src="/clock.svg"/> { !!microsite.publishedAt ? `Last Published ${moment(microsite.publishedAt).fromNow()}` : "Not Published" }</Subtitle>
			<ButtonContainer>
				<Buttons>
					<FreckleTooltip arrow title={!!microsite.publishedAt ? "Copy the URL for your published landing page." : "Copy the URL for your published landing page. (Disabled until a version is published!)"}>
						<div>
						<Button 
						onClick={()=> copyToClipboard(`https://watch.${props.baseUrl}/${microsite.uuid}`) }
						disabled={!!!microsite.publishChecksum}>COPY LINK</Button>
						</div>
					</FreckleTooltip>

					<FreckleTooltip arrow title={publishEnabled ? "Publishing makes any saved changes to your landing page visible to anyone with a link.  Unpublished changes are only visible to you." : "There have been no changes to publish."}>
						<div>
						<Button 
						onClick={props.didSelectPublish}
						disabled={!publishEnabled}>
						{
							(props.publishingMicrositeId === microsite.id) ? <BeatLoader color="#ffffff" size="5px"/> : "PUBLISH"
						}
						</Button>
						</div>
					</FreckleTooltip>
				</Buttons>
			</ButtonContainer>
		</Details>

		<RightPane>
			<DetailsButton 
			onClick={()=> setShowingMenu(true) }
			ref={menuButtonRef}>
			<DetailsButtonIcon src="/menu-icon.svg"/>
			</DetailsButton>
		</RightPane>

		<Menu
		className="freckle-menu"
		anchorEl={menuButtonRef.current}
		open={showingMenu}
		onClose={()=>setShowingMenu(false)}
		>
		<MenuItem
		className="freckle-menu-item"
		onClick={props.didSelectItem}>Edit Microsite</MenuItem>
		{!!microsite.publishChecksum && <MenuItem
		className="freckle-menu-item"
		onClick={()=>{
			copyToClipboard(`https://w.freckle.shop/${microsite.uuid}`) 
			setShowingMenu(false)
		}}>Copy Link</MenuItem>}
		<MenuItem
		className="freckle-menu-item red"
		onClick={()=>{
			props.onDelete()
			setShowingMenu(false)
		}}>Delete</MenuItem>
		</Menu>

		</Item>
	)
}