import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import PickerMenu from '../../PickerMenu'
import { ResponsiveChoropleth } from '@nivo/geo'
import { ios2to3, iso3to2 } from '../../../helpers/iso2to3.js'
import { abbreviateNumber } from '../../../helpers/abbreviate'
const axios = require("axios")

const RankItemContainer = styled.div`
display: flex;
flex-direction: column;
width: calc(100% - 50px);
height: 60px;
align-items: center;
justify-content: center;
padding: 0px 25px;
`

const RankItemTextContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
`

const FlagImg = styled.img`
height: 20px;
margin-right: 10px;
`

const RankItemTitle = styled.h4`
padding: 0;
margin:0;
font-size: 14px;
display: flex;
align-items: center;
`

const ProgressContainer = styled.div`
margin-top: 10px;
width: 100%;
height: 5px;
display: flex;
align-items: center;
justify-content: flex-start;
padding: 0 20px;
`
const ProgressBar = styled.div`
width: ${props=> props.progress + "%"};
height: 2px;
background-color: #4A90E2;
border-radius: 100px;
`

const ValueLabel = styled.h4`
padding: 0;
margin:0;
font-size: 14px;
`

// TODO: move progress bar under text so its consistent, add country flag next to location name

function RankItem(props){
	return(
		<RankItemContainer>
			<RankItemTextContainer>
				<RankItemTitle>
				<FlagImg src={`https://flagcdn.com/32x24/${props.countryCode.toLowerCase()}.png`}/>
				{ props.location }
				</RankItemTitle>
				<ValueLabel>{ props.value }</ValueLabel>
			</RankItemTextContainer>
			<ProgressContainer>
				<ProgressBar progress={props.progress}/>
			</ProgressContainer>
		</RankItemContainer>
	)
}


const Container = styled.div`
border-radius: 8px;
background-color: #1F1F1F;
overflow: hidden;
display: flex;
flex-direction: row;
max-height: 450px;
height: 50vh;
`

const LeftContainer = styled.div`
background-color: #1F1F1F;
overflow: hidden;
display: flex;
flex-direction: column;
width: 40%;
`

const RightContainer = styled.div`
width: 60%;
background-color: #2F2F2F;
`

const Header = styled.div`
background-color: #222222;
height: 60px;
padding: 0 20px;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
`

const Title = styled.h4`
font-family: Signika;
font-weight: 700;
font-size: 14px;
color: rgba(255,255,255,0.5);
`

const Content = styled.div`
width: 100%;
height: 100%;
overflow: scroll;
`

const RankContainer = styled.div`
display: flex;
flex-direction: column;
padding: 10px 0;
`

const MapTooltip = styled.div`
background-color: #3f3f3f;
color: white;
padding: 10px;
border-radius: 6px;
display: flex;
justify-content: center;
align-items: center;
font-weight: 700;
&:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    margin-top: -0.5px;
    width: 0;
    height: 0;
    border-top: solid 8px #3f3f3f;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
}
`

export default function MapItem(props){
	const [features, setFeatures] = useState(null)

	const getPicker = () => {
		if(!props.options) return <span/>
		return(
			<PickerMenu 
			options={props.options} 
			selectedOptionId={props.selectedOptionId}
			onChange={props.onChange}/>
		)
	}

	useEffect(()=>{
		axios.get("/map_features.json").then(resp=>{
			setFeatures(resp.data)
		})
	}, [])

	const maxValue = Math.max(...props.data.map(item=> parseFloat(item[props.selectedOptionId])))
	const mapDataHash = {}
	props.data.forEach(item=>{
		const id = ios2to3[item.countryCode]

		if(!!mapDataHash[id]) {
			mapDataHash[id] += parseFloat(item[props.selectedOptionId])
		} else {
			mapDataHash[id] = parseFloat(item[props.selectedOptionId])
		}
	})
	const mapData = Object.keys(mapDataHash).map(key=>{
		return {
			id: key,
			value: mapDataHash[key]
		}
	})

	return(
		<Container>
			<LeftContainer>
				<Header>
				{ getPicker() }
				<Title>{ props.title }</Title>
				</Header>
				<Content>
					<RankContainer>
					{
						props.data.sort((a,b)=> b[props.selectedOptionId] - a[props.selectedOptionId]).map(locationData=>{
							const locationString = `${locationData.city}, ${locationData.region} (${locationData.countryCode})`
							const value = parseFloat(locationData[props.selectedOptionId])
							const progress = 100 * value / maxValue
							return <RankItem location={locationString} countryCode={locationData.countryCode} value={value} progress={progress}/>
						})
					}
					</RankContainer>
				</Content>
			</LeftContainer>
			<RightContainer>
				{!!features && <ResponsiveChoropleth
					tooltip={(input)=>{
						if(input.feature.data){
							return <MapTooltip>
							<FlagImg src={`https://www.countryflags.io/${iso3to2[input.feature.id]}/flat/64.png`}/>
							{ abbreviateNumber(parseFloat(input.feature.data.value)) }
							</MapTooltip>
						} else {
							return <span/>
						}
					}}
			        data={mapData}
			        features={features}
			        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
			        colors="nivo"
			        domain={[ 0, maxValue ]}
			        unknownColor="#666666"
			        label="properties.name"
			        valueFormat=".2s"
			        projectionTranslation={[ 0.5, 0.5 ]}
			        projectionRotation={[ 0, 0, 0 ]}
			        enableGraticule={false}
			        borderWidth={0.5}
			        borderColor="#152538"
			    />}
			</RightContainer>
		</Container>
	)
}

