import React, { Component } from 'react'
import Editor from './js/components/Editor/Editor'
import InteractiveEditorTutorial from "./js/components/editor-tutorial/InteractiveEditorTutorial"
import Library from './js/components/Library'
import Home from './js/components/Home'
import AddStoreView from './js/components/AddStoreView'
import MicrositeEditor from './js/components/microsites/MicrositeEditor'
import LoadingView from './js/components/LoadingView'
import VerifyResult from './js/components/VerifyResult'
import ResetPassword from './js/components/ResetPassword'
import CreateAccountFromInvite from './js/components/CreateAccountFromInvite'

import TrackTest from './js/components/TrackTest'

import { AppContext, AppAction } from './js/components/Context/AppContext'


import { SETTINGS_PAGE, PRODUCTS_PAGE, VIDEOS_PAGE, ANALYTICS_PAGE } from './js/components/Library'
import Activate from './js/components/Activate'

import { allVideos, createVideo, deleteVideo, publishVideo, allTemplates } from './js/modules/freckle-api.js'

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import localforage from "localforage"

import './App.scss';

import FreckleSignIn from './js/components/SignIn'
import CreateAccount from './js/components/CreateAccount'
import PlanPickerView from './js/components/PlanPickerView'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { Redirect } from 'react-router'

import { createBrowserHistory } from "history";

import Pusher from 'pusher-js';

const history = createBrowserHistory();

export default class App extends Component {
  static contextType = AppContext

  constructor(props, context) {
    console.log("creating app")
    super(props, context);

    this.state = {
      loggedIn: false,
      messages: [],
      uploads: {
        
      }
    }

    localforage.config({
      driver      : [localforage.INDEXEDDB],
      name        : 'FreckleStudio',
      version     : 1.0,
      size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
      storeName   : 'merchant_store', // Should be alphanumeric, with underscores.
      description : ''
    });

  }

  subscribeToPusher = (user) => {
    console.log("subbed to pusher")
    var pusher = new Pusher('b203a00018ef2e756a99', {
      cluster: 'mt1'
    });

    var channel = pusher.subscribe(user.username);
    channel.bind('payments-updated', (data) => {
      this.context.fetchUser({bypassCache: true})
    });
    channel.bind('projects-updated', (data) => {
      console.log("pusher project event")
      this.context.syncVideos()
    });
    channel.bind('microsites-updated', (data) => {
      console.log("pusher microsite event")
      this.startTemplateSync()
    });
  }

  authenticateAndSync = async () => {
    let user
    try{
      user = await this.context.fetchUser()

      const storeData = await localforage.getItem("currentlySelectedStore")
      let store 
      if(!!storeData) {
        store = storeData
      } else {
        store = user.stores[0]
      }
      this.context.setStore( store )

      this.setState({loggedIn: true}, async ()=>{
        this.context.syncVideos()
        this.context.syncTemplates()
        this.subscribeToPusher(user)
      })
    } catch(e) {}
  }

  componentDidMount() {
    this.authenticateAndSync()

    // This isn't great but I'll leave it for now...
    document.addEventListener("authEvent", (message)=>{
      if(message.detail.type === "loggedIn") {
        this.authenticateAndSync()
      }
    })
    
    document.addEventListener("uploadProgress", (message)=>{
      let uploads = {...this.state.uploads}
      if(!!!message.detail.error) {
        if(message.detail.progress < 100) {
          uploads[message.detail.videoId] = message.detail
        } else {
          delete uploads[message.detail.videoId]
        }
        this.setState({uploads: uploads})
      } else {
        delete uploads[message.detail.videoId]
        this.setState({uploads: uploads})
      }
    }, false)

    document.addEventListener("postMessage", (message)=>{
      setTimeout(()=>{
        this.setState({messages: this.state.messages.filter((mes)=> mes !== message)})
      }, 5000)
      this.setState({messages: [...this.state.messages, message]})
    }, false)
  }

  getNotices = () => {
    const uploadAlerts = Object.keys(this.state.uploads).map(key => {
      const upload = this.state.uploads[key]
      return(
          <CSSTransition
                key={key}
                timeout={500}
                classNames="msg"
              >
            <div className="messageBox progress">
              <div className="contents">
                <h4>
                  <img src="/upload-icon.svg"/>
                  Uploading { upload.videoTitle }
                </h4>
                <div className="progress">Completed { Math.round(upload.progress) }%</div>
                <div className="bar-container">
                  <div className="bar" style={{width: `${upload.progress}%`}}/>
                </div>
              </div>
            </div>
          </CSSTransition>
        )
    })
    const messageAlerts = this.state.messages.map((message)=>{
      if(message.detail.type === "error") {
        return(
          <CSSTransition
                key={message.detail.text}
                timeout={500}
                classNames="msg"
              >
            <div className="messageBox error">
            <img src="/error.svg"/>
            { message.detail.text }
            </div>
          </CSSTransition>
        )

      } else if(message.detail.type === "success") {
        return(
          <CSSTransition
                key={message.detail.text}
                timeout={500}
                classNames="msg"
              >
            <div className="messageBox success">
            <img src="/success.svg"/>
            { message.detail.text }
            </div>
          </CSSTransition>
        )

      }
    })
    return uploadAlerts.concat(messageAlerts)
  }

  render() {
    return(
      <div>
        <div className="errorsOverlay">
          <TransitionGroup
            transitionname="msg"
            transitionentertimeout={500}
            transitionleavetimeout={300}>
            { this.getNotices() }
          </TransitionGroup>
        </div>
        <Router history={history}> 
        <Switch>
          <Route exact path="/">
            <Redirect to="/videos" />
          </Route>
          <Route path="/videos" component={Home}/>
          <Route path="/addstore" component={AddStoreView}/>
          <Route path="/reset/:data" component={ResetPassword}/>
          <Route path="/invite/:data" component={CreateAccountFromInvite}/>
          <Route path="/products" component={Home}/>
          <Route path="/settings" component={Home}/>
          <Route path="/analytics" component={Home}/>
          <Route path="/pages" component={Home}/>
          <Route path="/activate/:email/:code" component={VerifyResult}/>
          <Route path="/video/:videoId" component={Editor}/>
          <Route path="/videotutorial/:videoId/" component={InteractiveEditorTutorial}/>
          <Route path="/page/:micrositeId" component={MicrositeEditor}/>
          <Route path="/createaccount" component={CreateAccount}/>
          <Route path="/pickplan" component={PlanPickerView}/>
          <Route path="/login" component={FreckleSignIn}/>
          <Route path="/tracktest" component={TrackTest}/>
        </Switch>
        </Router>
      </div>
      )
  }

}

