import React, { Component } from 'react';
import Dropdown from 'react-dropdown'
import TextInput from './TextInput'
import { BeatLoader } from "react-spinners";
import { createTemplate } from '../modules/freckle-api.js'
import { logEvent } from '../helpers/analytics.js'
import LibraryItem from './LibraryItem'
import { MicrositeTemplates } from './microsites/templates'
import styled, { keyframes } from 'styled-components'
import { AppContext, AppAction } from './Context/AppContext'
import Button from './Button'

var fs = require('fs')

const FermData = require('form-data')
const axios = require('axios')

const REQUIRED_FIELDS = ["title"]

const Warning = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height: 100%;
`

const H3 = styled.h2`
color: white;
font-family: Signika;
margin: 0 !important;
padding: 0 !important;
`

const P = styled.p`
font-family: Signika;
color: #9f9f9f;
margin: 10px 0 50px 0;
padding: 0;
line-height: 25px;
max-width: 400px;
text-align: center;
`

const Background = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
pointer-events: none;
display: flex;
justify-content: center;
align-items: center;`

const Enter = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

const Container = styled.div`
pointer-events: all;
width: 65vw;
min-width: 500px;
max-width: 1000px;
height: 85vh;
max-height: 1000px;
background-color: #111;
color: white;
// padding: 20px 40px 0px 40px;
position: relative;
border-radius: 10px;
overflow: hidden;
padding: 0;
margin: 0;
border: 1px solid rgba(255,255,255,0.05);

.editable-content {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% - 50px);

  .step-container {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
  }
}

.header {
  padding: 0 10px 0 20px;
  width: calc(100% - 30px);
  height: 50px;
  box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1f1f1f;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;

    img {
      height: 15px;
      margin-right: 10px;
      margin-bottom: 2.5px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      outline: none;
      border: none;
      background-color: transparent;
      color: white;
      font-size: 25px;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      cursor: pointer;
      opacity: 0.5;
      transition: 0.25s;
    }
    button:hover {
      opacity: 1;
      background-color: rgba(255,255,255,0.05);
    }
  }
}`

const BackButtonContainer = styled.div`
position: absolute;
left: 0;
bottom: 0;
transition: 0.5s;
background-color: transparent;
padding: 20px;

${props=>{
  if (props.visible) {
    return `
opacity: 1;
transform: translateY(0);`
  }
      return `
opacity: 0;
transform: translateY(100px);`
}}`

const VideosList = styled.div`
padding: 40px;
display: flex;
justify-content: center;
align-items: flex-start;
flex-wrap: wrap;
gap: 20px;
overflow: scroll;

animation: ${Enter} 1.0s normal forwards;

h3 {
  font-size: 35px;
  width: 100% !important;
  text-align: center;
}
`

const NameContainer = styled.div`
padding: 40px;
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
overflow: scroll
flex: 1;
flex-direction: column;
margin: 0 auto;
height: 70%;
width: 300px;

animation: ${Enter} 1.0s normal forwards;

h3 {
  font-size: 35px;
  width: 100% !important;
  text-align: center;
}`

const ThemesList = styled.div`
padding: 40px;
display: flex;
justify-content: center;
flex-wrap: wrap;
gap: 20px;

animation: ${Enter} 1.0s normal forwards;

h3 {
  font-size: 35px;
  width: 100%;
  text-align: center;
}

.item {
  height: 320px;
  width: 280px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: #101010;
  overflow: hidden;
  border-top: 1px solid rgba(255,255,255,0.1);
  border-left: 1px solid rgba(255,255,255,0.1);
  border-right: 1px solid rgba(255,255,255,0.1);

  img {
    width: 100% !important;
    height: 300px !important;
    object-fit: contain;
  }

  .footer {
    background-color: #222;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 15px;
    font-family: Signika;
    font-weight: 500;
  }
}
`

function ThemeItem(props) {
  const { theme } = props
  return(
    <div {...props} className="item">
      <img src={`/${theme.name}-theme.jpg`}/>
      <div className="footer">
      { theme.displayName }
      </div>
    </div>
  )
}

export default class AddTemplate extends Component {
  static contextType = AppContext

  constructor(props, context) {
    super(props, context);

    this.form = React.createRef();

    this.state = {
      ready: false,
      payload: {},
      saving: false,
      selectedVideo: null,
      progress: 0,
      steps: ["Choose Theme", "Choose Video", "Choose Name"],
      currentStepIdx: 0, 
      micrositeName: "",
      selectedTheme: null,
    }
  }

  formUpdated = () => {
    const form = this.form.current
    const elements = [...form.elements]
    var completeFields = []
    elements.forEach((input)=>{
      if(REQUIRED_FIELDS.includes(input.name) && input.value.length > 0) completeFields.push(input.name)
    })
    
    const ready = REQUIRED_FIELDS.length === completeFields.length && this.state.selectedVideoFile instanceof File
    if(this.state.ready !== ready) this.setState({ready: ready})
  }

  addMicrosite = async (e) => {
    if(e) e.preventDefault()
    this.setState({saving: true})

    var payload = {}
    payload.title = this.state.micrositeName
    payload.shopUrl = this.context.state.selectedStore.domain
    payload.projectId = this.state.selectedVideo.id
    payload.data = this.state.selectedTheme

    try {
      const resp = await createTemplate(payload)
      this.props.onAdd()
      this.setState({saving: false})
    } catch(e) {
      console.log("Error on new template: " + e)
      this.setState({saving: false})
      document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":"New template not created...", "type":"error"}}))
    }

  }

  contentForCurrentStep = () => {
    const filteredVideos = this.context.state.videos.filter(video=> video.shopUrl === this.context.state.selectedStore.domain)

    if(!!!filteredVideos.length) {
      return(
        <Warning>
        <H3>No Videos</H3>
        <P>You must upload a video before creating a landing page.</P>
        </Warning>
      )
    }

    const { steps } = this.state
    switch(this.state.currentStepIdx) {
      case 0:
        return (
          <ThemesList>
          <h3>Pick a Theme</h3>
          {
            Object.values(MicrositeTemplates).map(theme=>{
              return <ThemeItem 
              onClick={()=>{
                this.setState({ selectedTheme: theme, currentStepIdx: 1 });
              }}
              theme={theme}/>
            })
          }
          </ThemesList>
        )
      case 1:
        return(
          <VideosList>
          <h3>Pick a Video</h3>
            {
              filteredVideos.map(video => {
                return(
                  <LibraryItem 
                  key={video.id} 
                  selected={false} 
                  video={video} 
                  doubleClicked={()=>{}} 
                  singleClicked={()=>{
                    this.setState({
                      selectedVideo: video,
                      currentStepIdx: 2,
                    })
                  }}/>
                )
              })
            }
            </VideosList>
        )
        break;
      case 2:
        return(
          <NameContainer>
          <h3>Pick a Name</h3>
          <TextInput 
          name="title" 
          label="Landing Page Name *" 
          style={{marginTop:'0', width:'300px',marginBottom:'15px'}} 
          defaultValue={this.state.micrositeName}
          onChange={(e)=>{
            this.setState({micrositeName: e.target.value})
          }}
          autoFocus/>
          <Button 
          disabled={!this.state.micrositeName.length} 
          fullWidth
          onClick={(e)=> this.addMicrosite(e) }>Create Landing Page</Button>
          </NameContainer>
        )
        break
    }
  }


  readyForNextStep = () => {
    switch(this.state.currentStepIdx){
      case 0:
        return true

      case 1:
        return !!this.state.micrositeName.length

      case 2:
        return true

    }
  }

  render(){
    return(
      <Background>
        <Container>
          <div className="header">
            <div className="left">
              <img src="./new-project.svg"/>
              <h4>New Landing Page</h4>
            </div>
            <div className="right">
            <button onClick={this.props.onCancel}>×</button>
            </div>
          </div>
          <div className="editable-content">
            
            {
              this.contentForCurrentStep()
            }
            
              <BackButtonContainer
              visible={this.state.currentStepIdx > 0}
              >
              <Button
              backgroundColor="#1f1f1f"
              color="#6f6f6f"
              onClick={()=>{
                this.setState({currentStepIdx: this.state.currentStepIdx-1})
              }}>
              ⟨ &nbsp; {this.state.steps[this.state.currentStepIdx-1] || this.state.steps[0]}
              </Button>
            </BackButtonContainer>
          </div>
        </Container>
      </Background>      
    )
  }

}
