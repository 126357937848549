import React, { Component } from 'react';
import VideoPlayer from "./VideoPlayer"
import Stage from "./Stage"
import Header from "../Header"
import Timeline from "./Timeline"
import ProductsPane from "./ProductsPane"

import ResizePanel from "react-resize-panel"
import { BeatLoader } from "react-spinners"

import {DndContext} from '@dnd-kit/core';

import { CSSTransition } from 'react-transition-group'

import { getVideo } from '../../modules/freckle-api.js'

import memoize from "memoize-one"

import { currency_symbols } from '../../constants/currency-symbols'

import { FreckleTooltip } from '../FreckleTooltip'

import { withRouter } from 'react-router-dom'

import { logEvent } from '../../helpers/analytics.js'

import { EditorContext, ContextAction } from './Context/EditorContext'

import PropertiesView from './PropertiesView'

import TrackSettings from './TrackSettings/TrackSettings'

import localforage from "localforage"

import ResizablePane from '../ResizablePane'

import "../../../styles/Editor.scss"

import TutorialTooltip from '../tutorial/TutorialTooltip'
import { EditorTutorialState } from '../tutorial/tutorial'

// import FrameGrabber from './TrackSettings/FrameGrabber'

export default class MainEditor extends Component {
	static contextType = EditorContext

	constructor(props, context) {
		super(props, context);
		const videoId = this.props.match.params.videoId;

		this.state = {
			videoId: videoId,
			duration: 0,
			video: null,
			selectedTabIndex: 0,
			showingProductsView: true,
			showsProperties: true,
			showsStage: true,
			playing: false,
			dragProduct: null,
			draggingProduct: false,
			videoNode: null,
			showingTracking: false,
			trackingStartPosition: {x:0, y:0},
			frameGrabFailed: false,
			doneGrabbingFrames: true
		}

		this.dragItemRef = React.createRef()

		this.idbStore = localforage.createInstance({
		  name: `video:${videoId}`,
		  driver: [localforage.INDEXEDDB]
		});
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeypress)
	}

	componentDidMount(){
		logEvent("Loaded Project", {videoId: this.state.videoId})
		
		getVideo(this.state.videoId).then((video)=>{
			this.context.dispatch({type: ContextAction.setCurrentVideo, payload: video})
		})

		document.addEventListener('keydown', this.handleKeypress)
	}

	handleKeypress = (e) =>{
		if(document.activeElement.tagName === "INPUT" && document.activeElement.type === "text") return
			const code = e.code
		switch(code){
			case "KeyK":
			this.setState({showsProperties: !this.state.showsProperties})
			e.preventDefault()
			break;
			case "KeyL":
			this.setState({showingProductsView: !this.state.showingProductsView})
			e.preventDefault()
			break;
			case "KeyT":
			this.setState({showsStage: !this.state.showsStage})
			e.preventDefault()
			break;
		}
	}

	getProduct = (productId)=>{
		return this.props.appContext.state.products.find((prod)=> prod.id === productId)
	}

	getGroup = (groupId)=>{
		return this.context.state.groups.find((group)=>{ return group.id === groupId })
	}

	getProductHeader = () => {
		if(!this.context.state.selectedGroupId) return <span/>

		const group = this.getGroup(this.context.state.selectedGroupId)
		if(!!!group) return <span/>

		const product = this.getProduct(group.id)
		if(!product){
			return(
				<div className="product-header">
				<div className="product-header-details">
				<div className="product-title">Product Not Found...</div>
				<div className="product-price">-</div>
				</div>
				<FreckleTooltip arrow title="Remove product from stage.">
				<div className="trash-container" onClick={()=>{ 
					this.context.dispatch({type: ContextAction.deleteGroup, payload: group})
				}}>
				<img className="trash-icon" src="/trash-icon.svg"/>
				</div>
				</FreckleTooltip>
				</div>
			)
		}

		const imageUrl = product.imageUrl
		
		let price =  currency_symbols[product.currency] + parseFloat(product.price).toFixed(0)

		return(
			<div className="product-header">
			<img src={imageUrl}/>
			<div className="product-header-details">
			<div title={product.title} className="product-title">{product.title}</div>
			<div className="product-price">{price}</div>
			</div>
			<div className="trash-container" onClick={()=>{ 
				this.context.dispatch({type: ContextAction.deleteGroup, payload: group})
			}}>
			<img className="trash-icon" src="/trash-icon.svg"/>
			</div>
			</div>
			)
	}

	getProductView = () => {
		const group = this.getGroup(this.context.state.selectedGroupId)
		if(!!!group) return <PropertiesView/>

		const product = this.getProduct(group.id)
		return <PropertiesView group={group} product={product}/>
	}

	productDragItem = memoize((product)=>{
		if(!product) return <span/>
			return(
				<div ref={this.dragItemRef} className="product-drag-item">
				<span><img className="icon" src={ product.imageUrl }/></span>
				</div>
				)
	})

	handleDragEnd = (e) => {
		// this is a messy pattern, but it should work...potential for race condition?
		setTimeout(()=>{
			this.setState({draggingProduct: false})
		}, 50)
	}

	handleDragStart = (e) => {
		this.setState({dragProduct: this.props.appContext.state.products.find(product => product.id === e.active.id)})
	}

	handleDragMove = (e) => {
		this.setState({draggingProduct:true})
	}

	// TODO: ugh this is gross
	handleMouseMove = (e) => {
		if(this.state.draggingProduct){
			this.dragItemRef.current.style.left = e.clientX + 'px'
			this.dragItemRef.current.style.top = e.clientY + 'px'
			// this.dragItemRef.current.style.transform = `translateX(${e.clientX}px) translateY(${e.clientY}px)`
		}
	}

	handleFreckleLayerUp = (payload) => {
		if(!!this.state.draggingProduct){
			this.context.dispatch({type: ContextAction.addProduct, itemType: "hotspot", payload: this.state.dragProduct})
			const {x, y, ts} = payload
			const freckle = {ts: ts, t: 1, x: x, y: y}
			if(x >= 0.0 && x <= 1.0 && y>=0.0 && y<=1.0){
				this.context.dispatch({type: ContextAction.addKeyframe, payload: freckle})	
			}
		}
}

render() {
	const shouldShowProps = this.state.showsProperties
	const rightPaneClass = shouldShowProps ? "right-pane" : "right-pane exit"

	const leftPanelClass = this.state.showingProductsView ? "prod-pane" : "prod-pane exit"

	const currentVideo = this.context.state.currentVideo

	const overlayClass = this.state.previewing ? "overlay-pane" : "overlay-pane hidden" 

	if( !!currentVideo ){
		const video = this.context.state.currentVideo
		const videoUri = `https://stream.freckle.shop/${video.uuid}/mp4/base.mp4`

		let currentTime = 0
		if(this.state.videoNode){
			currentTime = this.state.videoNode.currentTime
		}

		return (
			<DndContext autoScroll={false} onDragStart={this.handleDragStart} onDragMove={this.handleDragMove} onDragEnd={this.handleDragEnd}>
			<div className="Editor" onMouseMove={this.handleMouseMove}>

			<CSSTransition in={this.state.showingTracking} timeout={1000} classNames="tutorial" unmountOnExit> 
			<TrackSettings 
			visible={true} 
			src={videoUri} 
			time={currentTime}
			startPosition={this.state.trackingStartPosition}
			onSuccess={(freckles)=>{
				this.context.dispatch({type: ContextAction.addKeyframe, payload: freckles})	
				this.setState({showingTracking: false})
			}}
			onCancel={()=> this.setState({showingTracking: false})}/>
			</CSSTransition>
			

			<CSSTransition in={this.state.draggingProduct} timeout={500} classNames="drag-freckle" unmountOnExit>
			{ this.productDragItem(this.state.dragProduct) }
			</CSSTransition>

			<Header 
			showsHelp
			showsBack = {true}
			currentVideo={currentVideo} 
			showsStage={this.state.showsStage}
			showsPreviewButton={true}
			onPreviewMicrosite={()=>{
				this.setState({previewing: true})
			}}
			toggleStage={()=>{ this.setState({showsStage: !this.state.showsStage}) }}
			showProps={this.state.showsProperties} 
			toggleProps={()=>{ this.setState({showsProperties: !this.state.showsProperties})  }} 
			showProducts={this.state.showingProductsView} 
			toggleProducts={()=>{ this.setState({showingProductsView: !this.state.showingProductsView})  }} 
			/>

			<div className="main-content">

			
			<ProductsPane
			visible={this.state.showingProductsView}
			appContext={this.props.appContext} 
			addProduct={this.addProduct}/>

			{
				/*<FrameGrabber 
			src={`https://stream.freckle.shop/${this.context.state.currentVideo.uuid}/mp4/base.mp4`}
			store={this.idbStore}
			onDone={()=>{
				this.setState({doneGrabbingFrames: true})
			}}
			onStart={()=>{
				this.setState({doneGrabbingFrames: false})
			}}
			onError={()=>{
				this.setState({doneGrabbingFrames: true, frameGrabFailed: true})
			}}/>*/
		}

			<div className="center-pane">
			<VideoPlayer 
			videoNode={(node)=>{
				this.setState({videoNode: node})
			}}
			playStateChange={(playing)=>{ 
				this.setState({playing: playing}) 
			}} 
			key={this.context.state.currentVideo.id+":videoplayer"} 
			video={this.context.state.currentVideo}
			onBeginTracking={(pos)=>{
				this.setState({showingTracking: true, trackingStartPosition: pos})
			}}
			mouseUpOnFreckleLayer={this.handleFreckleLayerUp}
			loadedMetadata={(e)=>{
				const duration = e.target.duration
				this.setState({duration: duration})
			}}/>
			
			

			<ResizablePane>
			<Stage
			appContext={this.props.appContext}
			playing={this.state.playing}
			duration={this.state.duration}
			videoNode={this.state.videoNode}
			/>
			</ResizablePane>

			</div>

			<CSSTransition in={shouldShowProps} timeout={500} classNames="keyframe-panel" unmountOnExit>
			<div className={rightPaneClass}>
			{ this.getProductView() }
			</div>
			</CSSTransition>

			</div>
			</div>		
			</DndContext>
			)
	} else {
		return(
				<div className="loading-container">
				<BeatLoader color="#ffffff" size="5px"/>
				<h4>Loading Video</h4>
				</div>
				)
		}
	}

	addProduct = (product, index=0) => {
		logEvent("Add Product To Stage", {productId: product.id, videoId: this.state.videoId})
		this.context.dispatch({type: ContextAction.addProduct, itemType: "hotspot", payload: {...product, index: index}})
	}

}
