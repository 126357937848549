export const CartIcon = {
	pushCart: `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <polygon fill="white" points="160 96.039 160 128.039 464 128.039 464 191.384 428.5 304.039 149.932 304.039 109.932 16 16 16 16 48 82.068 48 122.068 336.039 451.968 336.039 496 196.306 496 96.039 160 96.039" class="ci-primary"/>
  <path fill="white" d="M176.984,368.344a64.073,64.073,0,0,0-64,64h0a64,64,0,0,0,128,0h0A64.072,64.072,0,0,0,176.984,368.344Zm0,96a32,32,0,1,1,32-32A32.038,32.038,0,0,1,176.984,464.344Z" class="ci-primary"/>
  <path fill="white" d="M400.984,368.344a64.073,64.073,0,0,0-64,64h0a64,64,0,0,0,128,0h0A64.072,64.072,0,0,0,400.984,368.344Zm0,96a32,32,0,1,1,32-32A32.038,32.038,0,0,1,400.984,464.344Z" class="ci-primary"/>
</svg>
`,
pushCart2: `
<svg width="21px" height="18px" viewBox="0 0 21 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-231.000000, -401.000000)">
            <g id="Group-4" transform="translate(231.000000, 402.000000)">
                <polyline id="Path-5" stroke="#fff" points="0.384615385 0.386363636 3.07692308 0.386363636 6.15384615 11.9772727 16.9230769 11.9772727 19.6153846 3.47727273 4.23076923 3.47727273"></polyline>
                <ellipse id="Oval" fill="#fff" cx="8.46153846" cy="15.4545455" rx="1.53846154" ry="1.54545455"></ellipse>
                <ellipse id="Oval-Copy" fill="#fff" cx="15.3846154" cy="15.4545455" rx="1.53846154" ry="1.54545455"></ellipse>
            </g>
        </g>
    </g>
</svg>
`,
	bag: `
<svg viewBox="0 0 13 17" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-243.000000, -377.000000)" stroke="#fff">
            <g id="Group" transform="translate(243.000000, 378.000000)">
                <rect id="Rectangle" x="0.5" y="3.5" width="12" height="12"></rect>
                <path d="M4,3 C4,1 4.91666667,7.10542736e-15 6.75,7.10542736e-15 C8.58333333,7.10542736e-15 9.5,1 9.5,3" id="Path"></path>
            </g>
        </g>
    </g>
</svg>`,
bag2: `
<svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-254.000000, -384.000000)" stroke="#fff">
            <g id="Group-Copy" transform="translate(254.000000, 385.000000)">
                <path d="M2.42895948,3.5 L0.582805632,15.5 L16.4171944,15.5 L14.5710405,3.5 L2.42895948,3.5 Z" id="Rectangle"></path>
                <path d="M6,5 L6,3 C6,1 6.91666667,0 8.75,0 C10.5833333,0 11.5,1 11.5,3 L11.5,5" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
`,
bag3: `
<svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-284.000000, -384.000000)" stroke="#fff">
            <g id="Group-Copy-2" transform="translate(284.000000, 385.000000)">
                <path d="M2.42895948,3.5 L1.02590455,12.619857 C1.0065523,12.7456467 0.996833793,12.8727304 0.996833793,13 C0.996833793,14.3807119 2.11612192,15.5 3.49683379,15.5 L13.5031662,15.5 C13.6304358,15.5 13.7575195,15.4902815 13.8833092,15.4709292 C15.2479657,15.2609821 16.1840426,13.9845136 15.9740955,12.619857 L14.5710405,3.5 L2.42895948,3.5 Z" id="Rectangle"></path>
                <path d="M6,5 L6,3 C6,1 6.91666667,0 8.75,0 C10.5833333,0 11.5,1 11.5,3 L11.5,5" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
`,
bag4: `
<svg xmlns="http://www.w3.org/2000/svg" width="33" height="37" xmlns:v="https://vecta.io/nano"><path d="M12.5 14.268a2 2 0 0 1-1 3.732 2 2 0 0 1-1-3.732V11.5H5.405l-2.3 23h27.29l-2.3-23H23.5v2.768a2 2 0 0 1-1 3.732 2 2 0 0 1-1-3.732V11.5h-9v2.768zm-2-4.768v-3c0-3.908 2.301-6 6.5-6s6.5 2.092 6.5 6v3h6.405l2.7 27H.895l2.7-27H10.5zm2 0h9v-3c0-2.759-1.365-4-4.5-4s-4.5 1.241-4.5 4v3z" fill="#fff"/></svg>
`,
bag5: `
<svg height="36" viewBox="0 0 27 36" width="27" xmlns="http://www.w3.org/2000/svg"><g fill="#fff" fill-rule="evenodd"><circle cx="14" cy="34" r="2"/><g><path d="m8.34985722 6h10.30033688c3.6554831 0 6.6954878 2.81275756 6.9789259 6.4572355l.6999473 9c.2997606 3.8543543-2.581807 7.2219298-6.4361614 7.5216904-.1805807.0140441-.3616385.0210741-.5427645.0210741h-11.7002315c-3.86599325 0-7-3.1340068-7-7 0-.181126.00702999-.3621838.0210741-.5427645l.69994732-9c.28343806-3.64447794 3.32344281-6.4572355 6.9789259-6.4572355zm-1.84985722 4c0 5.1876765 2.41056296 8 7 8 4.589437 0 7-2.8123235 7-8 0-.55228475-.4477153-1-1-1s-1 .44771525-1 1c0 4.1456568-1.589437 6-5 6s-5-1.8543432-5-6c0-.55228475-.44771525-1-1-1s-1 .44771525-1 1z"/><path d="m6.5 0h2c0 4.14565679 1.589437 6 5 6s5-1.85434321 5-6h2c0 5.18767655-2.410563 8-7 8-4.58943704 0-7-2.81232345-7-8z" fill-rule="nonzero" transform="matrix(-1 0 0 -1 27 8)"/></g></g></svg>`,
bag6: `
<svg style="fill:#fff;" height="36" viewBox="0 0 27 36" width="27" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="m14 36c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2 2 .8954305 2 2-.8954305 2-2 2zm-5.65014278-30h10.30033688c3.6554831 0 6.6954878 2.81275756 6.9789259 6.4572355l.6999473 9c.2997606 3.8543543-2.581807 7.2219298-6.4361614 7.5216904-.1805807.0140441-.3616385.0210741-.5427645.0210741h-11.7002315c-3.86599325 0-7-3.1340068-7-7 0-.181126.00702999-.3621838.0210741-.5427645l.69994732-9c.28343806-3.64447794 3.32344281-6.4572355 6.9789259-6.4572355zm.93623515 2c-3.29712033 0-6.03655314 2.5422043-6.28245443 5.8301421l-.00363794.0486428c1.51862886 2.0808101 4.8752134 3.1212151 10.0697536 3.1212151s8.8379557-1.0628293 10.9302464-3.188488c-.2451155-3.2774313-2.9757945-5.811512-6.2623789-5.811512z"/><g fill-rule="nonzero"><path d="m6.5 0h2c0 4.14565679 1.589437 6 5 6s5-1.85434321 5-6h2c0 5.18767655-2.410563 8-7 8-4.58943704 0-7-2.81232345-7-8z" transform="matrix(-1 0 0 -1 27 8)"/><path d="m6.5 9h2c0 4.1456568 1.589437 6 5 6s5-1.8543432 5-6h2c0 5.1876765-2.410563 8-7 8-4.58943704 0-7-2.8123235-7-8z" transform="matrix(-1 0 0 -1 27 26)"/></g></g></svg>`,
bag7: `
<svg style="fill:white;" height="16" viewBox="0 0 12 16" width="12" xmlns="http://www.w3.org/2000/svg"><path d="m3371.03577 2521c.26255-2.60903 1.61106-4 3.96423-4s3.70168 1.39097 3.96423 4h2.03577v12h-12v-12zm1.00473 0h5.919c-.22769-2.05414-1.18873-3-2.9595-3s-2.73181.94586-2.9595 3z" fill-rule="evenodd" transform="translate(-3369 -2517)"/></svg>`,
}

export const ButtonIcon = {
	square: `
<svg width="17px" height="9px" viewBox="0 0 17 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-270.000000, -327.000000)" stroke="#FFFFFF">
            <rect id="Rectangle" x="270.5" y="327.5" width="16" height="8"></rect>
        </g>
    </g>
</svg>
`,
rounded: `
<svg width="17px" height="9px" viewBox="0 0 17 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-270.000000, -338.000000)" stroke="#FFFFFF">
            <rect id="Rectangle-Copy" x="270.5" y="338.5" width="16" height="8" rx="2"></rect>
        </g>
    </g>
</svg>
`,
pill: `
<svg width="17px" height="9px" viewBox="0 0 17 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-270.000000, -349.000000)" stroke="#FFFFFF">
            <rect id="Rectangle-Copy-2" x="270.5" y="349.5" width="16" height="8" rx="4"></rect>
        </g>
    </g>
</svg>
`
}

export const AlignIcon = {
    left: `
<svg width="27px" height="9px" viewBox="0 0 27 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-300.000000, -327.000000)">
            <g id="Group-5" transform="translate(300.000000, 327.000000)">
                <rect id="Rectangle-Copy-6" stroke="#FFFFFF" x="0.5" y="0.5" width="26" height="8"></rect>
                <rect id="Rectangle-Copy-7" fill="#FFFFFF" x="2" y="2" width="8" height="5"></rect>
            </g>
        </g>
    </g>
</svg>
    `,
    center: `
<svg width="27px" height="9px" viewBox="0 0 27 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="iPhone-8" transform="translate(-300.000000, -337.000000)">
            <g id="Group-5-Copy" transform="translate(300.000000, 337.000000)">
                <rect id="Rectangle-Copy-8" stroke="#FFFFFF" x="0.5" y="0.5" width="26" height="8"></rect>
                <rect id="Rectangle-Copy-9" fill="#FFFFFF" x="10" y="2" width="8" height="5"></rect>
            </g>
        </g>
    </g>
</svg>
    `
}


