import React, { Component } from 'react';
import Help from './Help'
import '../../styles/TextInput.scss';

export default class TextInput extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
    }
    this.inputRef = React.createRef()
  }

  getLabel = () => {
    if(this.props.label){
      return(
        <div className="label">{this.props.label}</div>
      )
    }
    return (<span/>)
  }

  inputValue = () => {
    return this.inputRef.current.value
  }

  getInputElement = () => {
    var style = {}
    if(this.props.font) {
      style =  {'fontFamily': this.props.font}
    }
    if(this.props.textArea) {
      return <textarea ref={this.inputRef} id={this.props.name} name={this.props.name} style={style} name={this.props.name} className="text-area" placeholder={this.props.placeholder} defaultValue={this.props.defaultValue}/>
    } else {
      return <input ref={this.inputRef} autoFocus={this.props.autoFocus} id={this.props.name} name={this.props.name} type={this.props.type || "input"} style={style} name={this.props.name} className="text-input" placeholder={this.props.placeholder} defaultValue={this.props.defaultValue} onChange={this.props.onChange}/>
    }
  }

  render(){
    return(
      <div className={"text-input-container " + (this.props.fullWidth?"fullWidth":"")} style={{...this.props.style}}>
        <div className="labelContainer">
        { this.getLabel() }   
        { !!this.props.help && <Help arrow title={this.props.help}/> }
        </div>
        { this.getInputElement() }
      </div>
    )
  }

}
