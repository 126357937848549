import React, { Component } from 'react';
import styled, { css, keyframes } from 'styled-components'
import { BeatLoader } from "react-spinners";

import TextInput from './TextInput'
import '../../styles/SignIn.scss';

import * as API from '../modules/freckle-api'

import { signIn } from '../modules/auth'

import Button from './Button'

const LOGGING_IN_STATE = 'LOGGING_IN_STATE'
const FORGOT_PASSWORD_STATE = 'FORGOT_PASSWORD_STATE'
const SENT_RESET_STATE = 'SENT_RESET_STATE'

const DoubleField = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 10px;
`

export default class FreckleSignIn extends Component {
  constructor(props, context) {
    super(props, context)
    
    this.state = {
    	passwordState: LOGGING_IN_STATE,
    	loading: false,
    	errorMessage: null,
    	recoveryDestination: null,
    	storedUser: null
    }
  }

  handleSignIn = (event)=>{
    const { history } = this.props
    switch(event.detail.type) {
      case "loggedIn":
      history.push("/videos")
      break
    }
  }

  componentDidMount() {
    document.addEventListener("authEvent", this.handleSignIn)
  }

  componentWillUnmount() {
    document.removeEventListener("authEvent", this.handleSignIn)
  }

  submitted = async(e) => {
  	e.preventDefault()

  	const username = e.target.elements.username.value
  	const password = e.target.elements.password.value
  	if(username.length ===0 || password.length === 0){
  		this.setState({errorMessage: "Username and Password cannot be blank"})
  		return
  	}

  	this.setState({loading: true})
  	
  	try {
    // TODO: 1) finish swapping out auth modules 2) set up notifications between auth module and UI to communicate login/logout/etc events
    const user = await signIn(username, password);

  } catch(err) {
    if(err.code === 'PasswordResetRequiredException'){

    }
    this.setState({errorMessage: err.message})

  } finally {
    this.setState({loading: false})
  }
}

sendPasswordReset = (e) => {
  e.preventDefault()

  const username = e.target.elements.username.value
  if(username.length ===0){
  this.setState({errorMessage: "Username and Password cannot be blank"})
    return
  }

  this.setState({loading: true})
  API.requestPasswordReset(username).then(resp=>{
    this.setState({passwordState: SENT_RESET_STATE, errorMessage: null})
  }).catch(e=>{
    this.setState({loading: false})
    document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":"We were unable to reset your password.", "type":"error"}}))
  })
}

 resetPassword = (e) => {
   e.preventDefault()

   const username = e.target.elements.username.value
   const password = e.target.elements.password.value
   const code = e.target.elements.code.value
   if(username.length ===0 || password.length ===0 || code.length ===0){
    this.setState({errorMessage: "Username, Password, and Code cannot be blank"})
    return
  }

  
 }

getErrorMessage = () => {
 if(this.state.errorMessage){
  return (
   <div className="error-container">
   <div className="error-message">
   <img src="/error.svg"/>
   {this.state.errorMessage}
   </div>
   </div>
   )
} else {
  return <div className="error-container"/>
}
}

getForm = () => {
 switch(this.state.passwordState){
  
  case LOGGING_IN_STATE:
  return (
    <React.Fragment>
    <div className="logo"></div>
    <div className="form-container">
    <form onSubmit={this.submitted}>
    <TextInput name="username" label="Email"/>
    <TextInput name="password" label="Password" type="password"/>
    <Button large fullWidth margin='25px 0 0 0'>
    { this.state.loading ? (<BeatLoader size="5px" color="white"/>) : "SIGN IN" }
    </Button>
    <hr/>
    </form>
    <Button large fullWidth ghost onClick={()=> this.props.history.push("/createaccount") }>
    CREATE ACCOUNT
    </Button>
    <Button margin='25px 0 0 0' small link type="button" onClick={()=>{this.setState({passwordState: FORGOT_PASSWORD_STATE, errorMessage: null})}}>Forgot Password</Button>
    </div>
    </React.Fragment>
    )

  case FORGOT_PASSWORD_STATE:
  return (
    <React.Fragment>
    <div className="logo"></div>
    <div className="form-container">
    <form onSubmit={this.sendPasswordReset}>
    <TextInput name="username" label="Email"/>
    <Button large fullWidth margin='25px 0 0 0'>
    { this.state.loading ? (<BeatLoader size="5px" color="white"/>) : "RECOVER PASSWORD" }
    </Button>
    </form>
    <Button large margin='25px 0 0 0' small link type="button" onClick={()=>{this.setState({passwordState: LOGGING_IN_STATE, errorMessage: null})}}>Sign In</Button>
    </div>
    </React.Fragment>
    )

  case SENT_RESET_STATE:
  return (
    <div className="reset-container">
    <img className="icon" src="/sent.svg"/>
    <p>We've sent a password reset link to your email address.<br/>It will expire after a day.</p>
    </div>
    )
  default:
  return <span/>
}	

}

render() {
  return(
   <div className="container" key={this.state.passwordState}>
   { this.getErrorMessage() }
   { this.getForm() }
   </div>
   )
}
}