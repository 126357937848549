import React from 'react'
import styled from 'styled-components'
import { DragHotspot } from '../Editor/Hotspot/Hotspot'

export const GridSelectorStyle = {
	basic: "basic",
	bold: "bold"
}

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: ${props=>{
		switch(props.gridStyle) {
			case GridSelectorStyle.bold:
				return '5px'

			default:
				return '5px'
		}
	}};
`

const Tab = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	height: 55px;
	min-width: 35px;
	padding: 0 10px;
	transition: 0.25s;
	opacity: ${props=> props.selected ? 1.0 : 0.25};

	box-shadow: ${props=> props.selected ? 'rgba(0,0,0,0.15) 0 4px 15px':'none'};

	border-radius: 4px;

	background-color: ${props=>{
		switch(props.gridStyle) {
			case GridSelectorStyle.bold:
				return '#3f3f3f'

			default:
				return props.selected ? '#3f3f3f':'transparent';
		}
	}};

	&:hover {
		background-color: ${props=>{
			switch(props.gridStyle) {
				case GridSelectorStyle.bold:
					return '#3f3f3f'

				default:
					return '#3f3f3f'
			}
		}};
		opacity: ${props=> props.selected ? 1.0 : 0.8};
		box-shadow: rgba(0,0,0,0.15) 0 2px 15px;
	}
`

const Icon = styled.img`
	height: 25px;
`

export default function GridSelector(props) {
	return (
		<Container {...props}>
		{
			props.tabs.map(tab=>{
				const selected = tab.id === props.selected
				return(
					<Tab
					{...props}
					key={tab.id}
					selected={selected}
					onClick={()=>props.didSelectId(tab.id)}>
					{ 
						!tab.group && <Icon 
						src={tab.imageSrc}/> 
					}
					{
						tab.group && <DragHotspot
						uncontrolledPosition
						group={tab.group}
						size={ 35 }
						type={ tab.id }
						color={ tab.group.properties.color }
						accentColor={ tab.group.properties.accentColor }
						dragging={true}
						freckleRef={null}/>
					}
					</Tab>
				)
			})
		}
		</Container>
	)
}