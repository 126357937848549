import React, { Component } from 'react';
import Header from './Header'
import styled from 'styled-components'

import { SquareLoader } from "react-spinners";

import { Menu, MenuItem } from '@material-ui/core'

var moment = require('moment');

const CLICK_TIME_THRESH = 200

const DetailsButtonIcon = styled.img`
  max-width: 20px;
  opacity: 0.5;
  pointer-events: none;
`

const DetailsButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 1000px;
  height: 40px !important;
  width: 40px !important;
  transition: 0.2s;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  flex-shrink: 0;
  padding: 0;

  &:hover {
    background-color: #4f4f4f;
  }
`


const Container = styled.div`
border-radius: 10px;
height: 250px;
width: 200px;
max-width: 200px;
background-color: #222;
transition: 0.2s;

overflow: hidden;

display: flex;
flex-direction: column;

cursor: pointer;

color: white;

.title {
  font-weight: 500;
  font-size: 14px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right:40px;
  white-space: nowrap;
}

.updated {
  color: #757575;
  font-size: 12px;
  white-space: nowrap;
}

${props=>{
  if (props.selected) return `
background-color: #3781D8;
box-shadow: 0px 0px 50px -15px #3781D8;

.updated {
  color: white;
}`
}}

button {
  opacity: 0.25;
  transition: opacity 0.5s;
}

&:hover {
  background-color: #2f2f2f;
  button {
    opacity: 1;
  }  
}
`

const ThumbnailContainer = styled.div`
flex-grow: 1;
height:20px;
width: 100%;

display: flex;
justify-content: center;
align-items: center;

pointer-events: none;

.thumbnail-image {
  object-fit:cover;
  height:100% !important;
  width: 100%;
}

.processing {
  font-size: 14px;
  font-weight: 700;
  color: #444444;
  display: flex;
  justify-content:center;
  align-items: center;

  label {
    margin-left: 5px;
  }
}`

const Details = styled.div`
height: 40px;
width: calc(100% - 15px);
background-color: transparent;
transition: 0.2s;
padding: 12px 0px 14px 15px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: space-between;
pointer-events: none;

.left {
  max-width: calc(100% - 13px - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
`

export default class LibraryItem extends Component {

  constructor(props, context) {
    super(props, context);

    this.menuButtonRef = React.createRef();

    this.state = {
      lastClickDate: null,
      clickTimer: null,
      thumbnail: `https://image.mux.com/${props.video.muxPlaybackId}/thumbnail.jpg?width=500&height=500&time=0`,
      showingMenu: false,
    }
  }

  manageClick = (e) => {
    if (e.target.ariaLabel != "clickarea") return
    const lastDate = this.state.lastClickDate
    if(lastDate == null){
      const timeout = setTimeout(()=>{
        this.props.singleClicked()
        this.setState({lastClickDate: null})
      }, CLICK_TIME_THRESH)
      this.setState({lastClickDate: new Date()})
      return
    } else if(Math.abs(lastDate - new Date()) < CLICK_TIME_THRESH) {
      this.props.doubleClicked()
      clearTimeout(this.clickTimer)
      this.setState({lastClickDate: null})
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.video.muxPlaybackId!==this.props.video.muxPlaybackId) {
      this.setState({thumbnail: `https://image.mux.com/${nextProps.video.muxPlaybackId}/thumbnail.jpg?width=500&height=500&time=0`})
    }
  }

  getThumbnail = () => {
    if(!!!this.props.video.muxPlaybackId) {
      return <div className="processing"><SquareLoader color="#444444" size="15"/> <label>Processing</label></div>
    } else {
      return (
        <img className="thumbnail-image" src={this.state.thumbnail}/>
      )
    }
  }

  hover = () => {
    this.setState({thumbnail: `https://image.mux.com/${this.props.video.muxPlaybackId}/animated.gif`})
  }

  endHover = () => {
    this.setState({thumbnail: `https://image.mux.com/${this.props.video.muxPlaybackId}/thumbnail.jpg?width=500&height=500&time=0`})
  }

  render(){
    return(
      <Container aria-label="clickarea" selected={this.props.selected} onClick={this.manageClick} onMouseOver={this.hover} onMouseOut={this.endHover}>
        <ThumbnailContainer>
          { this.getThumbnail() }
        </ThumbnailContainer>
        <Details>
          <div className="left">
          <span className="title">{this.props.video.title}</span>
          <span className="updated">Updated {moment(this.props.video.updatedAt).fromNow()}</span>
          </div>

          {this.props.showsDetailsButton && <DetailsButton 
          onClick={(e)=>{
            this.setState({showingMenu: true}) 
            return false
          }}
          ref={this.menuButtonRef}>
          <DetailsButtonIcon src="/menu-icon.svg"/>
          </DetailsButton>}

        </Details>

        <Menu
        className="freckle-menu"
        anchorEl={this.menuButtonRef.current}
        open={this.state.showingMenu}
        onClose={()=>this.setState({showingMenu: false})}
        >
        <MenuItem
        onClick={()=>{
          this.setState({showingMenu: false})
          this.props.singleClicked()
        }}
        className="freckle-menu-item">Edit Interactive Video</MenuItem>
        <MenuItem
        className="freckle-menu-item red"
        onClick={()=>{
          this.props.onDelete()
          this.setState({showingMenu: false})
        }}>Delete</MenuItem>
        </Menu>

      </Container>
    )
  }

}
