import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group'
import localforage from "localforage"
import { ClickAwayListener } from '@material-ui/core'
import '../../styles/AccountPicker.scss';
import styled from 'styled-components'
import { AppContext, AppAction } from './Context/AppContext'
import { FreckleTooltip } from './FreckleTooltip'

function IconContainer(props){
  return(
    <FreckleTooltip arrow title={props.active ? "This store is paid and active." : "This store is in test mode.  You can create interactive microsites, but checkout is disabled.  Activate to enable checkout."}>
    <div style={{position:'relative'}}>
    {props.children}
    </div>
    </FreckleTooltip>
  )
}

const StatusIndicator = styled.div`
height: 8px;
width: 8px;
background-color: ${props=> props.active ? '#2bff00':'orange'};
border: 2px solid #1f1f1f;
border-radius: 100px;
position: absolute;
bottom: -3px;
right: 8px;
`

export default class AccountPicker extends Component {
  static contextType = AppContext

  constructor(props, context) {
    super(props, context);

    this.form = React.createRef();

    this.state = {
      showsMenu: false
    }
  }

  handleStoreSelection = (store) => {
    if(this.context.state.selectedStore.domain !== store.domain) {
      this.context.setStore(store)
    }
    this.setState({showsMenu: false})
  }

  render() {
    if(!this.context.state.selectedStore) return <span/>
      
    const activeStore = this.context.state.allStores.find(store=> store.domain === this.context.state.selectedStore.domain)
    if(!!!activeStore){
      return <React.Fragment/>
    }
    return(
      <ClickAwayListener onClickAway={()=>{ if(this.state.showsMenu) this.setState({showsMenu: false}) }}>
      <div className="account-picker-container">
        <div className="selected-account-container" onClick={()=>{
          this.setState({showsMenu: !this.state.showsMenu})
        }}>
        <div className="picker-contents">
          <IconContainer active={activeStore.isActive}>
            <img src={`https://www.google.com/s2/favicons?sz=128&domain_url=${this.context.state.selectedStore.domain}`}/>
            <StatusIndicator active={activeStore.isActive}/>
          </IconContainer>
          <div className="text">
            <span className="label">Selected Store</span>
            <span className="value">{ this.context.state.selectedStore.domain }</span>
          </div>
        </div>
        <div className={`arrow ${this.state.showsMenu?'up':'down'}`}/>
        </div>
        <div className={this.state.showsMenu ? "account-options-container open" : "account-options-container"} >
        {
          this.context.state.allStores.map(store => {
            return(
              <div className="item" key={store.domain} onClick={()=>{
                this.handleStoreSelection(store)
              }}>
              <IconContainer active={store.isActive}>
                  <img src={`https://www.google.com/s2/favicons?sz=128&domain_url=${store.domain}`}/>
                <StatusIndicator active={store.isActive}/>
              </IconContainer>
              { store.domain }
              </div>
            )
          })
        }
        </div>
      </div>
      </ClickAwayListener>
    )
  }

}
