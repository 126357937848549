import React, { Component, useState, useEffect, useRef } from 'react';
import memoize from "memoize-one"
import Help from '../Help'
import { FreckleTooltip } from '../FreckleTooltip'
import searchWorker from '../../workers/search.worker'
import {useDraggable} from '@dnd-kit/core';
import {DndContext} from '@dnd-kit/core';
import styled from 'styled-components'
import { EditorContext, ContextAction, useEditorContext } from './Context/EditorContext'
import { useAppContext, AppAction } from '../Context/AppContext'

import TutorialTooltip from '../tutorial/TutorialTooltip'
import { EditorTutorialState } from '../tutorial/tutorial'

import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';

const SCROLL_BATCH_SIZE = 50


const Container = styled.div`
background-color: #222222;
width: 270px;
margin-right: 2px;
height: calc(100vh - 63px);
overflow: hidden;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
transition: margin-left 0.25s;
margin-left: ${props=>{
	return (props.transitionState == 'entered') ? '0px' : '-270px'
}};
`

const Header = styled.div`
width: 100%;
height: 80px;
background-color: #1E1E1E;
display: flex;
flex-direction: column;
justify-content: space-between;
// border-bottom: 1px solid #252525;
box-shadow: rgba(0,0,0,0.15) 0 2px 10px;

input {
	background-color: transparent;
	outline: 0;
	border: 0;
	color: white;
	padding: 10px;
	margin: 0;
	font-family: "Signika";
	font-size: 13px;
	margin-left:25px;
	height:35px;

	background: url(/search-icon.svg);
	background-repeat: no-repeat;
	background-size: 10px;
	background-position: center left;
	padding-left:20px;
}
`

const HeaderTitle = styled.div`
display:flex;
align-items:center;
padding-left: 25px;
color: white;
margin: 0;
margin-top:15px;
font-size: 1em;
font-weight: 700;
user-select: none;
`

const ProductList = styled.div`
height: 100%;
width: 100%;
overflow: scroll;
overflow-x: hidden;
padding-top:5px;
`

const ProductItem = styled.div`
height: 45px;
line-height: 20px;
text-align: left;
width: 235px;
color: white;
font-size: 13px;
line-height: 22px;
display: flex;
justify-content: flex-start;
align-items: center;
padding: 5px 15px;
padding-left:20px;
user-select: none;
border-bottom: 2px solid transparent;
overflow: hidden;
transition: 0.2s;
position: relative;

pointer-events: ${props=> props.active ? 'none':'auto'};
opacity: ${props=> props.active ? 0.4:1};

label {
	padding-right: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	pointer-events: none;
}

img {
	height: 35px;
	width: 35px;
	object-fit: cover;
	background-color: white;
	border-radius: 4px;
	margin-right: 15px;
}

button {
	display: none;
	position: absolute;
	right: 10px;
	top: 15px;
	font-family: "Signika";
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
	color: white;
	background-color: #4da0ff;
	height: 25px;
	border-radius: 1000px;
	padding: 0 10px;
	outline: 0;
	border: 0;
	cursor: pointer;
	transition: 0.15s;

	&:hover {
		box-shadow: 0px 0px 18px -6px #4da0ff;
	}
}

&:hover {
	// background-color: #2f2f2f;
	button {
		display: block;
	}
}
`


// export function DnDDraggable(props) {
//   const {attributes, listeners, setNodeRef, transform} = useDraggable({
//     id: props.id,
//   });
//   const style = transform ? {
//     opacity: 0.75,
//   } : undefined;


//   return (
//     <ProductItem active={props.active} ref={setNodeRef} onDoubleClick={props.onDoubleClick} style={style} {...listeners} {...attributes}>
//       {props.children}
//       <button>Add</button>
//     </ProductItem>
//   );
// }


export function DnDDraggable(props) {
	const {attributes, listeners, setNodeRef, transform} = useDraggable({
		id: props.id,
	});
	const style = transform ? {
		opacity: 0.75,
	} : undefined;


	return (
		<ProductItem active={props.active} ref={setNodeRef} style={style}>
		{props.children}
		<button onClick={props.onClick}>Add</button>
		</ProductItem>
		);
}


export default function ProductsPane(props) {
	const { state, dispatch, groups } = useEditorContext()
	const appContext = useAppContext()
	const { products, editorTutorialState } = appContext

	const [selectedKey, setSelectedKey] = useState(null)
	const [filterString, setFilterString] = useState("")
	const [itemDisplayLimit, setItemDisplayLimit] = useState(SCROLL_BATCH_SIZE)
	const [searchResults, setSearchResults] = useState([])

	const searchWorkerRef = useRef(new searchWorker())

	const containerRef = useRef(null)

	useEffect(()=>{
		return ()=>{
			if(searchWorkerRef.current) searchWorkerRef.current.terminate()
		}
}, [searchWorkerRef])

	useEffect(()=>{
		searchWorkerRef.current.postMessage({type: 'populate', list: products})
		searchWorkerRef.current.addEventListener('message', (e) => {
			if(e.data.type === "searchResults") {
				setSearchResults(e.data.data.map(item=>item.item))
			}
		})
	}, [products])

	const getProductsList = () => {
		let allProducts
		if(!!filterString){
			allProducts = searchResults
		} else {
			allProducts = Object.values(products).slice(0, itemDisplayLimit)
		}
		const allGroupIds = groups.map(group=>group.id)
		return allProducts.map((product, i)=>{
			const isActive = allGroupIds.includes(product.id)
			let imageURL
			if(!!product.imageUrl){
				imageURL = product.imageUrl
			}

			return(
				<DnDDraggable 
				active={isActive} 
				key={product.id} 
				id={product.id}
				onClick={()=>{ 
					dispatch({type: ContextAction.addProduct, itemType: "list", payload: product})
					if (editorTutorialState == EditorTutorialState.addProduct) {
						appContext.dispatch({ type: AppAction.setEditorTutorialState, payload: EditorTutorialState.addSegment });
					}
				}}>
				<img src={imageURL}/>
				<label>{ product.title }</label>
				</DnDDraggable>
				)
		})
	}

	const searchChanged = (e) => {
		setFilterString(e.target.value)
		searchWorkerRef.current.postMessage({type: 'search', searchString: e.target.value})
	}

	const handleScroll = (e) => {
		const obj = e.target
		if( obj.scrollTop === (obj.scrollHeight - obj.offsetHeight)){
			const newLimit = Math.min(itemDisplayLimit + SCROLL_BATCH_SIZE, products.length)
			setItemDisplayLimit(newLimit)
		}
	}

	const addClass = (selectedKey == null) ? "add-button disabled" : "add-button"
	return (
		<CSSTransition
		in={props.visible}
		timeout={props.visible ? 0 : 250}
		unmountOnExit 
		mountOnEnter>
		{(transitionState) => {
			return(
				<TutorialTooltip
				transitionState={transitionState}
				placement={'right'}
				steps={[EditorTutorialState.addProduct]}
				containerRef={containerRef}>
				<Container transitionState={transitionState} ref={containerRef}>
				<Header>
				<HeaderTitle>
				Products
				<Help arrow title="Click the ADD button on products to add them to the Timeline."/>
				</HeaderTitle>
				<input placeholder="Search Products (Name or SKU)" onChange={searchChanged}/>
				</Header>
				<ProductList
				onScroll={handleScroll}>

				{ getProductsList() }
				
				</ProductList>
				</Container>			
				</TutorialTooltip>
				)
		}
		
	}
	</CSSTransition>

	)
	
}
