import React, { Component } from 'react';
import TextInput from './TextInput'
import { PropagateLoader } from "react-spinners"
import { CSSTransition } from 'react-transition-group'

import '../../styles/ProductsManagerView.scss';

import { currency_symbols } from '../constants/currency-symbols'


export default class ProductsManagerPane extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
    	product: null
    }
  }

  componentDidMount() {
    
  }

  static getDerivedStateFromProps(props, state) {
    if(props.product && (!state.product || state.product.id !== props.product.id)) {
      return {product: props.product}
    }
    return null
  }

  render(){
    const product = this.state.product
  	return(
      <div className="library">
        <img className="header-image" src={product.imageUrl}/>
        <div className="details">
          <h3>{ product.title }</h3>
          <span className="price-label">{currency_symbols[product.currency]}{product.price}</span>

          <div className="product-options-list">
            <a className="item" href={product.linkUrl} target="_blank">
              Open Product Page
            </a>
          </div>

        </div>
      </div>
		)
  }

}