import localforage from "localforage"
import jwt_decode from "jwt-decode";

import * as Analytics from '../helpers/analytics'

import { getProfile, updateProfile } from './freckle-api'

import { login } from './freckle-api'

const moment = require("moment")

let jwtToken
let authenticatedUser = null

let fetchingCurrentUser = false
let fetchCurrentUserPromise = null

export function sendLogoutEvent() {
	document.dispatchEvent(new CustomEvent("authEvent", {detail: {type: "loggedOut"}}))
}

function sendLoginEvent() {
	document.dispatchEvent(new CustomEvent("authEvent", {detail: {type: "loggedIn"}}))
}

export function currentAuthenticatedUser(options={}) {
	if(!fetchingCurrentUser){
		console.log("not fetching current user")
		fetchingCurrentUser = true
		fetchCurrentUserPromise = new Promise(async (resolve, reject)=>{
			if(!!!authenticatedUser || options.bypassCache) {
				// fetch user profile via freckle api
				try {
					authenticatedUser = await getProfile()
					authenticatedUser.username = authenticatedUser.email
					fetchingCurrentUser = false

					if (!authenticatedUser.stripeSubscriptionId  && window.location.pathname !== '/pickplan') {
						window.location = "/pickplan"
					} else if (!!authenticatedUser.stripeSubscriptionId && !authenticatedUser.stores.length && window.location.pathname !== '/addstore') {
						window.location = "/addstore"
					}

					// identify the current to Mixpanel
					Analytics.identify(authenticatedUser.email, {
						admin: authenticatedUser.admin,
						email: authenticatedUser.email,
						name: `${authenticatedUser.firstName} ${authenticatedUser.lastName}`,
						firstName: authenticatedUser.firstName,
						lastName: authenticatedUser.lastName,
						plan: authenticatedUser.plan,
						userId: authenticatedUser.id,
						env: process.env.NODE_ENV
					})

					resolve(authenticatedUser)
				} catch(e) {
					console.log(e)
					if(window.location.pathname !== '/login' && window.location.pathname !== '/createaccount' && !window.location.pathname.includes('/activate') && !window.location.pathname.includes('/reset') && !window.location.pathname.includes('/invite')){
						window.location = '/login'
					}
					fetchingCurrentUser = false
					console.log("we did not fetch a user")
					reject(new Error("no authenticated user"))
				}
			} else {
				resolve(authenticatedUser)
				fetchingCurrentUser = false
			}
		})
	}
	return fetchCurrentUserPromise
}

export async function updateUser(config, options={}) {
	return updateProfile(config)
}

export async function getJwtToken() {
	if(!!!jwtToken){
		jwtToken = await localforage.getItem('jwtToken')
		if(!!!jwtToken){
			sendLogoutEvent()
			throw "JWT token missing!"
		}
	}
	const decodedJwtToken = jwt_decode(jwtToken)
	if(moment.unix(decodedJwtToken.exp).isAfter(Date.now())) {
		// if not expired, return it
		return jwtToken
	} else {
		// if expired, fetch a new token then return it
		sendLogoutEvent()
	}
}

export async function signIn(username, password) {
	const resp = await login(username, password)
	jwtToken = resp.accessToken
	await localforage.setItem('jwtToken', jwtToken)
	sendLoginEvent()

	return jwtToken
}

export async function signOut() {
	jwtToken = null
	await localforage.setItem('jwtToken', null)
	window.location = "/login"
	return
}

