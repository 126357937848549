import React, { Component, useState, useEffect } from 'react';
import Dropdown from 'react-dropdown'
import TextInput from './TextInput'
import { BeatLoader } from "react-spinners";
import { createTemplate } from '../modules/freckle-api.js'
import { logEvent } from '../helpers/analytics.js'
import LibraryItem from './LibraryItem'
import { MicrositeTemplates } from './microsites/templates'
import styled, { keyframes } from 'styled-components'
import { AppContext, AppAction } from './Context/AppContext'
import Button from './Button'
import AddStoreView from './AddStoreView'
import { CSSTransition } from 'react-transition-group'
import { ClickAwayListener } from '@material-ui/core'

var fs = require('fs')

const FermData = require('form-data')
const axios = require('axios')

const REQUIRED_FIELDS = ["title"]

const Warning = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height: 100%;
`

const H3 = styled.h2`
color: white;
font-family: Signika;
margin: 0 !important;
padding: 0 !important;
`

const P = styled.p`
font-family: Signika;
color: #9f9f9f;
margin: 10px 0 50px 0;
padding: 0;
line-height: 25px;
max-width: 400px;
text-align: center;
`

const Background = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
pointer-events: none;
display: flex;
justify-content: center;
align-items: center;
transition: background-color 0.25s;
background-color: ${({transitionState})=> (transitionState == 'entered') ? 'rgba(0,0,0,0.7)':'transparent'};
pointer-events: all;`

const Enter = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

const Container = styled.div`
pointer-events: all;
width: 65vw;
min-width: 500px;
max-width: 1000px;
height: 85vh;
max-height: 1000px;
background-color: #111;
color: white;
// padding: 20px 40px 0px 40px;
position: relative;
border-radius: 10px;
overflow: hidden;
padding: 0;
margin: 0;
border: 1px solid rgba(255,255,255,0.05);

transition: transform 0.25s, opacity 0.25s;
transform: ${({transitionState})=> (transitionState == 'entered') ? 'scale(1)':'scale(0.95)'};
opacity: ${({transitionState})=> (transitionState == 'entered') ? '1':'0'};

.editable-content {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% - 50px);

  .step-container {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
  }
}

.header {
  padding: 0 10px 0 20px;
  width: calc(100% - 30px);
  height: 50px;
  box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1f1f1f;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;

    img {
      height: 15px;
      margin-right: 10px;
      margin-bottom: 2.5px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      outline: none;
      border: none;
      background-color: transparent;
      color: white;
      font-size: 25px;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      cursor: pointer;
      opacity: 0.5;
      transition: 0.25s;
    }
    button:hover {
      opacity: 1;
      background-color: rgba(255,255,255,0.05);
    }
  }
}`


export default function AddStore(props) {
  return(
    <CSSTransition
      onExited={()=>{
        
      }}
      in={props.display}
      timeout={250}
      unmountOnExit 
      mountOnEnter>
      {(transitionState) => {
        return <Background transitionState={transitionState}>
          <ClickAwayListener onClickAway={props.onCancel}>
          <Container transitionState={transitionState}>
            <div className="header">
              <div className="left">
                <h4>Add a store</h4>
              </div>
              <div className="right">
              <button onClick={props.onCancel}>×</button>
              </div>
            </div>
            <div className="editable-content">
              
              <AddStoreView 
              fromSettings
              onAdded={props.onCancel}/>

            </div>
          </Container>
          </ClickAwayListener>
        </Background> 
      }}
      </CSSTransition>
    )
}
