import styled, {keyframes} from "styled-components";
import React from 'react';

const enterLoaderAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
}
`

const MiddlePane = styled.div`
padding: 30px 80px;
flex-grow: 1;
width: calc(100% - 160px);
overflow: scroll;
padding-bottom: 100px;
color: white;
animation: enterLoaderAnim 500ms normal forwards;
position: relative;
`

const Header = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
margin-bottom: 20px;
`

const H2 = styled.h2`
display: flex;
align-items: center;
`

const HeaderImg = styled.img`
padding-right: 10px;
height: 18px;
`

export default function Base(props){
	return(
		<MiddlePane>
	        <Header>
	          <H2>{props.title}</H2>
	        </Header>
	        { props.children }
        </MiddlePane>
	)
}