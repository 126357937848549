import React, { Component } from 'react';
import "../../styles/PickerMenu.scss"
import { ClickAwayListener } from '@material-ui/core'

export default class PickerMenu extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false
    }
  }

  getOptions = () => {
    return this.props.options.map(value => {
      return <div className="item" onClick={()=>{
          this.setState({open: false}, ()=>{
            this.props.onChange(value.id)
          })
        }}>
        {value.name}
        </div>
    })
  }

  render(){
    return(
      <ClickAwayListener onClickAway={()=>{ if(this.state.open) this.setState({open: false}) }}>
      <div className="picker-menu">
        <div className={this.state.open ? "picker-select-box selected" : "picker-select-box"} onClick={()=>{
          this.setState({open: !this.state.open})
        }}>
        { this.props.options.find(option=> option.id === this.props.selectedOptionId).name }
        <div className={`arrow ${this.state.open?'up':'down'}`}/>
        </div>
        <div className={this.state.open ? "picker-options-container selected" : "picker-options-container"}>
        { this.getOptions() }
        </div>
      </div>      
      </ClickAwayListener>
    )
  }

}
