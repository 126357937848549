import React, { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import GridSelector, { GridSelectorStyle } from '../microsites/GridSelector'
import SwatchColorPicker from '../SwatchColorPicker'
import { HotspotType, coloredHotspotIcon } from './Hotspot/hotspot-types'
import { EditorContext, useEditorContext, ContextAction } from './Context/EditorContext'
import Help from '../Help'
import PickerMenu from '../PickerMenu'

const Container =  styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 10px 25px;

	opacity: ${props=> props.enabled ? '1':'0.35'};
	pointer-events: ${props=> props.enabled ? 'auto':'none'};
	filter: ${props=> props.enabled ? 'grayscale(0%)':'grayscale(100%)'};
`

const Title = styled.div`
	display:flex;
	align-items:center;
	margin: 0;
	margin-top:15px;
	font-size: 0.9em;
	font-weight: 700;
	padding-bottom:10px;
	user-select: none;
	color: #7f7f7f;
`

export default function HotspotProperties(props){
	const context = useEditorContext()
	const [selectedColorPicker, setSelectedColorPicker] = useState(null)

	const group = context.state.groups.find(group=> group.id === context.state.selectedGroupId)
	let color = group.properties.color
	let accentColor = group.properties.accentColor

	return(
		<Container {...props}>
		<Title>Hotspot Style
		<Help arrow title="Select a hotspot icon style to display for this product."/></Title>
		<GridSelector
		gridStyle={GridSelectorStyle.bold}
		tabs={[
			{ 
				id: HotspotType.bag, 
				group: group, 
				color: color,
				accentColor: accentColor,
				imageSrc: `data:image/svg+xml;base64,${btoa(coloredHotspotIcon('bag', color, accentColor))}`, 
			},
			{ 
				id: HotspotType.bag4, 
				group: group,
				color: color,
				accentColor: accentColor,
				imageSrc: `data:image/svg+xml;base64,${btoa(coloredHotspotIcon('bag4', color, accentColor))}`, 
			},
			{ 
				id: HotspotType.plus, 
				group: group,
				color: color,
				accentColor: accentColor,
				imageSrc: `data:image/svg+xml;base64,${btoa(coloredHotspotIcon('plus', color, accentColor))}`, 
			},
			{ 
				id: HotspotType.circleProductImage, 
				color: color,
				accentColor: accentColor,
				group: group,
			},
			{ 
				id: HotspotType.roundedProductImage, 
				color: color,
				accentColor: accentColor,
				group: group,
			},
		]}
		selected={ group.properties.hotspotType || "bag" }
		didSelectId={(id)=>{
			context.dispatch({type: ContextAction.setGroupProperties, payload: {hotspotType: id}})
		}}/>

		<Title>Hotspot Color
		<Help arrow title="Choose a primary background color for this hotspot."/></Title>
		<SwatchColorPicker 
		property="color" 
		color={ color } 
		selectedProperty="color"
		onChange={(value, property)=>{
			context.dispatch({type: ContextAction.setGroupProperties, payload: {color: value}})
		}}/>

		<Title>Hotspot Accent Color
		<Help arrow title="Choose a secondary accent color for this hotspot."/></Title>
		<SwatchColorPicker 
		property="accentcolor" 
		color={ accentColor } 
		selectedProperty="accentcolor"
		onChange={(value, property)=>{
			context.dispatch({type: ContextAction.setGroupProperties, payload: {accentColor: value}})
		}}/>

		<Title>Hotspot Size</Title>
		<PickerMenu
		options={[
			{
				id: "small",
				name: "Small",
			},
			{
				id: "medium",
				name: "Medium",
			},
			{
				id: "large",
				name: "Large",
			},
		]}
		selectedOptionId={group.properties.size || "medium"}
		onChange={(value)=>{
			context.dispatch({type: ContextAction.setGroupProperties, payload: {size: value}})
		}}
		/>

		</Container>
	)
}