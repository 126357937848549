import React from 'react'
import styled from 'styled-components'
import Dropzone from 'react-dropzone';
import Slider from '../Slider'

const DropViz = styled.div`
	width: calc(100% - 15px);
	height: 100px;
	background-color: #2f2f2f;
	transition: 0.25s;
	cursor: pointer;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&:hover{
		background-color: #3f3f3f;
	}
`

const Img = styled.img`
	pointer-events: none;
	height: 20px;
	opacity: 0.2;
`

const H5 = styled.h5`
	color: white;
	margin: 0;
	padding: 0;
	margin-top: 10px;
	opacity: 0.5;
`

const PreviewContainer = styled.div`
	width: calc(100% - 15px);
	height: 100px;
	background: url('/checks.svg');
	background-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
		border-radius: 4px;
`

const Logo = styled.img`
	max-height: 40%;
`

const ClearButton = styled.button`
	font-family: "Signika";
	border: none;
	outline: none;
	background-color: transparent;
	color: #8f8f8f;
	font-size: 12px;
	font-weight: 700;
	transition: 0.2s;
	cursor: pointer;
	float: right;
	margin-right: 15px;
	height: 30px;
	padding: 0 15px;
	margin-top: 5px;
	border-radius: 4px;

	&:hover {
		background-color: rgba(255,255,255,0.2);
		color: white;
	}
`

export default function FilePicker(props){

	const toBase64 = (file) => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	})

	const loadFile = async (files) => {
		if(!!!files.length) return
	    let file = files[0]
		const base64Image = await toBase64(file)
		props.didSelectImage(base64Image)
	}

	if(!!props.data){
		return(
			<React.Fragment>
			<PreviewContainer>
			<Logo src={props.data}/>
			</PreviewContainer>
			<ClearButton onClick={()=>{
				props.didSelectImage(null)
			}}>Clear Logo</ClearButton>
			<Slider 
			label="Logo Size"
			onChange={(value, property)=>{
				props.didChangeSize(value)
			}} property="" value={props.scale} max={100}/>
			</React.Fragment>
		)
	}

	return(
		<Dropzone accept="image/png,image/jpg,image/jpeg,image/bmp,image/svg+xml" onDrop={loadFile}>
        {({getRootProps, getInputProps}) => (
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <DropViz>
              <Img src="/upload.svg"/>
              <H5>Click or drag your logo image here</H5>
              </DropViz>
          </div>
        )}
      </Dropzone>
	)
}