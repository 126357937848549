import React, { useState } from 'react'
import Timeline from './Timeline'
import HotspotProperties from './HotspotProperties'
import { FreckleTooltip } from '../FreckleTooltip'
import { currency_symbols } from '../../constants/currency-symbols'
import { EditorContext, useEditorContext, ContextAction } from './Context/EditorContext'
import TabSelector from './TabSelector'
import Switch from '../Switch'
import styled, { css, keyframes } from 'styled-components'

const Container = styled.div`
	position: relative;
	height: calc(100vh - 62px);
	overflow: hidden;
	display: flex;
	flex-direction: column;
`

const EnableContainer = styled.div`
width: calc(100% - 40px);
margin: 5px 20px;
margin-top: 25px;
padding-bottom: 25px;

display: flex;
flex-direction: row;
align-items: center;

border-bottom: 1px solid #2f2f2f;

h3 {
	float: right;
	padding: 0;
	margin: 0;
	margin-left: 12.5px;
	color: white;
	font-size: 0.9em;
}
`

function EnableSection(props) {
	return (
		<EnableContainer>
		<Switch 
		enabled={props.group.itemType == 'hotspot'}
		onChange={val=>{
			if (val) {
				// set group to type hotspot
				props.context.dispatch({ type: ContextAction.setProductType, payload: 'hotspot' })
			} else {
				// set group to type list
				props.context.dispatch({ type: ContextAction.setProductType, payload: 'list' })
			}
		}}/>

		<h3>
		Display as Hotspot
		</h3>
		</EnableContainer>
	)
}

export default function PropertiesVew(props){
	const [selectedTabIndex, setSelectedTabIndex] = useState(0)
	const context = useEditorContext()

	const getProductHeader = () => {
		if(!context.state.selectedGroupId) return <span/>

		const group = props.group
		if(!!!group) return <span/>

		const product = props.product
		if(!product){
			return(
				<div className="product-header">
				
				</div>
			)
		}

		const imageUrl = product.imageUrl
		
		let price =  currency_symbols[product.currency] + parseFloat(product.price).toFixed(0)

		return(
			<div className="product-header">
			<img src={imageUrl}/>
			<div className="product-header-details">
			<div title={product.title} className="product-title">{product.title}</div>
			<div className="product-price">{price}</div>
			</div>
			<div className="trash-container" onClick={()=>{ 
				context.dispatch({type: ContextAction.deleteGroup, payload: group})
			}}>
			<img className="trash-icon" src="/trash-icon.svg"/>
			</div>
			</div>
			)
	}

	if(!!props.group) {
		return (
			<Container>
			{ 
			/*<TabSelector 
			items={["Project", "Selected Product"]}
			selectedIndex={0}/>*/
			}

			{ getProductHeader() }

			<EnableSection {...props} context={context}/>

			<HotspotProperties 
			enabled={props.group.itemType == 'hotspot'}
			product={props.group} 
			key={props.group.id}/>
			</Container>
		)

	} else {
		return (
			<div className="keyframes-zero-state">
			No Product Selected
			</div>
		)

	}

}




