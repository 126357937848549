import React, { useState } from 'react'
import TrackSettings from './Editor/TrackSettings/TrackSettings'

export default function TrackTest(props){
	return(
		<div>
		<TrackSettings 
			visible={true} 
			src={`https://stream.freckle.shop/fa3d2128-55a8-4c18-bc36-9dfd87cb09c3/mp4/base.mp4`} 
			// src={`https://stream.freckle.shop/f2b78bba-2d22-4d6c-b956-c784cf2de97b/mp4/base.mp4`}
			time={4}
			startPosition={{x:0, y:0}}
			onSuccess={(freckles)=>{
				debugger
			}}
			onCancel={()=>{}}/>
		</div>
	)
}