import React, { Component } from 'react';

import { BeatLoader } from "react-spinners";
import TextInput from './TextInput'

import { enrollInBeta } from '../modules/auth'

import '../../styles/Activate.scss';

export default class Activate extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      errorMessage: null,
      loading: false
    }
  }

  componentDidMount() {
     
  }

  submitEnrollment = (e) => {
    e.preventDefault()

    const invitecode = e.target.elements.invitecode.value
    if(invitecode.length === 0) {
      this.setState({loading: false, errorMessage: "Invite code cannot be empty."})
      return
    } else {
      this.setState({loading: true})
    }


  }

  getErrorMessage = () => {
    if(this.state.errorMessage){
      return (
        <div className="error-container">
          <div className="error-message">
            <img src="/error.svg"/>
            {this.state.errorMessage}
          </div>
        </div>
      )
    } else {
      return <div className="error-container"/>
    }
  }

  render(){
    return(
      <div className="container">
      { this.getErrorMessage() }
        <div className="message">
          <div className="logo"></div>
          <p>Last step!  Enter your invite code to enable your account. 🔥🔥</p>
          <form onSubmit={this.submitEnrollment}>
            <TextInput name="invitecode" label="Invite Code *"/>
            <button className="submitButton">
              { this.state.loading ? (<BeatLoader size="5px" color="white"/>) : "JOIN BETA" }
            </button>
          </form>
        </div>
      </div>
    )
  }

}