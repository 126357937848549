import { Tooltip } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const FreckleTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    fontFamily: "Signika",
    boxShadow: "0px 0px 18px 0px rgba(0,0,0,0.15)"
  },
  arrow: {
  	color: '#fff',
  }
}))(Tooltip);