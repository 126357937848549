import React, { Component } from 'react';
import '../../styles/Slider.scss';

export default class Slider extends Component {
	constructor(props, context) {
		super(props, context);
		
		this.state = {
			
		}

		this.inputRef = React.createRef()
		this.sliderRef = React.createRef()
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(this.sliderRef.current.value !== nextProps.value) this.sliderRef.current.value = 100 * nextProps.value / nextProps.max
	}

	render() {
		const isCurrent = this.props.property === this.props.selectedProperty
		const { value, max, property } = this.props
		return(
			<div className="slider-container">
				{!!this.props.label && <label>{this.props.label}</label>}
				<input 
				ref={this.sliderRef}
				className="range" 
				type="range" 
				min={this.props.minPerc || "0"}
				max="100" 
				defaultValue={100*value/max} 
				onChange={(e)=>{ this.props.onChange((max * e.target.value/100), property) }}/>
	        </div>
		)
	}
}