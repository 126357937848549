import { createContext, useContext, useState, useEffect, useReducer } from 'react'
import React from 'react'
import localforage from "localforage"

import * as Auth from '../../modules/auth'
import * as API from '../../modules/freckle-api'

import { EditorTutorialState } from '../tutorial/tutorial'

export const AppAction = {
	setVideos: "setVideos",
	setProducts: "setProducts",
	setTemplates: "setTemplates",
	setSyncingProducts: "setSyncingProducts",
	setAllStores: "setAllStores",
	setSelectedStore: "setSelectedStore",
  setUser: "setUser",
  setEditorTutorialState: "setTutorialState",
}

export const AppContext = createContext();

export function AppContextProvider(props) {
	const [state, dispatch] = useReducer((state, action)=>{

		switch(action.type){
			case AppAction.setVideos:
				return({
					...state,
					videos: action.payload,
					hasLoadedVideos: true
				})

				// TODO: store products in hash for easier lookup, refactor array usage with Object.values.
				// or just store both an array and a hash and don't worry about it...
			case AppAction.setProducts:
				let _productsLut = {}
				action.payload.forEach(product=> _productsLut[product.id] = product )
				return({
					...state,
					products: action.payload,
					productsLut: _productsLut
				})

			case AppAction.setTemplates:
				return({
					...state,
					templates: action.payload
				})

			case AppAction.setSyncingProducts:
				return({
					...state,
					syncingProducts: action.payload
				})

			case AppAction.setSelectedStore:
				let newStore = action.payload
				return({
					...state,
					selectedStore: newStore
				})

			case AppAction.setEditorTutorialState:
				localforage.setItem('editor-tutorial-state', action.payload)
				return({
					...state,
					editorTutorialState: action.payload,
				});

			case AppAction.setAllStores:
				const allStores = action.payload
				if(!!!allStores) return state
				return({
					...state,
					allStores: allStores
				})

      case AppAction.setUser:
        return({
          ...state, 
          user: action.payload
        })
		}

	}, 
	{
		products: [],
		productsLut: {},
		templates: [],
		videos: [],
		hasLoadedVideos: false,
		syncingProducts: false,
		selectedStore: null,
		allStores: [],
    user: null,
    editorTutorialState: null,
	})

	useEffect(async ()=>{
		const editorTutorialState = await localforage.getItem('editor-tutorial-state')
		dispatch({ type: AppAction.setEditorTutorialState, payload: editorTutorialState || EditorTutorialState.addProduct });
	}, [])

  const fetchUser = async (options={}) => {
    const _user = await Auth.currentAuthenticatedUser(options)
    dispatch({ 
    	type: AppAction.setUser, 
    	payload: _user,
    })
		dispatch({
			type: AppAction.setAllStores, 
			payload: _user.stores,
		})
    return _user
  }

  const createNewAccount = async (payload) => {
  	const _user = await API.createAccount(payload)
  	await localforage.setItem('jwtToken', _user.accessToken)
  	await fetchUser()
  }

  const bootstrapSelectedStore = () => {
    const bsselectedStore = state.allStores.length ? state.allStores[0] : null
    localforage.setItem("currentlySelectedStore", bsselectedStore)
    dispatch({ type: AppAction.setSelectedStore, payload: bsselectedStore })
    syncProducts(bsselectedStore)
  }

  const setStore = async (_newStore) => {
    let newStore = _newStore
    if(!!state.allStores.length && !!!state.allStores.find(store=> store.domain === newStore.domain)){
      newStore = state.allStores[0]
    }
    localforage.setItem("currentlySelectedStore", newStore)
    dispatch({ type: AppAction.setSelectedStore, payload: newStore })

    syncProducts(newStore)
  }

	const syncProducts = async (store, force=false) => {
    dispatch({type: AppAction.setSyncingProducts, payload: true})
		dispatch({type: AppAction.setProducts, payload: []})

		API.syncProducts(store, force, (res, error)=>{
		  if( error ){
		    dispatch({type: AppAction.setSyncingProducts, payload: false})
		    document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":error.message, "type":"error"}}))
		  } else {
		    if(res.data.length > 0){
		      if(!res.cached) document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text": "Products synced!", "type":"success"}}))
		        dispatch({type: AppAction.setSyncingProducts, payload: false})
		    }
		    dispatch({type: AppAction.setProducts, payload: res.data})
		  }
		})
	}

  const syncVideos = async () => {
    const videos = await API.allVideos()
    const items = [...videos].sort((a, b) =>{
      return new Date(b.updatedAt) - new Date(a.updatedAt)
    })
    dispatch({type: AppAction.setVideos, payload: items})
  }


  const syncTemplates = async (force) => {
    try {
      const templates = await API.allTemplates()
      const items = [...templates].sort((a, b) =>{
        return new Date(b.updatedAt) - new Date(a.updatedAt)
      })
      dispatch({type: AppAction.setTemplates, payload: items})
    } catch(e) {
      document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":"Templates sync failed.", "type":"error"}}))
    }
  }

	return (
		<AppContext.Provider value={{
			state, 
			dispatch,
      syncProducts,
      bootstrapSelectedStore,
      setStore,
      syncVideos,
      syncTemplates,
      fetchUser,
      createNewAccount,
			...state
		}}>
		{props.children}
		</AppContext.Provider>
	)
}

export function useAppContext() {
	return useContext(AppContext)
}

