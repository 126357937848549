import React, { Component, useState, useRef } from 'react'
import styled from 'styled-components'
import { BeatLoader } from "react-spinners";
import TextInput from './TextInput'
import Button from './Button'
import * as API from '../modules/freckle-api'

const Container = styled.div`
width: 100vw;
height: 100vh;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
`

const Content = styled.div`
width: 400px;
flex-direction: row;
align-items: center;
justify-content: center;
`

const H2 = styled.h2`
color: white;
font-family: Signika;
margin: 0;
padding: 0;
`

const P = styled.p`
font-family: Signika;
color: #9f9f9f;
margin: 10px 0 50px 0;
padding: 0;
line-height: 25px;
`

const Icon = styled.img`
height: 50px;
width: 50px;
margin-bottom: 15px;
`

export default function ResetPassword(props){
	const textRef = useRef(null)
	const textRef2 = useRef(null)

	const [loading, setLoading] = useState(false)
	const [hasReset, setHasReset] = useState(false)

	const submitPassword = async () => {
		const password = textRef.current.inputRef.current.value
		const passwordAgain = textRef2.current.inputRef.current.value

		if(!!!passwordAgain.length || !!!password.length) {
			document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":"Complete all fields.", "type":"error"}}))
			return
		}

		if(password !== passwordAgain ) {
			document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":"Password fields must match.", "type":"error"}}))
			return
		}

		const b64 = props.match.params.data
		const json = atob(b64)
		const payload = JSON.parse(json)

		const email = payload.email
		const code = payload.code

		setLoading(true)

		try {
			const pwdResp = await API.resetPassword(email, code, password)
			setHasReset(true)
		} catch(e) {
			document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":e.response.data.message, "type":"error"}}))
		}

		setLoading(false)
	}

	return(
		<Container>
			{!hasReset && <Content>
				<H2>Reset Password</H2>
				<P>Enter your new password.</P>
				<TextInput ref={textRef} name="password" label="Password *" type="password"/>
				<TextInput ref={textRef2} name="password2" label="Password Again *" type="password"/>
				<Button onClick={submitPassword} loading={loading} fullWidth margin='40px 0 0 0'>Save Password</Button>
			</Content>}
			{hasReset && <Content>
				<Icon src="/check.svg"/>
				<H2>Success!</H2>
				<P>Your password has been reset!  Sign back in using your updated credentials.</P>
				<Button onClick={()=>{
					props.history.push("/login")
				}} loading={loading} fullWidth ghost margin='40px 0 0 0'>SIGN IN</Button>
			</Content>}
		</Container>
	)
}