import React, { Component } from 'react';
import TextInput from './TextInput'
import { PropagateLoader } from "react-spinners"
import { CSSTransition } from 'react-transition-group'

import ProductsManagerPane from './ProductsManagerPane'

import { BeatLoader } from "react-spinners";

import '../../styles/ProductsManagerView.scss';

import { syncProducts } from '../modules/freckle-api.js'

import ReactResizeDetector from 'react-resize-detector';

import { AppContext, AppAction } from './Context/AppContext'

import { currency_symbols } from '../constants/currency-symbols'

const GUTTER_SIZE = 15
const SCROLL_BATCH_SIZE = 100

export default class ProductsManagerView extends Component {
  static contextType = AppContext

  constructor(props, context) {
    super(props, context);

    this.state = {
    	selectedProductId: null,
    	addingProduct: false,
    	subscriptions: null,
    	itemDisplayLimit: SCROLL_BATCH_SIZE
    }

    this.scrollArea = React.createRef();
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  libraryPanel = () => {
  	const product = this.context.state.products.find((item)=> item.id === this.state.selectedProductId )
  	return(
  		<CSSTransition in={this.state.selectedProductId !== null} timeout={500} classNames="library" unmountOnExit>
  		<ProductsManagerPane product={product} onArchive={this.archive}/>
  		</CSSTransition>
  	)
  }

  productItems = () => {
  	if(this.context.state.syncingProducts) {
  		return(
  			<div className="products-loading-container">
  			<BeatLoader color="#ffffff" size="5px"/>
  			<h4 style={{"marginTop":"15px"}}>Syncing Products</h4>
  			<p>We're syncing your products...hang in there!</p>
  			</div>
  		)
  	} else if(this.context.state.products === null || this.context.state.products.length === 0) {
  		return(
  			<div className="products-loading-container">
  			<h4>No Products</h4>
  			<p>Update your website URL in Settings to sync products.</p>
  			</div>
  		)
  	}
  	const productPanels = this.context.state.products.slice(0, this.state.itemDisplayLimit).map((product)=>{
  		const itemClass = product.id === this.state.selectedProductId ? "item selected" : "item"
  		return(
  			<div key={product.id} className={itemClass} onClick={()=>{ this.setState({selectedProductId: product.id}) }}>
  			<img src={product.imageUrl} onError={(e)=>{ e.target.style.visibility = 'hidden' }}/>
  			<div className="info">
	          <span className="title">{product.title}</span>
	          <span className="updated">{currency_symbols[product.currency]}{product.price}</span>
	        </div>
  			</div>
  		)
  	})
  	return(
  		<div className="products-content-pane-container">
	  		<div className="products-content-pane">
			  		{ productPanels }
	  		</div>
	  	</div>
  	)
  }

  closeLibrary = (e) => {
  	if(e.target.className === "products-pane" || e.target.className === 'products-content-pane' || e.target.className === "products-content-pane-container"){
  		this.setState({selectedProductId: null})
  	}
  }

  closeOverlay = (e) => {
  	if(e.target.className === "overlay-pane"){
  		this.setState({addingProduct: false})
  	}
  }

  syncProducts  = () => {
  	syncProducts((products)=>{
      this.context.dispatch({type: AppAction.setProducts, payload: products})
    })
  }

  handleScroll = (e) => {
  	const obj = e.target
  	if( obj.scrollTop === (obj.scrollHeight - obj.offsetHeight)){
  		const newLimit = Math.min(this.state.itemDisplayLimit + SCROLL_BATCH_SIZE, this.context.state.products.length)
  		this.setState({itemDisplayLimit: newLimit})
  	}
  }

  reloadProducts = () => {
    this.context.syncProducts( this.context.state.selectedStore, true )
  }

  render(){
    console.log("roinder: " + this.context.state.selectedStore + " | " + this.context.state.products.length)
  	const overlayClass = this.state.addingProduct ? "overlay-pane" : "overlay-pane hidden"
  	return(
  		<div className="main-container">
	  		<div className="products-pane" onClick={this.closeLibrary} onScroll={this.handleScroll}>
		  		<div className="products-header">
			  		<h2>Products</h2>
			  		<button onClick={this.reloadProducts}>
            <img src="./sync.svg"/>
            Sync Products
            </button>
		  		</div>

		  		{ this.productItems() }

	  		</div>
	  		{ this.libraryPanel() }

	  		<div className={overlayClass} onClick={this.closeOverlay}>
	          
	        </div>

  		</div>
  		)
  }

}

