import React, { useState, useRef, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Button from '../Button'
import ClosePathButton from '../Editor/ClosePathButton'
import { useHistory } from "react-router-dom";
import localforage from "localforage"

const TutorialStep = {
	dragToAdd: 0,
	scrubAndAdjust: 1,
	closePath: 2
}

const Fade = keyframes`
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
`

const VideoHoverAnimation = keyframes`
	0%, 100% { 
		transform: perspective(600px) rotateY(2deg);
		box-shadow: -10px 10px 10px 0px rgba(0,0,0,0.2);
	}
	50% { 
		transform: perspective(600px) rotateY(2.5deg) scale(1.01); 
		box-shadow: -15px 15px 25px 0px rgba(0,0,0,0.2);
	}
`

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #2f2f2f;
	z-index: 10000;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	font-family: Signika;
	animation: ${Fade} 1s ease-in-out forwards;
	animation-iteration-count: 1;
`

const TutorialContainer = styled.div`
	background-color: #2f2f2f;
	width: 90vw;
	height: calc(100% - 325px);
	flex: 1;
	justify-content: center;
	align-items: center;
	display: flex;
	padding-bottom: 50px;
`

const Video = styled.video`
	width: 100%;
	height: 100%;
	transform: perspective(600px) rotateY(2deg);
	border-radius: 15px;
	box-shadow: -10px 10px 10px 0px rgba(0,0,0,0.2);
	animation: ${VideoHoverAnimation} 10s ease-in-out forwards;
	animation-iteration-count: infinite;
	background-color: #111;
`

const VideoContainer = styled.div`
	width: 90%;
	height: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: calc(100% - 500px);
`

const InstructionAnimation = keyframes`
	0% { opacity: 0; transform: scale(0.8); }
	100% { opacity: 1; transform: scale(1); }
`

const InstructionPanelContainer = styled.div`
	height: 100%;
	width: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 50px;
`

const StepTitle = styled.h1`
	color: white;
	font-size: 35px;
	font-weight: 700;
	margin: 0;
	padding: 0;
	padding-top: 15px;
	animation-name: ${InstructionAnimation};
	animation-duration: 1s;
`

const StepBody = styled.p`
	color: white;
	font-size: 20px;
	max-width: 400px;
	line-height: 45px;
	font-weight: 400;
	margin: 0 10px;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	animation-name: ${InstructionAnimation};
	animation-duration: 1s;
	overflow: scroll;
`

const StepFooter = styled.div`
	padding: 30px 0 30px 0;
	width: calc(100% - 120px);
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 0;
`

const Header = styled.div`
	padding-top: 25px;
	padding-bottom: 30px;
	height: 100px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0;
	margin-bottom: 20px;
`

const SkipButton =  styled.button`
	outline: none;
	border: none;
	background-color: transparent;
	position: absolute;
	top:  20px;
	right: 55px;
	color: #5f5f5f;
	font-size: 12px;
	font-weight: 700;
	cursor: pointer;
	transition: 0.2s;
	padding: 15px 10px;
	border-radius: 4px;

	&:hover {
		color: #4A90E2;
		background-color: rgba(255,255,255,0.05);
	}
`

const H3 = styled.h3`
	color: white;
	margin: 0;
	padding: 0;
	padding-bottom: 0px;
	color: #555555;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Img = styled.img`
	height: ${props=> props.height}px;
	margin-bottom: 15px;
`

const ProgressBarContainer = styled.div`
	width: 400px;
	height:4px;
	background-color: #1f1f1f;
	border-radius: 2px;
	position: relative;
	margin-top: 30px;
`

const ProgressBarIndicator = styled.div`	
	height: 100%;
	width: ${props=> props.currentStep/(props.totalSteps-1) * 100 || 0}%;
	position: absolute;
	top: 0;
	left: 0; 
	background-color: #4A90E2;
	border-radius: 2px;
	transition: 0.75s;
`

const ProgressMarkerContainer = styled.div`
	position: absolute;
	top: -5px;
	left: 0;
	width: 100%;
	height: 14px;
	display: flex;
	justify-content: space-between;
	align-items: space-between;
	z-index: 1000;
`

const ProgressMarker = styled.div`
	width: 6px;
	height: 6px;
	border-radius: 1000px;
	background-color: ${props=> props.enabled ? '#4A90E2' : '#333333'};
	border: 4px solid ${props=> props.enabled ? '#4A90E2' : '#1f1f1f'};
	transition: 0.25s;
`

const Emoji = styled.div`
	font-size: 40px;
	margin-bottom: 25px;
`

function ProgressBar(props){
	let markers = []
	for(let i=0; i<props.totalSteps; i+=1){
		markers.push(<ProgressMarker key={i} enabled={props.currentStep >= i}/>)
	}
	return(
		<ProgressBarContainer>
			<ProgressBarIndicator {...props}/>
			<ProgressMarkerContainer>
			{ markers }
			</ProgressMarkerContainer>
		</ProgressBarContainer>
	)
}

function InstructionPanel(props){
	let stepView
	switch(props.step){
		case TutorialStep.dragToAdd:
			stepView = (
				<React.Fragment>
					<StepBody
					key={props.step+"body"}>
					<Emoji>🤏</Emoji>
					To tag products, drag them onto your video from the product library.
					</StepBody>
				</React.Fragment>
			)
			break;
		case TutorialStep.scrubAndAdjust:
			stepView = (
				<React.Fragment>
					<StepBody
					key={props.step+"body"}>
					<Emoji>⬅️&nbsp;➡️</Emoji>
					Press the left and right arrow keys to move through the video.
					<br/><br/>
					<Emoji>🤏</Emoji>
					Drag the product tag to adjust its position as the product moves.
					</StepBody>
				</React.Fragment>
			)
			break;
		case TutorialStep.closePath:
			stepView = (
				<React.Fragment>
					<StepBody
					key={props.step+"body"}>
					<Emoji>🛑</Emoji>
					To mark the end position of the product tag, click the "close path" button.
					<br/><br/>
					<ClosePathButton disabled={false}/>
					<br/>
					The product tag will disappear after this point.
					</StepBody>
				</React.Fragment>
			)
			break;
	}

	return(
		<InstructionPanelContainer>
		{ stepView }
		</InstructionPanelContainer>
	)
}

function ControlHeader(props){
	const history = useHistory();
	switch(props.currentStep) {
		case TutorialStep.dragToAdd:
			return(
				<StepFooter>
					<Button invert leftArrow onClick={()=>history.push("/videos")}>Back</Button>
					{ props.children }
					<Button rightArrow onClick={()=>props.setCurrentStep(TutorialStep.scrubAndAdjust)}>NEXT</Button>
				</StepFooter>
			)
		case TutorialStep.scrubAndAdjust:
			return(
				<StepFooter>
					<Button leftArrow invert onClick={()=>props.setCurrentStep(TutorialStep.dragToAdd)}>Back</Button>
					{ props.children }
					<Button rightArrow onClick={()=>props.setCurrentStep(TutorialStep.closePath)}>NEXT</Button>
				</StepFooter>
			)
		case TutorialStep.closePath:
			return(
				<StepFooter>
					<Button invert leftArrow onClick={()=>props.setCurrentStep(TutorialStep.scrubAndAdjust)}>Back</Button>
					{ props.children }
					<Button rightCheck onClick={async ()=>{
						localforage.setItem("hasShownEditorTutorial", new Date())
						const videoId = props.match.params.videoId;
						history.push(`/video/${videoId}`)
					}}>DONE</Button>
				</StepFooter>
			)
	}
}

export default function InteractiveEditorTutorial(props) {
	const history = useHistory();
	const [currentStep, setCurrentStep] = useState(TutorialStep.dragToAdd)
	const videoEl = useRef(null);
	const videoContainerEl = useRef(null)
	let animationFrame = null

	const updateVideoSize = () => {
		const videoRect =  videoEl.current.getBoundingClientRect()
		const containerRect = videoContainerEl.current.getBoundingClientRect()
		const containerRatio = containerRect.width /  containerRect.height

		if(containerRatio <= 1.46389){
			const height = containerRect.width / 1.46389
			videoEl.current.style.height = `${height}px`
			videoEl.current.style.width = '100%'
		} else {
			const width = containerRect.height * 1.46389
			videoEl.current.style.width = `${width}px`
			videoEl.current.style.height = '100%'
		}
	}

	useEffect(()=>{
		updateVideoSize()
		window.addEventListener('resize', updateVideoSize);

		return () => {
			window.removeEventListener('resize', updateVideoSize);
		}
	}, [])

	const timeUpdated = () => {
		if(!!videoEl.current) {
			const video = videoEl.current
			switch(currentStep){
				case TutorialStep.dragToAdd:
					if(video.currentTime >= 6){
						video.currentTime = 0
					}
					break;
				case TutorialStep.scrubAndAdjust:
					if(video.currentTime >= 17 || video.currentTime < 6){
						video.currentTime = 6
					}
					break;
				case TutorialStep.closePath:
					if(video.currentTime >= 20 || video.currentTime < 17){
						video.currentTime = 17
					}
					break;
				default:
					break;
			}
		}
	}

	/*
	<SkipButton
				onClick={()=>{
					const videoId = props.match.params.videoId;
					history.push(`/video/${videoId}`)
				}}>
				SKIP TUTORIAL
				</SkipButton>
				*/

	return (
		<Container>
				<ControlHeader {...props} currentStep={currentStep} setCurrentStep={setCurrentStep}>
				<Header>
					<H3><img style={{height: '13px', marginRight: '7px', opacity: '0.2'}} src="/flash.svg"/> QUICK TUTORIAL</H3>
					<ProgressBar currentStep={currentStep} totalSteps={Object.keys(TutorialStep).length}/>
				</Header>
				</ControlHeader>
			<TutorialContainer>
				<VideoContainer ref={videoContainerEl}>
					<Video 
					ref={videoEl}
					preload="meta" 
					controls={false} 
					autoPlay 
					onTimeUpdate={timeUpdated}
					muted={true}>
						<source src="/freckle_tutorial_3.mp4"/>
					</Video>
				</VideoContainer>
				<InstructionPanel 
				step={currentStep}/>
			</TutorialContainer>
		</Container>
	)
}

