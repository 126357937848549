import React, { Component } from 'react';
import TextInput from './TextInput'
import { BarLoader } from "react-spinners";
import { createVideo } from '../modules/freckle-api.js'

import { logEvent } from '../helpers/analytics.js'

import '../../styles/EmbedVideo.scss';

var fs = require('fs')

const FermData = require('form-data')
const axios = require('axios')

export default class EmbedVideo extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      hasCopiedCode: false,
      loopVideo: true,
      autoPlay: true,
      hidesFreckles: false
    }
  }

  componentDidMount() {
    logEvent("Clicked Embed", {videoId: this.props.video.id, versionId: this.props.version.id})
  }

  embedHTML = () => {
    const embedId = (this.props.version && this.props.video) ? `${this.props.video.id}:${this.props.version.id}` : null
    return `
      <freckle-video videoid="${embedId}" autoplay="${this.state.autoPlay}" loop="${this.state.loopVideo}" hidesfreckles="${this.state.hidesFreckles}" videowidth="${this.props.video.videoXDim}px" videoheight="${this.props.video.videoYDim}px"></freckle-video>
      <script src="https://player.freckle.shop/index.js"></script>
    `
  }

  copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  copyEmbedCode = () => {
    this.copyToClipboard(this.embedHTML())
    this.setState({hasCopiedCode: true})

    logEvent("Copied Embed Code", {videoId: this.props.video.id, versionId: this.props.version.id})
  }

  render(){
    const embedMessage = this.state.hasCopiedCode ? "Copied Embed Code" : "Copy Embed Code"
    return(
      <div className="embed-video-super-container">
        <div className="embed-video-container">
          <div className="editable-content">
            <h2>
              <img class="embed-icon" src="/embed-icon.svg"/>
              Embed your video
            </h2>

            <div class="notice-container">
              <p>To embed your video, simply paste this code into the body of your website.  Remember to publish your project before embedding.  <u>Only published changes will be visible.</u></p>
            </div>

            <div className="code-area">
              {this.embedHTML()}

              <button onClick={this.copyEmbedCode} className="copy-code-button">
                <img src="/copy-code-icon.svg"/>
                <span>{embedMessage}</span>
              </button>
            </div>
            
            <div class="options-container">
              <h4>Embed Options</h4>
              <div><input checked={this.state.loopVideo} type="checkbox" onChange={(e)=>{this.setState({loopVideo: e.target.checked}) }}/><label>Loop Video</label></div>
              <div><input checked={this.state.autoPlay} type="checkbox" onChange={(e)=>{this.setState({autoPlay: e.target.checked}) }}/><label>Autoplay</label></div>
              <div><input checked={this.state.hidesFreckles} type="checkbox" onChange={(e)=>{this.setState({hidesFreckles: e.target.checked}) }}/><label>Hide Freckles While Playing</label></div>
            </div>

          </div>
          <div className="footer-container">
            <span/>
            <button onClick={this.props.onDone} className="done-button">DONE</button>
          </div>
          <div className={this.state.saving ? "saving-overlay" : "saving-overlay hidden"}>
            <BarLoader color="#4A90E2"/>
          </div>
        </div>
      </div>      
    )
  }

}
