import React from 'react'
import styled, { keyframes } from 'styled-components'
import { HotspotType, coloredHotspotIcon } from './hotspot-types'

// TODO: values should be SVG markdown
// TODO: also include animations here?  Do we really need animations?
// TODO: add pulsing halo option
// TODO: add a color option?

const PulseAnimation = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0px rgba(255, 255, 255, 0);
	}
	60% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	}

	80% {
		transform: scale(1);
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
`

const HotspotContainer = styled.div`
	-webkit-animation-timing-function: linear;
	animation-play-state: paused;
	
	display: flex;
	justify-content: center;
	align-items: center;

	position: ${props=> !!props.uncontrolledPosition ? 'relative':'absolute'};
	image-rendering: -webkit-optimize-contrast;

	visibility: ${props=> (!!!props.dragHotspot || (!!props.dragHotspot && !!props.dragging)) ? 'visible' : 'hidden'};
	pointer-events: ${props=> !!props.dragHotspot ? 'none' : 'auto'};

	cursor: grab;
`

// 	animation-name: ${props=> props.dragHotspot? '' : ("anim-" + props.group.id + "-freckle")};
// animation-duration: ${props=> props.dragHotspot? 0 : ("anim-" + props.group.id + "-freckle")};

const Icon = styled.img`
	image-rendering: -webkit-optimize-contrast;
	height: 100%;
	width: 100%;
	object-fit: contain;
	user-select: none;
	pointer-events: none;
	border-radius: 10000px;
	backface-visibility: hidden;
	transform: ${props=> `translateZ(0) scale(${props.scale || 1.0})`};
`

const ProductImage = styled.img`
height: calc(100% - 15%);
width: calc(100% - 15%);
object-fit: cover;
image-rendering: -webkit-optimize-contrast;
border-radius: ${props=>{
	switch(props.type) {
		case HotspotType.circleProductImage:
			return '10000px'
		case HotspotType.roundedProductImage: 
			return '10px'
		default:
			return '10px'
	}
}};
`

const ProductImageIconContainer = styled.div`
background-color: ${props=> props.color};
height: 100%;
width: 100%;

display: flex;
justify-content: center;
align-items: center;

transform: ${props=> `translateZ(0) scale(${props.scale || 1.0})`};

border-radius: ${props=>{
	switch(props.type) {
		case HotspotType.circleProductImage:
			return '10000px'
		case HotspotType.roundedProductImage: 
			return '12px'
		default:
			return '12px'
	}
}};
`

function ProductImageIcon(props){
	return(
		<ProductImageIconContainer {...props}>
			<ProductImage {...props} src={props.src}/>
		</ProductImageIconContainer>
	)
}

export default function Hotspot(props){
	let style = {...props.addedStyle}
	if(!!!props.dragHotspot){
		const freckleName = "anim-" + props.group.id + "-freckle"
		const freckleClass = props.selected ? "freckle selected" : "freckle"
		style = {
			...style, 
			"animationName": freckleName, 
			"animationDuration": props.duration + "s", 
			height: `${props.size}px`, 
			width: `${props.size}px`,
			marginLeft: `${-0.5*props.size}px`,
			marginTop: `${-0.5*props.size}px`
		}
	} else if(!props.uncontrolledPosition) {
		style = { 
			...style,
			height: `${props.size}px`, 
			width: `${props.size}px`,
			marginLeft: `${-0.5*props.size}px`,
			marginTop: `${-0.5*props.size}px`
		}
	} else {
		style = { 
			...style,
			height: `${props.size}px`, 
			width: `${props.size}px`,
		}
	}

	const showsProductImage = [HotspotType.roundedProductImage, HotspotType.circleProductImage].includes(props.type)

	let icon
	if (!showsProductImage) {
		icon = coloredHotspotIcon(props.type || HotspotType.standard, props.color, props.accentColor)
	}

	let scale = 1.0;
	if (props.group.properties.size && !props.uncontrolledPosition) {
		switch(props.group.properties.size) {
			case 'small':
				scale = 0.75
				break
			case 'medium':
				scale = 1.0
				break
			case 'large': 
				scale = 2.0
				break
			default: 
				scale = 1.0
				break
		}
	}

	return(
		<HotspotContainer
		key={props.dragHotspot ? 'drag-hotspot' : ("anim-" + props.group.id + "-freckle:freckle:"+Math.random())}
		ref={props.freckleRef}
		style={style}
		onMouseDown={(e)=>{
			if(e.button === 0){
				props.onDragStart(e, props.group.id)
			}
		}}
		{...props}>
		{ !showsProductImage && <Icon scale={scale} {...props} src={`data:image/svg+xml;base64,${btoa(icon)}`}/> }
		{ showsProductImage && <ProductImageIcon scale={scale} {...props} src={props.group.imageUrl}/> }
		</HotspotContainer>
	)
}

export function DragHotspot(props){
	return <Hotspot dragHotspot={true} {...props}/>
}
