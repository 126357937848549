import localforage from "localforage"
import moment from 'moment'
import axios from 'axios'
import { print } from 'graphql';
import gql from 'graphql-tag';

const isSyncingStores = new Set()

const FETCH_PRODUCTS = gql`
query GetProductsprodu($first: Int, $last: Int, $after: String, $before: String) {
  products(first: $first, last: $last, after: $after, before: $before) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      cursor
      node {
        id
        slug
        sku
        name
        type
        databaseId
        shortDescription
        image {
          id
          sourceUrl
          altText
        }
        galleryImages {
          nodes {
            id
            sourceUrl
            altText
          }
        }
        ... on SimpleProduct {
          onSale
          price
          content
          regularPrice
        }
        ... on VariableProduct {
          onSale
          price
          content
          regularPrice
          variations {
            nodes {
              id
              name
              price
              sku
            }
          }
        }
      }
    }
  }
}
`

export async function syncProducts(store, force=false, callback) {
  if(isSyncingStores.has(store.domain)) return
  isSyncingStores.add(store.domain)

  let value = null
  try {
    value = await localforage.getItem(`synced-products-${store.domain}`)
    const localData = JSON.parse(value)
    callback({cached: true, data: localData}, null)
  } catch(e) {}

  const threshDate = moment().subtract(1, 'days')
  const lastSyncDate = await localforage.getItem(`${store.domain}-sync-date`)
  const lastSyncDateWrap = moment(lastSyncDate)
  const cacheBusted = lastSyncDateWrap.valueOf() < threshDate.valueOf()

  if(force || cacheBusted || !!!value){
    console.log("fetching from remote")
    const url = `https://${store.domain}/graphql`
    const batchSize = 100
    let batchIndex = 0

    const payload = {
      query: print(FETCH_PRODUCTS),
      variables: {
        first: batchSize,
        offset: batchIndex * batchSize,
        last: null,
        after: null,
        before: null
      }
    }

    let products = []

    while(true){
      const result = await axios.post(url, payload)

      products = products.concat(result.data.data.products.edges.map(edge=>{
        const node = edge.node
        let skus = [node.sku]
        if(!!node.variations && !!node.variations.nodes.length) {
          skus = Array.from(new Set(
            node.variations.nodes.map(v=>v.sku)
          ))
        }
        return {
          id: node.databaseId,
          gqlid: node.id,
          title: node.name,
          price: node.price.replace(new RegExp('\\$', 'g'), ''),
          currency: 'USD',
          linkUrl: `https://` + store.domain + `/?products=${node.databaseId}`,
          imageUrl: node.image.sourceUrl,
          skus: skus
        }
      }))

      if(!result.data.data.products.pageInfo.hasNextPage) {
        localforage.setItem(`synced-products-${store.domain}`, JSON.stringify(products), function (err) {})
        localforage.setItem(`${store.domain}-sync-date`, new Date(), function (err) {})
        console.log("done syncing products")
        callback({cached: false, data: products}, null)
        break
      }
      batchIndex+=1
    }
  }

  isSyncingStores.delete(store.domain)
  
}