import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import DashboardItem from './DashboardItem'
import { ResponsiveLine } from '@nivo/line'

const moment = require("moment")


const LineChartContainer = styled.div`
height: 400px;
flex-grow: 1;
color: #999;
`

const ChartTooltip = styled.div`
background-color: #3f3f3f;
color: white;
padding: 10px;
border-radius: 6px;
display: flex;
justify-content: center;
align-items: center;
font-weight: 700;
&:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: solid 8px #3f3f3f;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
}
`

export default function LineChartItem(props){
	const option = props.options.find(opt=> opt.id === props.selectedOptionId)

	const data = [
  {
    "id": "series",
    "color": "hsl(17, 70%, 50%)",
    "data": props.data.map(item=> {
    	const d = moment(item.day)
    	return {
    		x: d.format("M/D"),
    		y: item.count
    	}
    })
  }
]

	return(
		<DashboardItem
		options={props.options}
		selectedOptionId={props.selectedOptionId}
		title={props.title}
		onChange={props.onChange}>

		<LineChartContainer>
		<ResponsiveLine
		tooltip={(input)=>{
			return <ChartTooltip>{input.point.data.y}</ChartTooltip>
		}}
        data={data}
        margin={{ top: 30, right: 30, bottom: 60, left: 70 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
		curve="catmullRom"
		enableArea={true}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 15,
            tickRotation: 45
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 15,
            tickRotation: 0
        }}
        enableGridX={false}
        enableGridY={false}
        colors={[option.color]}
        pointSize={10}
        pointColor={"#1f1f1f"}
        pointBorderWidth={2}
        pointBorderColor={option.color}
        pointLabelYOffset={-12}
        enableCrosshair={false}
        useMesh={true}
        theme={{
    fontSize: 13,
    textColor: "#aaa",
    fontFamily: "Signika"
   }}
    />
    </LineChartContainer>

		</DashboardItem>
	)
}