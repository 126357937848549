import React, { Component, useState, useRef, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import TextInput from './TextInput'
import Button from './Button'
import * as API from '../modules/freckle-api'
import { BeatLoader } from "react-spinners";
import { FreckleTooltip } from './FreckleTooltip'
import { AppContext, AppAction, useAppContext } from './Context/AppContext'
import * as Auth from '../modules/auth'

const Enter = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

const ErrorContainer = styled.div`
height: 30px;
width: 100%;
position: absolute;
top: 10px;
left: 0;
display: flex;
align-items: center;
justify-content: center;
transition: 0.25s;

.error-message {
  border-radius: 4px;
  padding: 5px 20px;
  background-color: #E73C7E;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 15px;
    width: 15px;
    padding-right: 10px;
  }
  animation: enterErrorAnim 500ms normal forwards;

  @keyframes enterErrorAnim {
    0% {
      opacity: 0;
      margin-top: -100px;
    }
    100% {
      opacity: 1.0;
      margin-top: 0;
    }
  }
}
`

const Container = styled.div`
animation: ${Enter} 1.0s normal forwards;
font-family: Signika;
padding: 20px;
max-width: calc(100% - 40px);
width: 450px;
display: flex;
gap: 20px;
justify-content: center;
flex-direction: column;
height: ${props=> props.fromSettings ? 'calc(100% - 80px)' : 'calc(100vh - 40px)'};
margin: 0 auto;

@media (max-aspect-ratio: 1/1) {
  justify-content: flex-start;
}

h3 {
	margin: 0;

  font-size: 35px;
  color: white;
  text-align: center;
}

h5 {
	color: white;
	padding: 0;
	margin: 0;
	font-size: 20px;
	font-weight: 300;
}

p {
	margin: 0;
	padding: 0;
	color: #9f9f9f;
	text-align: center;
	margin-bottom: 15px;
}
`

const StoreTypeSelectorContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: space-between;
border-radius: 8px;
// box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
gap: 20px;

p {
	margin: 0;
	padding: 0;
	color: white;
	font-size: 14px;
	line-height: 20px;
	// border-top: 2px solid #222;
	padding: 20px;

	a { 
		color: white;
	}
}
`
const StoreTypeButtonsContainer = styled.div`
display: flex;
gap: 15px;
flex-wrap: wrap;
align-items: center;
justify-content: center;

@media (max-aspect-ratio: 1/1) {
  justify-content: center;
}
`

const StoreTypeButton = styled.button`
border: none;
outline: none;
height: 100px;
width: 180px;
background-color: white;
padding: 10px;
background-image: url('${props=> props.imageURL}');
background-position: center;
background-size: 80%;
background-repeat: no-repeat;
border-radius: 8px;
cursor: pointer;
position: relative;
opacity: ${props=> props.storeType === props.selectedStoreType ? 1:0.4};
transition: 0.15s;

@media (max-aspect-ratio: 1/1) {
  width: calc(50% - 10px);
}

${props=>{
	if (props.disabled) return `pointer-events: none;`
}}

&:hover {
${props=>{
	if(!props.beta) {
		const val = (props.storeType === props.selectedStoreType ? 1:0.7);
		return `opacity: ${val};`
	}
}}
}

&::after {
	visibility: ${props=>!!props.beta?'visible':'hidden'};
  content: "Private Beta";
  background-color: #7F54B3;
  color: white;
  font-weight: bold;
  border-radius: 1000px;
  padding: 5px 10px;
  position: absolute;
  bottom: -10px;
  right: 0px;
}
`

const Welcome = styled.h4`
color: white;
text-align: center;
margin: 0;
padding: 0;
`

const OnboardingHeader = styled.div`
position: absolute;
top: 0;
left: 0;
width: calc(100% - 40px);
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
gap: 15px;
padding: 20px 20px;
background-color: #121212;
border-bottom: 1px solid rgba(255, 255, 255, 0.025);
`

function StoreTypeSelector(props){

	let instructions
	switch(props.selectedStoreType){
		case "shopify":
			instructions=<p>Shopify stores don't require any setup.  Just enter your store's URL!</p>
			break
		case "woo":
			instructions=<p>WooCommerce stores must have WPGraphQL, WooGraphQL, and Freckle Cart Handler plugins installed.  <br/><br/><a target="_blank" href="https://freckle.shop/support/configure-a-woo-store">Click here for instructions.</a></p>
			break
	}

	return(
		<StoreTypeSelectorContainer>
			<StoreTypeButtonsContainer>
			<StoreTypeButton
			{...props}
			imageURL="/shopify_logo_whitebg.svg"
			storeType="shopify"
			onClick={()=> props.didSelectStoreType("shopify")}/>

			<FreckleTooltip arrow title="Contact support to request access to the WooCommerce private beta.">
			<StoreTypeButton
			{...props}
			imageURL="/woocommerce-logo-color-black.svg"
			storeType="woo"
			beta/>
			</FreckleTooltip>

			</StoreTypeButtonsContainer>
			{ instructions }
		</StoreTypeSelectorContainer>
	)
}

export default function AddStoreView(props) {
	const [addingStore, setAddingStore] = useState(false)
	const [storeType, setStoreType] = useState("shopify")
	const [storeUrl, setStoreUrl] = useState("")
	const [errorMessage, setErrorMessage] = useState(null)
	const textRef = useRef(null)

	const context = useAppContext()

	useEffect(()=>{
		if (!props.fromSettings && !!context.allStores.length && context.state.user.plan != 'Agency') {
			// props.history.push("/videos")
		}
	}, [context.allStores])

	const submit = (e) => {
		const storeString = textRef.current.inputRef.current.value
		if(!!!storeString || !!!storeString.length) {
			setErrorMessage("Store URL must not be empty.")
		} else {
			setAddingStore(true)
			API.addStore(storeString, storeType, true).then(()=>{
				textRef.current.inputRef.current.value = ""
				context.fetchUser({bypassCache: true}).then(()=>{
					if (props.onAdded) {
						props.onAdded()
					} else {
						props.history.push("/videos")
					}
				})
			}).catch((e)=>{
				setAddingStore(false)
				setErrorMessage(e.response.data.message)
			})
		}
	}

	const getErrorMessage = () => {
	 if(errorMessage){
		  return (
		   <ErrorContainer>
		   <div className="error-message">
		   <img src="/error.svg"/>
		   {errorMessage}
		   </div>
		   </ErrorContainer>
		   )
		}
	}

	const { user } = context.state

	const logoutButton = (
		<OnboardingHeader>
		<Welcome>Welcome, { user && user.firstName }!</Welcome>
		<Button
		backgroundColor="#1f1f1f"
		color="#6f6f6f"
		onClick={()=>{
		  Auth.signOut()
		}}>
		Logout
		</Button>
		</OnboardingHeader>
	)

	const numberOfStores = context.allStores.length

	return (
		<Container {...props}>
		{ getErrorMessage() }


		{(numberOfStores==0) && logoutButton}

		<h3>Add a store</h3>
		<p>🔥 You've got the goods, show them off!</p>
		<StoreTypeSelector 
		selectedStoreType={storeType}
		didSelectStoreType={newStoreType=> setStoreType(newStoreType)}/>

		<TextInput 
		ref={textRef} 
		name="store-domain" 
		placeholder="" 
		label="Store URL"
		onChange={(e)=>{
			setStoreUrl(e.target.value)
		}}/>

		<Button onClick={submit} disabled={!storeUrl.length} large fullWidth>
		{addingStore ? <BeatLoader color="#ffffff" size="5px"/> : <span>Add Store</span>}
		</Button>
		</Container>
	)
}





