import React from 'react'
import styled from 'styled-components'
import Button from '../Button'

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

const Left = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

const ProductIcon = styled.img`
	height: 25px;
	width: 25px;
	object-fit: aspect-fill;
	border-radius: 1000px;
	background-color: #efefef;
	margin-right: 15px;
	border: 2px solid #1f1f1f;

	box-shadow: ${props=> props.count > 1 ? '-3px 0px 0px 0px #6f6f6f' : ''};
`

const Label = styled.label`
	color: white;
	font-family: Signika;
	font-size: 14px;
	font-weight: 700;
`

const ClearButton = styled.button`
	font-family: "Signika";
	border: none;
	outline: none;
	background-color: transparent;
	color: #8f8f8f;
	font-size: 12px;
	font-weight: 700;
	transition: 0.2s;
	cursor: pointer;
	float: right;
	margin-right: 15px;
	height: 30px;
	padding: 0 15px;

	&:hover {
		background-color: rgba(255,255,255,0.2);
		color: white;
	}
`

export default function ProductsList(props){
	return(
		<Container>
			<Left>
				{!!props.products.length && <ProductIcon count={props.products.length} src={props.products[0].imageUrl}/>}
				<Label>{ props.products.length } product{ props.products.length === 1 ? '':'s' } selected</Label>
			</Left>
			<ClearButton onClick={props.onEditList}>Edit List</ClearButton>
		</Container>
	)
}