import jsfeat from "jsfeat"
import { standardDeviation, average, sum, ema } from './math'

import * as simplify from 'simplify-js'

import * as ss from 'simple-statistics'

const SEEK_INCREMENT = 0.1
const MAX_TRACKING_POINTS = 1000

const cov = require( 'compute-covariance' );
var Gaussian = require('multivariate-gaussian');

const IOUBufferMax = 10

// BoundingBox: all values are percents, range 0:1
// {x, y, width, height}

class FifoDispersionSet {
	constructor(maxSize){
		this.maxSize = maxSize
		this.data = []
	}

	add = (slice) => {
		const allXs = []
		const allYs = []

		for(let k=0; k<slice.length; k+=2){
			allXs.push(slice[k])
			allYs.push(slice[k+1])
		}

		const avgX = average(allXs)
		const avgY = average(allYs)

		const distances = []
		for(let i=0; i<slice.length; i+=2){
			const x = slice[i]
			const y = slice[i+1]
			distances.push(Math.pow(Math.pow((x-avgX), 2) + Math.pow((y-avgY), 2), 0.5))
		}

		const averageDistance = sum(distances)/distances.length

		if(this.data.length === this.maxSize){
			this.data.shift()
		}

		this.data.push(averageDistance)
	}

	isAnomalous = () => {
		return false
		const lastValue = this.data[this.data.length-1]
		const ma = average(this.data)
		const anomalous = Math.abs(lastValue-ma) > ma*0.5
		return anomalous
	}
}

export default class Tracker {
	constructor(opts, callback) {
		const {boundingBox, height, width, timestamp} = opts
		this.boundingBox = boundingBox
		this.height = height
		this.width = width

		this.timestamp = timestamp		

		this.scaledBoundingBox = {
			x: this.boundingBox.x * this.width,
			y: this.boundingBox.y * this.height,
			width: this.boundingBox.width * this.width,
			height: this.boundingBox.height * this.height
		}

		this.callback = callback

		this.curpyr = new jsfeat.pyramid_t(3)
	    this.prevpyr = new jsfeat.pyramid_t(3)

		this.curpyr.allocate(this.width, this.height, jsfeat.U8C1_t)
	    this.prevpyr.allocate(this.width, this.height, jsfeat.U8C1_t)

		// this.fifoSet = new FifoDispersionSet(10)

	    this.pointCount = 0
	    this.initialPointCount = 0
	    this.pointStatus = new Uint8Array(MAX_TRACKING_POINTS)
	    this.prevxy = new Float32Array(MAX_TRACKING_POINTS * 2)
	    this.curxy = new Float32Array(MAX_TRACKING_POINTS * 2)
	    this.startxy = new Float32Array(MAX_TRACKING_POINTS * 2)

	    this.bootstrapped = false

	    this.last_edges = new jsfeat.matrix_t(this.width, this.height, jsfeat.S32C2_t);
	    this.current_edges = new jsfeat.matrix_t(this.width, this.height, jsfeat.S32C2_t);

	    this.iou_buffer = new Array()

	    // contains an array of arrays.  Each entry contains [x,y] for time index timestamp + i * SEEK_INCREMENT.
	    this.positions = [{
			x: boundingBox.x + boundingBox.width/2, 
	    	y: boundingBox.y + boundingBox.height/2,
	    	ts: this.timestamp
		}]
	}

	forceComplete = () => {
		const freckles = this.generateFreckles(this.positions)
		return {
			done: true,
			freckles: freckles
		}
	}

	inspectFrame = (pixels, timestamp) => {
		if(this.bootstrapped) {

			const {dX, dY} =  this._calculateMovement(pixels, timestamp)
			const lastLocation = this.positions[this.positions.length-1]
			const x=lastLocation.x+dX, y=lastLocation.y+dY
			const newPosition = {
				x: x, 
				y: y,
				ts: timestamp
			}

	    	if(this.iou_buffer.length == IOUBufferMax) {
		    	const iou_mean = average(this.iou_buffer)
		    	// const iou_sigma = standardDeviation(this.iou_buffer)
		    	// const thresh = iou_mean-2*iou_sigma
		    	const thresh = iou_mean - 0.2
		    	if(this.iou_buffer[this.iou_buffer.length-1] < thresh) {
		    		console.log("FRAME CHANGE")
		    		// console.log(`${timestamp} ==> iou: ${this.iou_buffer[this.iou_buffer.length-1]} | thresh: ${thresh} | mean: ${iou_mean} | sigma ${iou_sigma}`)
		    		const freckles = this.generateFreckles(this.positions)
					return {
						done: true,
						freckles: freckles
					}
		    	}
	    	}

			if(this.pointCount == 0) {
				const freckles = this.generateFreckles(this.positions)
				return {
					done: true,
					freckles: freckles
				}
			}

			this.positions.push(newPosition)

			// TODO: remove this
			let displacements=[]
		    for(let i=0; i<this.pointCount; i++) {
		    	const startx=this.startxy[2*i], starty=this.startxy[2*i+1]
		    	const x=this.curxy[2*i], y=this.curxy[2*i+1]
		    	const dispX=x-startx, dispY=y-starty
		    	displacements.push({dx: dispX/this.width, dy: dispY/this.height})
		    }

			return {
				done: false,
				position: newPosition,
				displacements: displacements,
				features: this.curxy.slice(0, this.pointCount*2).map((item, idx)=>{
					if(idx%2 == 0) {
						return item / this.width
					} else {
						return item / this.height
					}
				})
			}

		} else {
			// find trackable features in the frame
			// const allFeaturePoints = this._detectFeatures(pixels)
			const startPoints = this.fastCorners(pixels, this.scaledBoundingBox)
			if(startPoints.length == 0) {
				return {
					done: true,
					freckles: []
				}
			}

			// populate this.curxy with those points
			startPoints.forEach((point, i)=>{
				this.curxy[i*2] = point.x
				this.curxy[i*2+1] = point.y

				this.startxy[i*2] = point.x
				this.startxy[i*2+1] = point.y
			})
			this.pointCount = startPoints.length
			this.initialPointCount = this.pointCount

			this.bootstrapped = true

			return {
				done: false,
				position: [this.positions[0].x, this.positions[0].y],
				features: this.curxy.slice(0, this.pointCount*2).map((item, idx)=>{
					if(idx%2 == 0) {
						return item / this.width
					} else {
						return item / this.height
					}
				})
			}
		}

	}

	fastCorners = (pixels, region=null) => {
		var threshold = 20;
        jsfeat.fast_corners.set_threshold(threshold);
         
        var corners = [], border = 3, image_width = pixels.width, image_height = pixels.height;
         
        // you should use preallocated keypoint_t array
        for(var i = 0; i < pixels.width * pixels.height; i++) {
            corners[i] = new jsfeat.keypoint_t(0,0,0,0);
        }

        let img_u8 = new jsfeat.matrix_t(image_width, image_height, jsfeat.U8_t | jsfeat.C1_t);
        jsfeat.imgproc.grayscale(pixels.data, image_width, image_height, img_u8);
        
        var count = jsfeat.fast_corners.detect(img_u8, corners, 5);

        const minX = region.x
		const minY = region.y
		const maxX = region.x + region.width
		const maxY = region.y + region.height
		const filteredRegionCorners = corners.filter(point => point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY)

        const scores = filteredRegionCorners.filter(corner=> corner.score > 0).map(corner=> corner.score)
        if(!!!scores.length) {
        	return []
        }
        const perc = ss.quantile(scores, 0.7)

        const filteredCorners = filteredRegionCorners.filter(corner=> corner.score > perc)

        return filteredCorners
	}

	yapeCorners = (pixels) => {
		var corners = [],
	    image_width = pixels.width, image_height = pixels.height,
	    radius = 5, pyramid_levels = 1; // for now only single level support

	    let img_u8 = new jsfeat.matrix_t(image_width, image_height, jsfeat.U8_t | jsfeat.C1_t);
		 
		// YAPE needs init before running detection
		jsfeat.yape.init(image_width, image_height, radius, pyramid_levels);

		jsfeat.imgproc.grayscale(pixels.data, image_width, image_height, img_u8);

        jsfeat.imgproc.box_blur_gray(img_u8, img_u8, 2, 0);
		 
		// you should use preallocated keypoint_t array
		for(var i = 0; i < image_width * image_height; i++) {
		    corners[i] = new jsfeat.keypoint_t(0,0,0,0);
		}
		 
		// perform detection
		// returns the amount of detected corners
		var count = jsfeat.yape.detect(img_u8, corners, 4);

		return corners
	}

	generateFreckles = (positions) => {
		const points = positions.map((pos, i)=>{
			return {x: pos.x*this.width, y: pos.y*this.height, z: pos.ts}
		})
		const tolerance = 0.01 * Math.hypot(this.height, this.width)
		const freckles = simplify(points, tolerance, true)
		// const freckles = points
		return freckles.map((freckle, idx)=>{
			let type = 1
			if(idx === 0) type = 0
			if(idx === freckles.length-1) type = 2

			const ts = this.timestamp + freckle.z * SEEK_INCREMENT
			return {
				x: freckle.x / this.width,
				y: freckle.y / this.height,
				ts: freckle.z,
				t: type
			}
		})
	}

	// returns percent movement of tracked object since last frame
	_calculateMovement = (pixels, timestamp) => {
		return this._trackFrame(pixels, timestamp)
	}

	_findAnomalyIndices = (x, mult, minthreshold) => {
		const sigma = standardDeviation(x)
		return x.map((xVal, idx)=>{
			const subx = x.filter((_,i)=>idx!==i)
			const avg = average(subx)
			// const sigma = standardDeviation(subx)
			const isAnom = Math.abs(xVal-avg) > Math.max(mult * sigma, minthreshold)
			// if(isAnom) console.log(`dist ${Math.abs(xVal-avg)} | thresh ${mult * sigma}`)
			return isAnom
		})
	}

	_trackFrame = (pixels, timestamp) => {
		let xyswap = this.prevxy;
        this.prevxy = this.curxy;
        this.curxy = xyswap;
        let pyrswap = this.prevpyr;
        this.prevpyr = this.curpyr;
        this.curpyr = pyrswap;

        // these are options worth breaking out and exploring
        const winSize = 20;
        const maxIterations = 30;
        const epsilon = 0.01;
        const minEigen = 0.001;

        jsfeat.imgproc.grayscale(pixels.data, this.width, this.height, this.curpyr.data[0]);
        this.curpyr.build(this.curpyr.data[0], true);

        const edge_swap = this.last_edges
        this.last_edges = this.current_edges
        this.current_edges = edge_swap
        // jsfeat.imgproc.scharr_derivatives(this.curpyr.data[0], this.current_edges);

		var r = 4;
		var kernel_size = (r+1) << 1;


		const edge_data = this.curpyr.data[2]
		jsfeat.imgproc.gaussian_blur(edge_data, this.current_edges, kernel_size, 0);
		jsfeat.imgproc.canny(this.current_edges, this.current_edges, 4, 35);


		let intersection=0, union=0
        for(let i=0; i<(edge_data.rows * edge_data.cols); i+=1) {
        	const last_val = this.last_edges.buffer.f64[i]
        	const cur_val = this.current_edges.buffer.f64[i]
        	if(last_val > 0 && cur_val > 0) {
        		intersection += 1
        		union += 1
        	} else if(last_val > 0 || cur_val > 0) {
        		union += 1
        	}
        }
        const iou = intersection/union
        if(iou < 1) {
        	this.iou_buffer.push(iou)
        	if(this.iou_buffer.length > IOUBufferMax) this.iou_buffer.shift()
        }

        // console.log(`${timestamp} ==> iou: ${iou} | interection: ${intersection} | union ${union}`)


        this.pointStatus.fill(0, 0, this.pointStatus.length);

        jsfeat.optical_flow_lk.track(
            this.prevpyr, this.curpyr,
            this.prevxy, this.curxy,
            this.pointCount,
            winSize, maxIterations,
            this.pointStatus,
            epsilon, minEigen);

        // APPROACH A
        // get last centroid (last recorded position)
        // let rejectedPoints
        // if(!!this.positions.length){
        // 	// const centroid = this.positions[this.positions.length-1]
        // 	const sublist = this.curxy.slice(0, 2*this.pointCount)

        // 	const xs = sublist.map((item, idx)=> sublist[2*idx])
        // 	const ys = sublist.map((item, idx)=> sublist[2*idx+1])
        // 	const centroid = {
        // 		x: average(xs) / this.width,
        // 		y: average(ys) / this.height
        // 	}
        // 	debugger
        // 	// calculate distance of all points from that centroid
        // 	const distances = []
        // 	for(let i=0; i<this.pointCount; i++){
        // 		const x=this.curxy[2*i], y=this.curxy[2*i+1]
        // 		const dist = Math.hypot(x-centroid.x*this.width, y-centroid.y*this.height)
        // 		distances.push(dist)
        // 	}
	       //  // calculate avg and std dev of distances
	       //  const avg = average(distances)
	       //  const sigma = standardDeviation(distances)

	       //  // filter points that are more than 2 sigma out
	       //  rejectedPoints = distances.map((distance, idx)=>{
	       //  	const subdistances = [...distances]
	       //  	subdistances.splice(idx, 1)
	       //  	const subsigma = standardDeviation(subdistances)

	       //  	const minthreshold = Math.hypot(this.boundingBox.width * this.width, this.boundingBox.height * this.width) * 0.1

	       //  	const threshold = avg + Math.max(2*subsigma, minthreshold)
	       //  	const rejected = distance > threshold

	       //  	console.log(`threshold ${threshold} | avg ${avg} | sigma ${sigma} | subsigma ${subsigma}`)
	       //  	if(rejected) {
	       //  		console.log(`rejected ${distance} > ${avg+2*subsigma}`)
	       //  	}
	       //  	// const thresh = Math.hypot(this.boundingBox.width * this.width, this.boundingBox.height * this.width)
	       //  	// const rejected = Math.abs(distance-avg) > thresh * 0.1

	       //  	return rejected
	       //  })

	       //  console.log(distances)
        // }

        // APPROACH 2
	    // let displacementsX=[], displacementsY=[]
	    // const distances = []
     //    for(let i=0; i<this.pointCount; i++) {
     //    	const startx=this.startxy[2*i], starty=this.startxy[2*i+1]
     //    	const x=this.curxy[2*i], y=this.curxy[2*i+1]
     //    	const dispX=x-startx, dispY=y-starty
        	
    	// 	const dist = Math.hypot(dispX, dispY)
    	// 	distances.push(dist)
     //    }

     //    const avg = average(distances)
     //    const sigma = standardDeviation(distances)

     //    const minthreshold = Math.hypot(this.boundingBox.width * this.width, this.boundingBox.height * this.width) * 0.2
     //    // const thresh = Math.max(sigma, minthreshold)
     //    // filter points that are more than 2 sigma out
     //    const rejectedPoints = distances.map((distance, idx)=>{
     //    	const subdistances = distances.filter((d,i)=>idx!==i)
     //    	const subavg = average(subdistances)
     //    	const subsigma = standardDeviation(subdistances)
     //    	const thresh = Math.max(2*subsigma, 0)

     //    	console.log(`subavg ${subavg} | distance ${distance} | thresh ${thresh}`)
     //    	const rejected = Math.abs(subavg-distance) > thresh
     //    	return rejected
     //    })

     // APPROACH 3 --> look for anomalies in x and y dimensions separately
 	    let displacementsX=[], displacementsY=[]
        for(let i=0; i<this.pointCount; i++) {
        	const startx=this.startxy[2*i], starty=this.startxy[2*i+1]
        	const x=this.curxy[2*i], y=this.curxy[2*i+1]
        	const dispX=x-startx, dispY=y-starty
        	
    		displacementsX.push(dispX)
    		displacementsY.push(dispY)
        }

        const minthreshold = Math.hypot(this.boundingBox.width * this.width, this.boundingBox.height * this.width) * 0.2
      	const xAnomalies = this._findAnomalyIndices(displacementsX, 2, minthreshold)
      	const yAnomalies = this._findAnomalyIndices(displacementsY, 2, minthreshold)
      	const rejectedPoints = xAnomalies.map((xanom, idx)=>!!xanom || !!yAnomalies[idx])

	    if(Math.max(...this.pointStatus) === 1) {
	    	var outputPoint = 0;
		    for (var inputPoint = 0; inputPoint < this.pointCount; inputPoint++) {
		        if (this.pointStatus[inputPoint] == 1 && !rejectedPoints[inputPoint]) {
		            if (outputPoint < inputPoint) {
		                var inputIndex = inputPoint * 2;
		                var outputIndex = outputPoint * 2;
		                // shift current points
		                this.curxy[outputIndex] = this.curxy[inputIndex];
		                this.curxy[outputIndex + 1] = this.curxy[inputIndex + 1];
		                // shift previous points to keep index matching consistent
		                this.prevxy[outputIndex] = this.prevxy[inputIndex];
		                this.prevxy[outputIndex + 1] = this.prevxy[inputIndex + 1];
		                // shift start points to allow calc of per-point displacement
		                this.startxy[outputIndex] = this.startxy[inputIndex]
		                this.startxy[outputIndex + 1] = this.startxy[inputIndex + 1]
		            }
		            outputPoint++;
		        }
		    }
		    this.pointCount = outputPoint;
	    }
	    // now remove any remaining stray point records
	    for(let i=outputPoint*2; i<this.curxy.length; i++){
	    	this.curxy[i] = 0
	    	this.prevxy[i] = 0
	    }

	    // calculate the movement of each remaining point from last to current frame
	    const dXs=[], dYs=[]
	    for(let i=0; i<this.pointCount; i++) {
	    	dXs.push(this.curxy[2*i] - this.prevxy[2*i])
	    	dYs.push(this.curxy[2*i+1] - this.prevxy[2*i+1])
	    }

	    const newAvgX = average(dXs)/this.width, newAvgY = average(dYs)/this.height

        return {dX: newAvgX, dY: newAvgY}
	}

}



