import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 25px;
	position: relative;
`

const Img = styled.img`
	height: 120px;
	width: 120px;
	border-radius: 12px;
	background-color: white;
	user-select: none;
	object-fit: cover;
`

const Label = styled.label`
	margin-top: 15px;
	font-family: Signika;
	color: white;
	font-size: 14px;
	font-weight: 700;
	text-align: center;
	max-width: 110px;
	user-select: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const DeleteButton = styled.button`
	height: 25px;
	width: 25px;
	transition: 0.2s;
	border-radius: 1000px;
	border: 2px solid white;
	background-color: black;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	line-height: 14px;
	font-size: 12px;
	position: absolute;
	top: -10px;
	right: -10px;
	cursor: pointer;

	&:hover {
		background-color: white;
		color: black;
		box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
	}
`

export default function ProductItem(props){
	return(
		<Container>
			{!!props.showDelete && <DeleteButton onClick={props.onDelete}>✖</DeleteButton>}
			<Img src={props.product.imageUrl}/>
			<Label>{ props.product.title }</Label>
		</Container>
	)
}