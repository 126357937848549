import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import Base from '../Base/Base'
import LineChartItem from './DashboardItem/LineChartItem'
import AccountMetricsItem from './DashboardItem/AccountMetricsItem'
import MicrositeLeaderboardItem from './DashboardItem/MicrositeLeaderboardItem'
import ProductLeaderboardItem from './DashboardItem/ProductLeaderboardItem'
import MapItem from './DashboardItem/MapItem'
import { getAggregateAnalytics } from '../../modules/freckle-api'
import { AppContext, AppAction, useAppContext } from '../Context/AppContext'
import { BeatLoader } from "react-spinners";

const ItemsGrid = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 15px;
`

const LoadingContainer = styled.div`
height: 60vh;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`

export default function Dashboard(props){
	const [data, setData] = useState(null)
	const [dataUnavailable, setDataUnavailable] = useState(null)
	const [fetchingData, setFetchingData] = useState(false)
	const [loadedStore, setLoadedStore] = useState(null)
	
	const [selectedTimeSeries, setSelectedTimeSeries] = useState("viewsOverTime")
	const [selectedGeoStats, setSelectedGeoStats] = useState("view")

	const appContext = useAppContext()

	useEffect(()=>{
		reloadData()
	})

	const reloadData = () => {
		const store = appContext.state.selectedStore.domain
		if(dataUnavailable!==store && (!data || store!==loadedStore) && !fetchingData && !!store){
			setLoadedStore(store)
			setFetchingData(true)
			getAggregateAnalytics(store)
			.then(_resp=>{
				if(_resp.status === 204) {
					setDataUnavailable(store)
				}
				setFetchingData(false)
				setData(_resp.data)
			})
			.catch(()=> {
				setFetchingData(false)
				setDataUnavailable(store)
			})
		}
	}

	const getRows = () => {
		if(!data) return <div/>

		const lineChartRow = (
		<LineChartItem
		title="last 30 days"
		data={data[selectedTimeSeries]}
		options={[
			{ name: "Views", id: "viewsOverTime", color: "#F5A623" },
			{ name: "Cart Adds", id: "addToCartOverTime", color: "#BD10E0" },
			{ name: "Checkouts", id: "checkoutOverTime", color: "#66B238" }
		]}
		selectedOptionId={selectedTimeSeries}
		onChange={(_selectedTimeSeries)=> setSelectedTimeSeries(_selectedTimeSeries)}
		/>
		)

		const accountMetricsRow = (
		<AccountMetricsItem
		title="Account Metrics"
		conversion={(100*data.statsAllVideos[0].checkout/data.statsAllVideos[0].view).toFixed(1)}
		metrics={[
			{ name: "Views", value: parseInt(data.statsAllVideos[0].view) },
			{ name: "Cart Adds", value: parseInt(data.statsAllVideos[0].add_to_cart) },
			{ name: "Checkouts", value: parseInt(data.statsAllVideos[0].checkout) },
			{ name: "Average Cart Value", value: `$${data.statsAllVideos[0].avg_cart_value ? data.statsAllVideos[0].avg_cart_value.toFixed(2) : 'N/A'}` }
		]}/>
		)

		const geoStatsRow = (
		<MapItem
		title="by location"
		data={data.statsByCity}
		options={[
			{ name: "Views", id: "view" },
			{ name: "Cart Adds", id: "add_to_cart" },
			{ name: "Checkouts", id: "checkout" }
		]}
		selectedOptionId={selectedGeoStats}
		onChange={(_selectedGeoStats)=> setSelectedGeoStats(_selectedGeoStats)}/>
		)

		const msLeaderboardRow = (
		<MicrositeLeaderboardItem
		title="Performance by Microsite"
		microsites={data.micrositeAggregates}/>
		)

		const productLeaderboardRow = (
		<ProductLeaderboardItem
		title="Product Metrics"
		productsStats={data.productAggregates}
		/>
		)

		return(
			<React.Fragment>
			{lineChartRow}
			{accountMetricsRow}
			{geoStatsRow}
			{msLeaderboardRow}
			{productLeaderboardRow}
			</React.Fragment>
		)
	}

	return(
		<Base
		title="Analytics">
		<ItemsGrid>
		{ !fetchingData && getRows() }
		{ fetchingData && <LoadingContainer><BeatLoader color="#ffffff" size="5px"/></LoadingContainer>}
		{ (dataUnavailable===appContext.state.selectedStore.domain) && <LoadingContainer>No Data Available</LoadingContainer> }
		</ItemsGrid>
		</Base>
	)
}