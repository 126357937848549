import React, { Component } from 'react';
import styled from 'styled-components'
import { BeatLoader } from "react-spinners";
import "../../styles/Button.scss"

const ArrowImage = styled.img`
height: 8px;
`

const Icon = styled.img`
height: 20px;
margin-left: -5px;
padding-right: 3px;
margin-top: -1px;
`

const BaseButton = styled.button`
outline: 0;
border: 0;
height: ${props=> {
  if (props.small) {
    return '22px';
  } if (props.large) {
    return '45px';
  } else {
    return '35px';
  }
}};
width: fit-content;
padding: ${props=>{
  if(!!props.link){
    return '0px'
  } else if(!!props.small) {
    return '0 12px'
  } else {
    return '0 20px'
  }
}};

border: ${props=> props.outline ? '2px solid white':'none'};

gap: 10px;

background: ${props=>{
  if(!!props.link) return 'transparent'
  if(!!props.ghost) return 'rgba(255,255,255,0.1)'
  if(props.backgroundColor) return props.backgroundColor
  if(props.outline) return 'transparent'
  if(props.back) return 'transparent'
  return props.disabled ? '#222':'#3781D8'
}};

border-radius: 100px;

display:flex;
align-items: center;
justify-content:center;

width: ${props=> !!props.fullWidth ? '100%':'auto'};

align-self: ${props=> !!props.fullWidth ? 'auto':'center'};

font-family: "Signika";
font-size: ${props=> props.large ? '15px' : '12px'};
letter-spacing: 0.5px;
font-weight: 700 !important;

cursor: ${props=> props.disabled ? 'not-allowed':'pointer'};
color: ${props=>{
  if(props.color) return props.color;
  if(props.link) return 'rgba(255,255,255,0.7)';
  if(props.ghost) return 'rgba(255,255,255,0.7)';
  if(props.back) return 'white'
  return props.disabled ? '#9f9f9f':'white'
}};
transition: 0.25s;

text-transform: uppercase;

margin: ${props=> !!props.margin ? props.margin : 0};

text-decoration: ${props=> !!props.link ? 'underline' : 'none'};

&:hover{
  background-color: ${props=>{
    if(!!props.link) return `transparent`
    if(!!props.hoverBackgroundColor) {
      return props.hoverBackgroundColor
    } else if(!!props.outline) {
      return 'white'
    } else if(!!props.backgroundColor) {
      return 'white'
    } else if(!!props.ghost) {
      return '#E73C7E'
    } else if(props.disabled) {
      return 'transparent'
    } else {
      return '#E73C7E'
    }
  }};
  color: ${props=>{
    if(!!props.hoverTextColor){
      return props.hoverTextColor
    } else if(props.ghost){
      return 'white'
    } else if(props.outline || props.backgroundColor){
      return 'black'
    } else {
      return 'white'
    }
  }};

  box-shadow: ${props=>{
    if(!!props.link) return `none`
    if (props.disabled) {
      return 'none'
    } else if(props.hoverBackgroundColor) {
      return `0px 0px 18px -2px ${props.hoverBackgroundColor};`
    } else {
      return `0px 0px 18px -2px #E73C7E;`
    }
  }}
}
`

export default function Button(props) {
    let contents = props.children
    if(props.loading) contents = <BeatLoader size="5px" color="white"/>
    return(
      <BaseButton {...props}>
      { !!props.leftArrow && <ArrowImage src="/left-arrow.svg"/>}
      { !!props.imgUrl && <Icon src={props.imgUrl}/> }
      { contents }
      { !!props.rightArrow && <ArrowImage src="/right-arrow.svg"/>}
      { !!props.rightCheck && <ArrowImage src="/small-check.svg"/>}
      </BaseButton> 
    )

}
