import React, { Component, useState } from 'react'
import "../../styles/Header.scss"
import { BeatLoader } from "react-spinners";
import { signOut } from '../modules/auth'
import { FreckleTooltip } from './FreckleTooltip'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { ClickAwayListener } from '@material-ui/core'
import { AppContext, AppAction } from './Context/AppContext'
import Button from './Button'
import { EditorTutorialState } from './tutorial/tutorial'

var moment = require('moment');

const DDMenu = styled.div`
position: absolute;
top: 100%;
left: 0;
margin-top: 0px;
background-color: #222;
width: 200px;

transition: 0.15s;
opacity: ${props=> props.visible ? 1:0};
transform: ${props=> props.visible ? 'scale(1)':'scale(0.975)'};
pointer-events: ${props=> props.visible ? 'auto':'none'};

.item {
	display:flex;
	align-items: center;
	height: 50px;
	padding-left: 20px;
	transition: 0.15s;
	background-color: rgba(255,255,255,0.025);
	cursor: pointer;

	&:hover{
		background-color: #444;
	}
}
`

const SupportButtonContainer = styled.div`
margin-right: 20px;
position: relative;
height: 60px !important;
`
const SupportButtonButton = styled.button`
color: white;
font-size: 14px;
font-weight: 500 !important;
width: auto !important;
padding: 0 20px !important;
height: 60px !important;
border-radius: 0 !important;
font-family: Signika;
font-weight: 700;

&:hover {
	background-color: rgba(255,255,255,0.05) !important;
}
`
const HelpIcon = styled.img`
height: 20px;
margin-right: 10px;
`
function SupportButton(props){
	const [open, setOpen] = useState(false)
	return(
		<SupportButtonContainer 
		onMouseOver={()=>{ setOpen(true) }}
		onMouseOut={()=>{ setOpen(false) }}>
			<SupportButtonButton>
			<HelpIcon src="/white-help.svg"/>
			Get Help
			</SupportButtonButton>
			<DDMenu visible={open}>
			{ props.children }
			</DDMenu>
		</SupportButtonContainer>
	)
}

class Header extends Component {
	static contextType = AppContext

	constructor(props, context) {
		super(props, context);
	}

	returnToLibrary = () => {
		const { history } = this.props
		if(history) history.push(this.props.backDest || '/videos')
	}

	getLibButton = () => {
		if(this.props.showProps === undefined){
			return <span/>
		}else{
			return(
				<FreckleTooltip arrow title={(this.props.showProps ? "Hide":"Show") + " Keyframes (K)"}>
				<button className="library-button" onClick={this.props.toggleProps}>
					<img src={this.props.showProps ? "/properties-icon-selected.svg" : "/properties-icon.svg"}/>
				</button>
				</FreckleTooltip>
			)
		}
	}

	getProductsButton = () => {
		if(this.props.showProducts === undefined){
			return <span/>
		}else{
			return(
				<FreckleTooltip arrow title={(this.props.showProducts ? "Hide":"Show") + " Products Library (L)"}>
				<button className="products-button" onClick={this.props.toggleProducts}>
					<img src={this.props.showProducts ? "/products-icon-selected.svg" : "/products-icon.svg"}/>
				</button>
				</FreckleTooltip>
			)
		}
	}

	getStageButton = () => {
		if(this.props.showsStage === undefined){
			return <span/>
		}else{
			return(
				<FreckleTooltip arrow title={(this.props.showsStage ? "Hide":"Show") + " Timeline (T)"}>
				<button className="stage-button" onClick={this.props.toggleStage}>
					<img src={this.props.showsStage ? "/stage-icon-selected.svg" : "/stage-icon.svg"}/>
				</button>
				</FreckleTooltip>
			)
		}
	}

	getPreviewButton = () => {
		if(this.props.showsPreviewButton === undefined){
			return <span/>
		}else{
			return(
				<FreckleTooltip arrow title="Preview Microsite">
				<button className="preview-button" onClick={this.props.onPreviewMicrosite}>
					▶ &nbsp; Preview Microsite
				</button>
				</FreckleTooltip>
			)
		}
	}

	getPublishButton = () => {
		if(this.props.showsPublishButton === undefined){
			return <span/>
		} else if(!!!this.props.publishEnabled) {
			return(
				<FreckleTooltip arrow title="All changes have already been published.">
				<Button disabled={true} onClick={this.props.onPublishMicrosite}>
					Publish Changes
				</Button>
				</FreckleTooltip>
			)
		}else{
			return(
				<FreckleTooltip arrow title="Publish all changes to this microsite.  Any edits you have made will be publicly visible.">
				<Button onClick={this.props.onPublishMicrosite}>
					{
						this.props.publishing ? <BeatLoader color="#ffffff" size="5px"/> : "Publish Changes"
					}
				</Button>
				</FreckleTooltip>
			)
		}
	}

	getTitle = () => {
		if(!this.props.currentVideo && !this.props.title) return <span/>
		return(
			<div className="title">
				<span>
					{this.props.currentVideo.title}
				</span>
				<span className="date">
					Created {moment(this.props.currentVideo.createdAt).fromNow()}
				</span>
			</div>
		)
	}

	render() {
		const headerClass = this.props.showsBack ? "header-container back" : "header-container"
		const backButton = <button className="back-button" onClick={this.returnToLibrary}><img src="/back-icon.svg"/></button>
		return (
			<div className={headerClass}>
				{ this.props.showsBack && backButton }
				{ this.getTitle() }
				<div className="menu-right">
				{ 
					this.getPublishButton() 
				}
					<div className="menu-bar">
						{!!this.props.showsHelp && <SupportButton>
							<div className="item" onClick={()=>{ 
								this.context.dispatch({ type: AppAction.setEditorTutorialState, payload: EditorTutorialState.addProduct });
								this.setState({ showsHelp: false })
							}}>Restart Tutorial</div>
							<div className="item" onClick={()=>{ 
								window.HubSpotConversations.widget.open() 
							}}>Chat with Support</div>
						</SupportButton>}
						{ this.getProductsButton() }
						{ this.getStageButton() }
						{ this.getLibButton() }
					</div>
					<div className="account">
						<div className="account-avatar">
							{ this.context.state.user ? this.context.state.user.firstName.slice(0, 1) : ""}
						</div>
						<div className="dropdown">
							<div className="item" onClick={()=>{ 
								signOut().then(()=>{
									window.location = "/"
								}).catch((err)=>{
									console.log("Issues logging out: " + JSON.stringify(err))
								})
							}}>Logout</div>
						</div>
					</div>
				</div>
			</div>			
		)
	}
}

export default withRouter(Header);