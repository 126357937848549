import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import DashboardItem from './DashboardItem'
import { abbreviateNumber } from '../../../helpers/abbreviate'
import { useAppContext } from '../../Context/AppContext'

const Table = styled.table`
width: 100%;
border-collapse:collapse;
`
const Thead = styled.thead`
background-color: #2F2F2F;
height: 30px;
font-weight: 400;
font-size: 14px;
`
const Th = styled.th`
text-align: left;
&:first-child {
  padding-left:20px;
  padding-right:20px;
}
`
const Td = styled.td`
text-align: left;
height: 60px;
width: 5%;
&:first-child {
	padding-left:20px;
	padding-right:0;
}
`

const Trh = styled.tr`
background-color: #2F2F2F;
height: 30px;
font-weight: 400;
font-size: 14px;
`
const Tdh = styled.td`
text-align: left;
height: 30px;
&:first-child {
  padding-left:20px;
  padding-right:0;
}
`

const Tbody = styled.tbody`

`
const Tr = styled.tr`
&:nth-child(odd){
  background-color: #252525;
}
&:nth-child(even){
  background-color: #1F1F1F;
}
`

const ProductName = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-self: start;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;

`

const ProductImage = styled.img`
height: 30px;
width: 30px;
border-radius: 1000px;
background-color: white;
margin-right: 10px;
flex-shrink: 0;
`

const H4 = styled.h4`
width: 100%;
text-align: center;
font-weight: 400;
font-size: 14px;
opacity: 0.2;
`

export default function ProductLeaderboardItem(props){
	const context = useAppContext()

	const getTable = () => {
		const lut = context.state.productsLut
		return(
		<Table>
			<Trh>
				<Tdh>Product Name</Tdh>
				<Tdh>Views</Tdh>
				<Tdh>Cart Adds</Tdh>
				<Tdh>Checkouts</Tdh>
				<Tdh>Conversion</Tdh>
			</Trh>
		{
			props.productsStats.map(productStats=>{
				const product = context.state.productsLut[productStats.productId]
				if(!product) return <span/>
				return(
					<Tr>
						<Td>
						<ProductName>
						<ProductImage src={ product.imageUrl }/>
						{ product.title }
						</ProductName>
						</Td>
						<Td>{ productStats.view }</Td>
						<Td>{ productStats.add_to_cart }</Td>
						<Td>{ productStats.checkout }</Td>
						<Td>{ (100*parseFloat(productStats.checkout)/parseFloat(productStats.view)).toFixed(1) }%</Td>
					</Tr>
				)
			})
		}
		</Table>
		)
	}

	return(
		<DashboardItem
		title={props.title}>
		{ getTable() }
		{
			!!!props.productsStats.length && <H4>No product events have been recorded for this account.</H4>
		}
		</DashboardItem>
	)
}