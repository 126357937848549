import React from 'react';
import styled from 'styled-components'

const StopButton = styled.div`
	float: right;
	margin-right: 5px;
	height: 23px;
	width: fit-content;
	outline: none;
	border: none;
	border-radius: 4px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #F74743;
	font-weight: 700;
	font-size: 13px;
	padding: 0 5px;
	user-select: none;
	transition: 0.25s;

	cursor: ${props=> !!props.disabled ? 'auto' : 'pointer'};
	opacity: ${props=> !!props.disabled ? 0.25 : 1};
	filter: ${props=> !!props.disabled ? 'grayscale(1)' : 'none'};

	&:hover {
		box-shadow: 0px 0px 12px 0px rgba(247,71,67,0.5);
	}

	&:active {
		transform: scale(0.99);
	}
`

export default function ClosePathButton(props) {
	return(
		<StopButton
		{...props}>
		<img style={{height: '15px', marginRight: '5px'}} src="/stop.svg"/>
		CLOSE PATH
		</StopButton>
	)
}