import React, { Component, useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components'
import { BeatLoader } from "react-spinners";
import { AppContext, AppAction, useAppContext } from './Context/AppContext'

import TextInput from './TextInput'

import Checkbox from '@material-ui/core/Checkbox'

import * as API from '../modules/freckle-api'

import { signIn } from '../modules/auth'

import Button from './Button'

import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const Enter = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

const ErrorContainer = styled.div`
height: 30px;
width: 100%;
position: absolute;
top: 10px;
left: 0;
display: flex;
align-items: center;
justify-content: center;
transition: 0.25s;

.error-message {
  border-radius: 4px;
  padding: 5px 20px;
  background-color: #E73C7E;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 15px;
    width: 15px;
    padding-right: 10px;
  }
  animation: enterErrorAnim 500ms normal forwards;

  @keyframes enterErrorAnim {
    0% {
      opacity: 0;
      margin-top: -100px;
    }
    100% {
      opacity: 1.0;
      margin-top: 0;
    }
  }
}
`

const Container = styled.div`
padding: 20px;
width: calc(100vw - 40px);
min-height: calc(100vh - 40px);
height: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
font-family: "Signika";

animation: ${Enter} 1.0s normal forwards;

@media (max-aspect-ratio: 1/1) {
  justify-content: flex-start;
}

h3 {
  font-size: 35px;
  color: white;
  padding: 0;
  margin: 0;
}

p {
  color: #8f8f8f;
  text-align: center;
  padding: 0;
}
`

const P = styled.p`
opacity: 0.75;
margin-bottom: 15px !important;
font-size: 16px !important;
`

const Terms = styled.div`
color: white;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 20px;

span {
  display: block;
}

a {
  color: white;
  text-decoration: underline;
  margin-left: 5px;
}
`

export default function CreateAccount(props) {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [acceptedTerms, setAcceptedTerms] = useState(false)

  const context = useAppContext()
  
  const createAccount = async (e) => {
   e.preventDefault()

   setLoading(true)

   const firstName = e.target.elements.firstName.value
   const lastName = e.target.elements.lastName.value
   const email = e.target.elements.email.value
   const password = e.target.elements.password.value
   const passwordconfirm = e.target.elements.passwordconfirm.value

   if(!!!firstName || !!!lastName || !!!email || !!!password || !!!passwordconfirm) {
    setLoading(false)
    setErrorMessage("Please complete all required fields.")
    return	
  }

  if(password !== passwordconfirm) {
    setLoading(false)
    setErrorMessage("Passwords must match.")
    return		
  }

  try {
    await context.createNewAccount({
      firstName,
      lastName,
      email,
      password,
    })

    setLoading(false)
    setErrorMessage(null)
  } catch(error) {
    setLoading(false)
    setErrorMessage(error.message)
  }

 }

const getErrorMessage = () => {
 if(errorMessage){
  return (
   <ErrorContainer>
   <div className="error-message">
   <img src="/error.svg"/>
   {errorMessage}
   </div>
   </ErrorContainer>
   )
} else {
  return <div className="error-container"/>
}
}



  return(
   <Container>
   { getErrorMessage() }

    <h3>Create an account</h3>
    <p>👋 Let's get started!</p>
    <div>
    <form onSubmit={createAccount}>
    <TextInput name="firstName" label="First Name *"/>
    <TextInput name="lastName" label="Last Name *"/>
    <TextInput name="email" label="Email *"/>
    <TextInput name="password" label="Password *" type="password"/>
    <TextInput name="passwordconfirm" label="Confirm Password *" type="password"/>
    
    <Terms>
    <Checkbox checked={acceptedTerms}
        onChange={(e)=>{
          setAcceptedTerms(e.target.checked)
        }}
        style={{color:'white'}}
        size="small"
        name="checked"
        />

      <span>I have read and agree to the <a target="_blank" href="https://freckle.shop/tos">Terms of Service</a>.</span>
      </Terms>

    <Button large disabled={!acceptedTerms} fullWidth margin='25px 0 0 0'>
    { loading ? (<BeatLoader size="5px" color="white"/>) : "CREATE ACCOUNT" }
    </Button>
    </form>
    </div>

   </Container>
   )
}
