import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import DashboardItem from './DashboardItem'
import { abbreviateNumber } from '../../../helpers/abbreviate'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';


const Row = styled.div`
display: flex;
flex-direction: row;
`

const Item = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 20px;
flex: 1;
`

const ConversionItem = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
padding: 20px;
flex: 1;
gap: 15px;
`
const ConversionLabel = styled.h2`
font-size: 20px;
color: white;
padding: 0;
margin: 0;
`

const Value = styled.h2`
font-size: 45px;
color: white;
padding: 0;
margin: 0;
`

const Label = styled.h4`
color: white;
padding: 0;
margin: 0;
font-weight: 700;
font-size: 14px;
`

const ProgressContainer = styled.div`
position: relative;
height: 70px;
width: 70px;
overflow: display;
flex-shrink: 0;
`
const ProgressSVG = styled.svg`
display: block;
max-height: 70px;
`
const Circle = styled.path`
stroke: #45d321;
fill: none;
stroke-width: 4;
stroke-linecap: round;
`
const BaseCircle = styled.path`
stroke: rgba(255,255,255,0.2);
fill: none;
stroke-width: 2;
stroke-linecap: round;
animation: progress 1s ease-out forwards;
`
const ProgressLabel = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
color: white;
height: 70px;
width: 70px;
font-family: Signika;
font-size: 20px;
font-weight: 700;
`

function ProgressBar(props){
    return(
    <ProgressContainer>
    <ProgressSVG viewBox="0 0 36 36">
    <BaseCircle
    strokeDasharray="100, 100"
    d="M18 2.0845
    a 15.9155 15.9155 0 0 1 0 31.831
    a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <Circle
    strokeDasharray={`${props.percentage}, 100`}
    d="M18 2.0845
    a 15.9155 15.9155 0 0 1 0 31.831
    a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    </ProgressSVG>
    <ProgressLabel>
    {props.percentage}
    </ProgressLabel>
    </ProgressContainer>
    )
}

export default function AccountMetricsItem(props){

	return(
		<DashboardItem
		title={props.title}>

        <Row>

        <ConversionItem>
        <ProgressBar 
        style={{height: "20px"}}
        percentage={props.conversion}/>
        <ConversionLabel>
        Average Conversion
        </ConversionLabel>
        </ConversionItem>

		{ 
            props.metrics.map(metric=>{
                let value = metric.value
                if(!isNaN(value)){
                    value = abbreviateNumber(value)
                }
                return(
                    <Item>
                    <Value>{value}</Value>
                    <Label>{metric.name}</Label>
                    </Item>
                )
            }) 
        }
        </Row>
		</DashboardItem>
	)
}