import React from 'react'
import styled from 'styled-components'
import ProductsPane from './ProductsPane'
import ProductItem from './ProductItem'
import { ClickAwayListener } from '@material-ui/core'

const SuperContainer = styled.div`
	width: 80vw;
	height: 80vh;
	max-width: 1400px;
	max-height: 850px;
	display: flex;
	flex-direction: column;
`

const Header = styled.div`
	width: 100%;
	padding: 10px 0px;
	height: 30px;
	background-color: #111;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid black;
`

const Title = styled.h4`
	color: white;
	font-size: 17px;
	font-weight: 700;
	opacity: 0.8;
	margin-left: 24px;
`

const ExitButton = styled.button`
	border: 0;
	outline: 0;
	background-color: transparent;
	color: white;
	font-weight: 700;
	font-size: 20px;
	cursor: pointer;
	transition: 0.2s;
	border-radius: 100px;
	opacity: 0.8;
	height: 30px;
	width: 30px;
	margin-right: 20px;

	&:hover{
		background-color: rgba(255,255,255,0.2);
	}
`

const Container = styled.div`
	width: 100%;
	height: calc(100% - 50px);
	background-color: #111;
	display: flex;
	flex-direction: row;
	position: relative;
	margin: 0 auto;
`

const SelectedProductsPane = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: scroll;
`

const SelectedProductsSubPane = styled.div`
	padding: 30px 50px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-contents: flex-start;
	align-items: flex-start;
`

const List = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 20px 0;
	justify-contents: flex-start;
	align-items: flex-start;

	display: flex;
	> * {
		margin: 0 25px 0 0;
	}
`

const H3 = styled.h3`
	color: #9f9f9f;
	padding: 0;
	margin: 0;
	padding-bottom: 10px;
	font-size: 14px;
	font-weight: 700;
`

export default function ProductsPicker(props){
	const videoProductsList = () => {
		return props.productsInVideo.map(product=>{
			return <ProductItem 
			product={product}/>
		})
	}

	const extraProductsList = () => {
		return props.extraProducts.map(product=>{
			return <ProductItem 
			showDelete
			onDelete={()=>{
				props.onDeleteProduct(product)
			}}
			product={product}/>
		})
	}

	const allProducts = [...props.productsInVideo, ...props.extraProducts]

	return(
		<ClickAwayListener onClickAway={props.onCancel}>
		<SuperContainer>
		<Header>
			<Header>
			<Title>Manage Products</Title>
			<ExitButton onClick={props.onCancel}>×</ExitButton>
			</Header>
		</Header>
			<Container>
				<ProductsPane 
				onSelectProduct={props.onSelectProduct}
				selectedProducts={allProducts}/>
				<SelectedProductsPane>
					<SelectedProductsSubPane>
						<H3>PRODUCTS SHOWN IN YOUR VIDEO</H3>
						<List>
							{ videoProductsList() }
						</List>
					</SelectedProductsSubPane>

					<SelectedProductsSubPane>
						<H3>ADDITIONAL PRODUCTS</H3>
						<List>
							{ extraProductsList() }
						</List>
					</SelectedProductsSubPane>
				</SelectedProductsPane>
			</Container>
		</SuperContainer>
		</ClickAwayListener>
	)
}