import React, { Component } from 'react';
import Dropdown from 'react-dropdown'
import TextInput from './TextInput'
import { BeatLoader } from "react-spinners";
import { createVideo } from '../modules/freckle-api.js'
import { logEvent } from '../helpers/analytics.js'
import Dropzone from 'react-dropzone';
import Button from './Button'
import styled, { keyframes } from 'styled-components'
import { AppContext, AppAction } from './Context/AppContext'

var fs = require('fs')

const FermData = require('form-data')
const axios = require('axios')

const REQUIRED_FIELDS = ["title"]

const BackButtonContainer = styled.div`
position: absolute;
left: 0;
bottom: 0;
transition: 0.5s;
background-color: transparent;
padding: 20px;

${props=>{
  if (props.visible) {
    return `
opacity: 1;
transform: translateY(0);`
  }
      return `
opacity: 0;
transform: translateY(100px);`
}}`

const Background = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
pointer-events: none;
display: flex;
justify-content: center;
align-items: center;`

const Enter = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

const Container = styled.div`
pointer-events: all;
width: 65vw;
min-width: 500px;
max-width: 1000px;
height: 85vh;
max-height: 1000px;
background-color: #111;
color: white;
// padding: 20px 40px 0px 40px;
position: relative;
border-radius: 10px;
overflow: hidden;
padding: 0;
margin: 0;
border: 1px solid rgba(255,255,255,0.05);

h3 {
  font-size: 35px !important;
  width: 100% !important;
  text-align: center !important;
}

.editable-content {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% - 50px);

  .step-container {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
  }
}

.header {
  padding: 0 10px 0 20px;
  width: calc(100% - 30px);
  height: 50px;
  box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1f1f1f;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;

    img {
      height: 15px;
      margin-right: 10px;
      margin-bottom: 2.5px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      outline: none;
      border: none;
      background-color: transparent;
      color: white;
      font-size: 25px;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      cursor: pointer;
      opacity: 0.5;
      transition: 0.25s;
    }
    button:hover {
      opacity: 1;
      background-color: rgba(255,255,255,0.05);
    }
  }
}`

const NameContainer = styled.div`
padding: 40px;
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
overflow: scroll
flex: 1;
flex-direction: column;
margin: 0 auto;
height: 85%;
width: 300px;

animation: ${Enter} 1.0s normal forwards;

h3 {
  font-size: 35px;
  width: 100% !important;
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;
  padding-bottom: 20px;
}`

const FilerPickerContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

animation: ${Enter} 1.0s normal forwards;

p {
  margin: 0 !important;
  padding: 10px 0 30px 0;
  opacity: 0.5;
}`

export default class AddVideo extends Component {
  static contextType = AppContext

  constructor(props, context) {
    super(props, context);

    this.form = React.createRef();

    this.state = {
      ready: false,
      payload: {},
      saving: false,
      selectedVideoFile: null,
      progress: 0,
      steps: ["Choose Video", "Name Video", "Finish"],
      currentStepIdx: 0, 
      projectName: "",
      selectedTemplate: null
    }
  }

  formUpdated = () => {
    const form = this.form.current
    const elements = [...form.elements]
    var completeFields = []
    elements.forEach((input)=>{
      if(REQUIRED_FIELDS.includes(input.name) && input.value.length > 0) completeFields.push(input.name)
    })
    
    const ready = REQUIRED_FIELDS.length === completeFields.length && this.state.selectedVideoFile instanceof File
    if(this.state.ready !== ready) this.setState({ready: ready})
  }

  addVideo = (e) => {
    if(e) e.preventDefault()
    this.setState({saving: true})

    var payload = {}
    payload.title = this.state.projectName
    payload.shopUrl = this.context.state.selectedStore.domain

    logEvent("Clicked Add Video")

    // TODO: decouple upload from component
    // post events for progress, scope to particular video id
    createVideo(payload, this.state.selectedVideoFile, (done, progress, err)=>{
      if(err) {
        logEvent("Add Video Failed", {error: err.message})
        this.setState({saving: false})
        document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":"Video upload unsuccessful.", "type":"error"}}))
      } else if(done) {
        logEvent("Add Video Succeeded")
        this.props.onAdded()
        this.setState({saving: false})
      } else {
        this.setState({progress: progress})
      }
    })
  }

  loadFile = (files) => {
    if(!!!files.length) return
    let file = files[0]
    logEvent("Selected Video File", {fileName: file.name})
    this.setState({selectedVideoFile: file, currentStepIdx: 1})
  }

  getFileUploadView = () => {
    return(
        <Dropzone accept="video/mp4,video/x-m4v,video/*" onDrop={this.loadFile}>
        {({getRootProps, getInputProps}) => (
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <FilerPickerContainer>
              <h3>Choose a Video</h3>
              <p>Drag a video file here, or click to select.</p>
              <Button ghost>SELECT A FILE</Button>
              </FilerPickerContainer>
              </div>
        )}
      </Dropzone>
      )
  }

  saveButtonContents = () => {
    if(this.state.saving) {
      return <span className="button-contents"><BeatLoader color="#ffffff" size="5px"/></span>
    } else {
      return <span>NEXT: {this.state.steps[this.state.currentStepIdx+1].toUpperCase()}</span>
    }
  }

  stepsIndicator = () => {
    const percent = 100*this.state.currentStepIdx / (this.state.steps.length-1)
    return(
      <div className="steps-progress-container">
      <div className="bar" style={{width: `${percent}%` }}/>
      <div className="balls-container">
      {
        this.state.steps.map((step, idx) => {
          let ballClass = "ball"
          if(idx == this.state.currentStepIdx) ballClass = "ball current"
          if(idx < this.state.currentStepIdx) ballClass = "ball done"
          return(
            <div className={ballClass} key={step} data-step-name={step}/>
          )
        })
      }
      </div>
      </div>
    )
  }

  contentForCurrentStep = () => {
    const { steps } = this.state
    switch(this.state.currentStepIdx) {
      case 0:
        return(
          <NameContainer>
            { this.getFileUploadView() }
          </NameContainer>
        )
        break;
      case 1:
        return(
          <NameContainer>
          <h3>Pick a Name</h3>
          <TextInput 
          name="title" 
          label="Video Name *" 
          style={{marginTop:'0', width:'300px', marginBottom: '15px'}} 
          defaultValue={this.state.projectName}
          onChange={(e)=>{
            this.setState({projectName: e.target.value})
          }}
          autoFocus/>
          <Button 
          disabled={!this.state.projectName.length} 
          fullWidth
          onClick={(e)=>{
            this.addVideo(e)
            this.props.onCancel()
          }}>Add Video</Button>
          </NameContainer>
        )
        break
    }
  }

  readyForNextStep = () => {
    switch(this.state.currentStepIdx){
      case 0:
        return true

      case 1:
        return !!this.state.projectName.length

      case 2:
        return true

    }
  }

  render(){
    return(
      <Background>
        <Container>
          <div className="header">
            <div className="left">
              <img src="./new-project.svg"/>
              <h4>New Video</h4>
            </div>
            <div className="right">
            <button onClick={this.props.onCancel}>×</button>
            </div>
          </div>
          <div className="editable-content">
            {
              this.contentForCurrentStep()
            }
          </div>
          <BackButtonContainer
            visible={this.state.currentStepIdx > 0}
            >
            <Button
            backgroundColor="#1f1f1f"
            color="#6f6f6f"
            onClick={()=>{
              this.setState({currentStepIdx: this.state.currentStepIdx-1})
            }}>
            ⟨ &nbsp; {this.state.steps[this.state.currentStepIdx-1] || this.state.steps[0]}
            </Button>
          </BackButtonContainer>
        </Container>
      </Background>      
    )
  }

}

