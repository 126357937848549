import * as UpChunk from '@mux/upchunk';

import localforage from "localforage"

import axios from 'axios'

import moment from 'moment'

import { getJwtToken } from './auth'

import * as Sentry from "@sentry/react";
import { logEvent } from '../helpers/analytics.js'


import * as Shopify from './shopify-manager'
import * as Woo from './woo-manager'

const BASE_URL = !!!process.env.REACT_APP_ENV ? "http://localhost:3000" : "https://api.freckle.shop"

export async function enrollInBeta(code) {

}

export async function addStore(storeDomain, storeType, active=true){
  const token = await getJwtToken()
  const response = await axios.post(`${BASE_URL}/stores`, 
    {
      domain: storeDomain.toLowerCase(),
      active: active,
      storeType: storeType
    },
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  return response.data
}

export async function deleteStore(store){
  const token = await getJwtToken()
  const response = await axios.delete(`${BASE_URL}/stores/${store.id}`, 
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  return response.data
}

export async function updateStore(store, active){
  const token = await getJwtToken()
  const response = await axios.patch(`${BASE_URL}/stores/${store.id}`, 
    {
      domain: store.domain,
      active: active
    },
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  return response.data
}

export async function linkStoreDomain(store){
  const token = await getJwtToken()
  const response = await axios.patch(`${BASE_URL}/stores/${store.id}/linkdomain`, 
    {
      domain: store.domain
    },
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  return response.data
}

export async function createCheckoutSession(tier){
  let redir = `${window.location.protocol}//${window.location.hostname}`
  if(!!window.location.port.length){
    redir = redir + ":" + window.location.port
  }
  const token = await getJwtToken()
  const url = `${BASE_URL}/createpayment/${btoa(redir)}/${tier}`
  const response = await axios.get(url, {
    headers: {
      "Authorization": "Bearer " + token
    }
  })
  return response.data
}

export async function changePlan(tier){
  const token = await getJwtToken()
  const response = await axios.post(`${BASE_URL}/managepayment/changeplan`, 
  {tier: tier}, 
  {
    headers: {
      "Authorization": "Bearer " + token
    }
  })
  return response.data
}

export async function resetPassword(email, code, password){
  const response = await axios.post(`${BASE_URL}/user/reset`, {email: email, code: code, password: password}, {})
  return response.data
}

export async function requestPasswordReset(email){
  const response = await axios.get(`${BASE_URL}/user/forgot/${email}`, {})
  return response.data
}

export async function createCustomerPortalSession(){
  let redir = `${window.location.protocol}//${window.location.hostname}`
  if(!!window.location.port.length){
    redir = redir + ":" + window.location.port
  }
  const token = await getJwtToken()
  const url = `${BASE_URL}/managepayment/${btoa(redir)}`
  const response = await axios.get(url, {
    headers: {
      "Authorization": "Bearer " + token
    }
  })
  return response.data
}

export async function login(username, password) {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, {username: username, password: password}, {})
    return response.data
  } catch(e) {
    switch(e.response.status) {
      case 401: 
        throw new Error("Invalid username or password.")
        break
      case 423:
        throw new Error("This account has not been verified.")
        break
      default:
        throw new Error("Unable to login")
        break
    }
  }
}

export async function verifyAccount(email, code) {
  try {
    const response = await axios.get(`${BASE_URL}/user/verify/${email}/${code}`)
    return response
  } catch(e) {
    throw new Error("Unable to verify this account...")
  }
}

export async function updateProfile(config) {
  const token = await getJwtToken()
  const response = await axios.post(`${BASE_URL}/user/update`, 
  {config},
  {
    headers: {
      "Authorization": "Bearer " + token
    }
  })
  return response.data
}

export async function createAccount(config) {
  const response = await axios.post(`${BASE_URL}/user/create`, config)
  return response.data
}

export async function getProfile() {
  const token = await getJwtToken()
  const response = await axios.get(`${BASE_URL}/profile`, {
    headers: {
      "Authorization": "Bearer " + token
    }
  })
  return response.data
}

export async function syncProducts(store, force=false, callback) {
  switch(store.storeType) {
    case "shopify":
      return Shopify.syncProducts(store, force, callback)
    case "woo":
      return Woo.syncProducts(store, force, callback)
    default:
      throw new Error("Unsupported store type " + store.storeType)
  }
}

export async function createTemplate(payload) {
  const token = await getJwtToken()
  const response = await axios.post(`${BASE_URL}/microsites/create`, 
    payload,
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
  return response.data
}

export async function createVideo(videoPayload, videoFile, callback) {
  const jwtToken = await getJwtToken()
  console.log("creating video...")
  const result = await axios.post(`${BASE_URL}/projects/create`, 
    videoPayload,
    {
      headers: {
        "Authorization": "Bearer " + jwtToken
      }
    })

  console.log("created video record")
  const videoTitle = result.data.name
  const videoId = result.data.id
  const uploadUrl = result.data.uploadUrl

  try {
    console.log("will attempt to upload to: " + uploadUrl)
    logEvent("Attempting Video Upload", {videoId: videoId})

    const upload = UpChunk.createUpload({
      endpoint: uploadUrl,
      file: videoFile,
          chunkSize: 5120, // Uploads the file in ~5mb chunks
        });

        // subscribe to events
        upload.on('error', err => {
          console.log("upload error (mux): " + JSON.stringify(err))
          logEvent("Upload Error (mux)", {videoId: videoId, error: JSON.stringify(err)})
          Sentry.captureException(err);
          callback(true, null, err)
          document.dispatchEvent(
            new CustomEvent(
              "uploadProgress", 
              {detail: {videoId: videoId, videoName: videoTitle, error: err, progress: 0}}
              )
            )
        });

        upload.on('progress', progress => {
          callback(false, progress.detail, null)
          document.dispatchEvent(
            new CustomEvent(
              "uploadProgress", 
              {detail: {videoId: videoId, videoName: videoTitle, error: null, progress: progress.detail}}
              )
            )
        });

        upload.on('success', () => {
          console.log("upload completed")
          logEvent("Upload Completed", {videoId: videoId})
          callback(true, null, null)
          document.dispatchEvent(
            new CustomEvent(
              "uploadProgress", 
              {detail: {videoId: videoId, videoName: videoTitle, error: null, progress: 100}}
              )
            )
        });

      } catch(e) {
        Sentry.captureException(e)
        console.log("upload error: " + JSON.stringify(e))
        logEvent("Upload Error", {videoId: videoId, error: JSON.stringify(e)})
        callback(true, null, e)
        document.dispatchEvent(
          new CustomEvent(
            "uploadProgress", 
            {detail: {videoId: videoId, videoName: videoTitle, error: e, progress: 0}}
            )
          )
      }
    }

    export async function deleteVideo(videoId) {
      const token = await getJwtToken()
      const response = await axios.delete(`${BASE_URL}/projects/${videoId}`, 
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      })
      return response.data
    }

    export async function allTemplates() {
      const token = await getJwtToken()
      const response = await axios.get(`${BASE_URL}/microsites`, 
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      })
      return response.data
    }

    export async function getTemplate(id) {
      const token = await getJwtToken()
      const response = await axios.get(`${BASE_URL}/microsites/${id}`, 
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      })
      return response.data
    }

    export async function deleteTemplate(templateId) {
      const token = await getJwtToken()
      const response = await axios.delete(`${BASE_URL}/microsites/${templateId}`, 
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      })
      return response.data
    }

    export async function updateTemplate(id, config) {
      const token = await getJwtToken()
      const response = await axios.patch(`${BASE_URL}/microsites/${id}`, 
        config,
        {
          headers: {
            "Authorization": "Bearer " + token
          }
        })
      return response.data
    }

    export async function publishMicrosite(id) {
      const token = await getJwtToken()
      const response = await axios.get(`${BASE_URL}/microsites/${id}/publish`, 
        {
          headers: {
            "Authorization": "Bearer " + token
          }
        })
      return response.data
    }

    export async function allVideos() {
     const token = await getJwtToken()
     const response = await axios.get(`${BASE_URL}/projects`, 
     {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
     return response.data
   }

   export async function getVideo(id) {
    const token = await getJwtToken()
    const response = await axios.get(`${BASE_URL}/projects/${id}`, 
    {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
    return response.data
  }

  export function updateFreckles(id, freckles) {
    getJwtToken().then(async token=>{
      const response = await axios.patch(`${BASE_URL}/projects/${id}`, 
        { data: freckles },
        {
          headers: {
            "Authorization": "Bearer " + token
          }
        })
      return response.data
    })
  }

  export function setVideoTemplate(id, templateId) {
    console.log("setVideoTemplate is DEPRECATED!")
  }

  export function fetchPublishedVideos(id) {
    console.log("fetchPublishedVideos is DEPRECATED!")
    return []
  }


  export async function getAggregateAnalytics(store) {
    const token = await getJwtToken()
     const response = await axios.get(`${BASE_URL}/event/analytics/${store}`, 
     {
      headers: {
        "Authorization": "Bearer " + token
      }
    })
     return response
  }

