import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { ClickAwayListener } from '@material-ui/core'
import '../../styles/SwatchColorPicker.scss';
import styled from 'styled-components';

const SwatchContainer = styled.div`
display: flex;
flex-direction: row;
`

const Swatch = styled.div`
position: relative;
cursor: pointer;
height: 40px;
width: 60px;
box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
border-radius: 4px;
box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
background-color: ${props=> props.color};
transition: 0.2s;

&:hover {
	box-shadow: ${props=> props.color} 0px 0px 20px -10px;
}
`

const HexInput = styled.input`
outline: 0;
border: 0;
background-color: #2f2f2f;
width: 60px;
margin-left: 5px;
color: white;
font-family: "Signika";
font-size: 0.7em;
padding: 0 10px;
box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
border-radius: 4px;
`

export default class SwatchColorPicker extends Component {
	constructor(props, context) {
		super(props, context);
		
		this.state = {
			showsPicker: false,
		}

		this.inputRef = React.createRef()
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(this.inputRef.current.value !== nextProps.color) this.inputRef.current.value = nextProps.color
	}

	render() {
		const isCurrent = this.props.property === this.props.selectedProperty
		return(
			<ClickAwayListener onClickAway={()=>{ if(this.state.showsPicker) this.setState({showsPicker: false}) }}>
			<div>
				<SwatchContainer>
		    		<Swatch color={this.props.color} onClick={()=>{ 
		    			this.setState({showsPicker: !this.state.showsPicker}, ()=>{
		    				this.props.onChange(this.props.color, this.props.property)
		    			}) 
		    		}}/>
		    		<HexInput ref={this.inputRef} defaultValue={this.props.color} onChange={(e)=>{
		    			this.props.onChange(e.target.value, this.props.property) 
		    		}}/>
        		</SwatchContainer>
	        	<div style={{position: 'absolute', marginTop: '2px', zIndex: '2', display: (this.state.showsPicker && isCurrent)?'block':'none'}}>
		        	<SketchPicker color={this.props.color || "#fff"} onChange={(c)=>{ this.props.onChange(c.hex, this.props.property) }}/>
	        	</div>
	        </div>
	        </ClickAwayListener>
		)
	}
}