import React, { useState, useRef, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ClickAwayListener } from '@material-ui/core'
import Button from '../../Button'
import Hls from "hls.js"
import { Rnd } from "react-rnd"
import ReactResizeDetector from 'react-resize-detector';
import { BeatLoader, BounceLoader } from "react-spinners"
import * as axios from 'axios'
import { EditorContext, ContextAction, useEditorContext } from '../Context/EditorContext'
import { DragHotspot } from '../Hotspot/Hotspot'

import trackerWorker from '../../../workers/tracker.worker'
import Tracker from '../../../modules/tracking/tracker.js'

import { grabFrames } from '../../../modules/frameGrabber'
import { standardDeviation, average, sum, ema } from '../../../modules/tracking/math'
import localforage from "localforage"

const resizeImageData = require('resize-image-data')

const cov = require( 'compute-covariance' );

const MaxImageHeight = 480

const SuperContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1005;
	background-color: rgba(0,0,0,0.75);
	pointer-events: auto;
	transition: 0.25s;
	overflow: hidden;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: 0.25s;
`

const Content = styled.div`
	background-color: #2f2f2f;
	width: 70vw;
	max-width: 1000px;
	position: relative;
	overflow: hidden;
`
const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 30px;
	padding: 20px 20px;
	background-color: #2f2f2f;
	border-top: 1px solid black;
`

const VideoContainer = styled.div`
	width: 100%;
	min-width: 100px;
	height: 80vh;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`

const LoadingContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
`

const Video = styled.video`
	opacity: ${props=> props.visible ? '1':'0'};
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	background-color: #2f2f2f;
`

const Box = styled.div`
	width: calc(100% - 8px);
	height: calc(100% - 8px);
	margin: 4px;
	border: 2px solid #4A90E2;
	background-color: transparent;
	min-height: 20px;
	min-width: 20px;
	box-shadow: 0 0 0 300vh rgba(0,0,0,0.5);
	position: relative;
`

const OverlayLayer = styled.div`
	position: absolute;
	left: ${props=>{
		const val = `calc(50% - ${props.marginLeft})`
		return val
	}};
	top: ${props=> `calc(50% - ${props.marginTop})` };
	width: ${props=> props.width};
	height: ${props=> props.height};
	display: ${props=> props.ready ? 'block':'none'};
	overflow: hidden;
`

const BoxCorner = styled.div`
	position: absolute;
	left: ${props=>{
		switch(props.position){
			case 'topleft':
			case 'bottomleft':
			case 'middleleft':
				return '0';
			case 'topmiddle':
			case 'bottommiddle':
				return 'calc(50% - 2px)'
			default: 
				return 'calc(100% - 5px)'
		}
	}};

	top: ${props=>{
		switch(props.position){
			case 'topleft':
			case 'topright':
			case 'topmiddle':
				return '0';
			case 'middleleft':
			case 'middleright':
				return 'calc(50% - 4px)'
			default: 
				return 'calc(100% - 5px)'
		}
	}};

	display: block;
	height: 8px;
	width: 8px;
	background-color: #54A1FF;
`

const Header = styled.div`
	width: calc(100% - 40px);
	padding: 10px 20px;
	height: 30px;
	background-color: #2f2f2f;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid black;
`

const Title = styled.h4`
	color: white;
	font-size: 17px;
	font-weight: 700;
	opacity: 0.8;
`

const ExitButton = styled.button`
	border: 0;
	outline: 0;
	background-color: transparent;
	color: white;
	font-weight: 700;
	font-size: 20px;
	cursor: pointer;
	transition: 0.2s;
	border-radius: 100px;
	opacity: 0.8;
	height: 30px;
	width: 30px;

	&:hover{
		background-color: rgba(255,255,255,0.2);
	}
`

const ButtonGuts = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

function BoundingBox(props){
	return(
		<React.Fragment>
		<Box>
		</Box>
		<BoxCorner position="topleft"/>
		<BoxCorner position="topright"/>
		<BoxCorner position="bottomleft"/>
		<BoxCorner position="bottomright"/>
		<BoxCorner position="middleleft"/>
		<BoxCorner position="middleright"/>
		<BoxCorner position="topmiddle"/>
		<BoxCorner position="bottommiddle"/>
		</React.Fragment>
	)
}

const TrackerCanvas = styled.canvas`
z-index: 1000;
position: absolute;
left: 50%;
top: 50%;
pointer-events: none;
border: ${props=> '1px solid ' + props.outline};
opacity: ${props=> !!props.hidden ? 0:1}
`

export default function TrackSettings(props){
	const videoRef = useRef()
	const [videoConfigured, setVideoConfigured] = useState(false)
	
	const [aspectRatio, setAspectRatio] = useState(0)
	const [videoSize, setVideoSize] = useState({height: 0, width: 0})

	const [overlaySize, setOverlaySize] = useState({ width: 0, height: 0 })
	const [region, setRegion] = useState({x: props.startPosition.x - 0.05, y: props.startPosition.y - 0.05, width: 0.1, height: 0.1})

	const startPosition = useRef(props.startPosition)

	const framesBuffer = useRef([])
	const bufferTimer = useRef(null)

	const trackerInstance = useRef(null)

	const [tracking, setTracking] = useState(false)

	const [group, setGroup] = useState(null)

	const currentIndex = useRef(0)

	const context = useEditorContext()

	const canvasRef = useRef(null)
	const drawCanvasRef = useRef(null)

	const lastImageRef = useRef(null)

	useEffect(()=>{
		if(!!context){
			const _group = context.state.groups.find((group)=>{ return group.id === context.state.selectedGroupId })
			setGroup(_group)
		}

		if(!videoConfigured){
			// videoRef.current.defaultPlaybackRate = 0.25
			videoRef.current.addEventListener('seeked', function() {
				setVideoConfigured(true)
			}, false);
			videoRef.current.addEventListener('loadedmetadata', function() {
				videoRef.current.currentTime = props.time;
				const playerHeight = videoRef.current.videoHeight
				const playerWidth = videoRef.current.videoWidth
				
				setVideoSize({height: playerHeight, width: playerWidth})
				setAspectRatio(playerWidth / playerHeight)

				console.log("loading metadata bitches")
			}, false);
			videoRef.current.addEventListener('canplay', ()=>{
				console.log("can play!!!")
			})
			videoRef.current.addEventListener('error', (e)=>{

			})
		}

		return ()=>{
			cancelAnimationFrame(bufferTimer.current)
		}
	}, [])

	useEffect(()=>{
		const loadMedia = async () => {
			const storedVideoKey = props.src
			const data = await localforage.getItem(storedVideoKey)
			
			try {
				const arrayBuffer = await data.arrayBuffer()
				const typedBlob = new Blob([arrayBuffer], {type: 'video/mp4'});
				const url = window.URL.createObjectURL(typedBlob)
				videoRef.current.src = url
			} catch(e) {
				const resp = await axios.get(props.src, {responseType: 'blob'})
				const arrayBuffer = await resp.data.arrayBuffer()
				const typedBlob = new Blob([arrayBuffer], {type: 'video/mp4'});

				localforage.setItem(storedVideoKey, typedBlob)
				videoRef.current.src = URL.createObjectURL(typedBlob)
			}

			resizedVideo()
		}
		loadMedia()
	}, [])

	useEffect(()=>{
		resizedVideo()
	}, [aspectRatio])

	const resizedVideo = () => {
		const player = videoRef.current
		var height = player.getBoundingClientRect().height
		var width = player.getBoundingClientRect().width

		const containerAspectRatio = width / height

		if(aspectRatio <= containerAspectRatio) {
			width = aspectRatio * height
		} else {
			height = width / aspectRatio
		}

		canvasRef.current.width = width
		canvasRef.current.height = height
		canvasRef.current.style.marginLeft = (-1*width/2) + "px"
		canvasRef.current.style.marginTop = (-1*height/2) + "px"

		const scaledWidth = (MaxImageHeight / videoRef.current.videoHeight) * videoRef.current.videoWidth

		drawCanvasRef.current.width = scaledWidth
		drawCanvasRef.current.height = MaxImageHeight
		drawCanvasRef.current.style.marginLeft = (-1*scaledWidth/2) + "px"
		drawCanvasRef.current.style.marginTop = (-1*MaxImageHeight/2) + "px"

		setOverlaySize({height: height, width: width})
	}

	const getFreckleOffset = () => {
		const dX = startPosition.current.x - (region.x + 0.5 * region.width)
		const dY = startPosition.current.y - (region.y + 0.5 * region.height)
		return {dX, dY}
	}

	const stopTracking = async () => {
		// console.log("stop tracking")
		const result = trackerInstance.current.forceComplete()
		let rawFreckles = result.freckles
		const offsets = getFreckleOffset()

		rawFreckles = rawFreckles.map(item=>{
			return {...item, x: item.x + offsets.dX, y:  item.y + offsets.dY} 
		})

		props.onSuccess(rawFreckles)
	}

	const startTracking = async () => {
		if(!!bufferTimer.current) return

		const frame = getFrame()
		trackerInstance.current = new Tracker({boundingBox: region, height: frame.height, width: frame.width, timestamp: props.time})
		tick()
	}

	const tick = async () => {
		const timestamp = videoRef.current.currentTime

		const ctx = canvasRef.current.getContext('2d')
		ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
		ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

		const frame = getFrame()

		// console.time("track")
		let result = trackerInstance.current.inspectFrame(frame, timestamp)
		// console.timeEnd("track")

		if(result.done || timestamp === videoRef.current.duration) {
			if(!result.done) {
				result = trackerInstance.current.forceComplete()
			}
			let rawFreckles = result.freckles
			const offsets = getFreckleOffset()

			rawFreckles = rawFreckles.map(item=>{
				return {...item, x: item.x + offsets.dX, y:  item.y + offsets.dY} 
			})

			props.onSuccess(rawFreckles)

		} else {
			bufferTimer.current = requestAnimationFrame(tick);
			ctx.beginPath()

			const pos = result.position
			const x = (pos.x-region.width/2) * canvasRef.current.width,
			y = (pos.y-region.height/2) * canvasRef.current.height,
			width = canvasRef.current.width * region.width,
			height = canvasRef.current.height * region.height

			ctx.rect(
				x,y,width,height
			)
			ctx.strokeStyle = "rgb(3, 198, 252)"
			ctx.lineWidth = 3
			ctx.stroke()

			// const features = result.features
			// for(let i=0; i<features.length; i+=2) {
			// 	ctx.beginPath();
			// 	ctx.rect(
			// 		features[i]*canvasRef.current.width-3,
			// 		features[i+1]*canvasRef.current.height-3,
			// 		6,
			// 		6
			// 	)
			// 	ctx.strokeStyle = "red"
			// 	ctx.lineWidth = 3
			// 	ctx.stroke()
			// }

			if(videoRef.current.paused) {
				videoRef.current.play()
			}

		}
	}

	const getFrame = () => {
		// console.time("get frame")
		const ctx = drawCanvasRef.current.getContext('2d')
		videoRef.current.crossOrigin = 'anonymous'
		// console.time("draw frame")
		ctx.drawImage(videoRef.current, 0, 0, drawCanvasRef.current.width, drawCanvasRef.current.height);
		// console.timeEnd("draw frame")
		// console.time("get image data")
	    const frame = ctx.getImageData(0, 0, drawCanvasRef.current.width, drawCanvasRef.current.height);
	    // console.timeEnd("get image data")
	    // console.timeEnd("get frame")
	    return frame
	}

	// const startTracking_old = async () => {

	// 	let startTime = Math.round(props.time * 10)/10
	// 	currentIndex.current = startTime

	// 	const frame = await props.store.getItem("0")
	// 	lastImageRef.current = frame

	// 	trackerWorkerInstance.postMessage({
	// 		type: "start",
	// 		region: region,
	// 		time: startTime,
	// 		videoSize: {height: frame.height, width: frame.width}
	// 	})

	// 	trackNextFrame(currentIndex.current)
	// }

	// const trackNextFrame_old = async () => {
	// 	const key = (Math.round(10*currentIndex.current)/10).toString()
	// 	const frame = await props.store.getItem(key)
	// 	lastImageRef.current = frame

	// 	if(!!frame){
	// 		trackerWorkerInstance.postMessage({
	// 			type: "new_frame",
	// 			frame: frame,
	// 			timestamp: timestamp
	// 		})
	// 	} else {
	// 		trackerWorkerInstance.postMessage({
	// 			type: "done"
	// 		})
	// 	}
	// }

	let hotspotSize = videoSize.width * 0.05
	if(hotspotSize > 50) hotspotSize = 50
	if(hotspotSize < 30) hotspotSize = 30

	return(
		<SuperContainer aria-container
		onClick={(e)=>{
			if(!!e.target.getAttribute('aria-container')){
				props.onCancel()
			}
		}}>
				<Container>
					<Content>
						<Header>
				              <Title>Track</Title>
				            <ExitButton onClick={props.onCancel}>×</ExitButton>
				          </Header>

						<VideoContainer>
							{!!!videoConfigured && <LoadingContainer>
								<BeatLoader color="#ffffff" size="5px"/>
								<h3>Preparing your video...</h3>
							</LoadingContainer>}

							<ReactResizeDetector handleWidth handleHeight onResize={resizedVideo}>
								<Video 
								muted
								loop={false}
								visible={videoConfigured} 
								ref={videoRef}>
								</Video>
							</ReactResizeDetector>

							{!!videoConfigured && !!!tracking && <OverlayLayer
							width={`${overlaySize.width}px`}
							height={`${overlaySize.height}px`}
							marginLeft={`${(overlaySize.width/2)}px`}
							marginTop={`${((overlaySize.height/2))}px`}
							ready={!!overlaySize.width && videoConfigured}
							>
							
							{!!group && <DragHotspot
							group={group}
							size={ hotspotSize }
							type={ group.properties.hotspotType }
							color={ group.properties.color }
							accentColor={ group.properties.accentColor }
							dragging={true}
							addedStyle={{
								left: `${startPosition.current.x * overlaySize.width}px`,
								top: `${startPosition.current.y * overlaySize.height}px`
							}}/>}

								<Rnd 
								minHeight='30px'
								minWidth='30px'
								position={{
									x: region.x * overlaySize.width,
									y: region.y * overlaySize.height
								}}
								size={{
									width: region.width * overlaySize.width,
									height: region.height * overlaySize.height,
								}}
								onDragStop={(e, d) => {
									// TODO: make this a percentage so it will resize and reposition with resize of window
									setRegion({...region, x: d.x / overlaySize.width, y: d.y / overlaySize.height})
								}}
								onResizeStop={(e, direction, ref, delta, position) => {
									// TODO: make this a percentage so it will resize and reposition with resize of window
									const {width, height} =  ref.getBoundingClientRect()
									setRegion({x: position.x / overlaySize.width, y: position.y / overlaySize.height, width: width/overlaySize.width, height: height/overlaySize.height})
								}}
								bounds="parent">
									<BoundingBox/>
								</Rnd>
							</OverlayLayer> }

						<TrackerCanvas ref={canvasRef}/>
						<TrackerCanvas hidden ref={drawCanvasRef}/>

						</VideoContainer>
						<Footer>
							<Button onClick={props.onCancel} back>CANCEL</Button>
							<Button 
							backgroundColor={ tracking ? 'red':null }
							hoverBackgroundColor={ tracking ? 'red':null }
							hoverTextColor={ tracking ? 'white':null }
							onClick={()=>{
								if(!!!bufferTimer.current){
									setTracking(true)
									startTracking()
								} else {
									setTracking(false)
									stopTracking()
								}
							}}>
							{ tracking ? <ButtonGuts><BounceLoader color="#ffffff" size="15px"/> &nbsp; STOP</ButtonGuts> : "TRACK"}
							</Button>
						</Footer>
					</Content>
				</Container>
		</SuperContainer>
	)
}