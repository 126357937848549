import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
	height: 40px;
	width: 100%;
	background-color: #1E1E1E;
	display: flex;
	align-items: center;
	justify-content: start;
	overflow: hidden;
	border-bottom:1px solid #1f1f1f;
`

const TabItem = styled.div`
	height: 100%;
	width: auto;
	padding: 0 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: ${props=> props.selected ? '#3f3f3f' : 'transparent'};
	cursor: pointer;
	font-family: "Signika";
	font-size: 14px;
	transition: 0.15s;

	&:hover {
		background-color: ${props=> props.selected ? '#3f3f3f' : '#2f2f2f'};
	}
`

export default function TabSelector(props){
	return(
		<Container>
		{
			props.items.map((item, idx)=>{
				return <TabItem 
				onClick={()=>props.didSelectIndex(idx)}
				selected={idx === props.selectedIndex}>
				{ item }
				</TabItem>
			})
		}
		</Container>
	)
}