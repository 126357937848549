import { createContext, useContext, useState, useEffect, useReducer } from 'react'
import React from 'react'
import { sanitizeFrames } from '../../../helpers/frames-helper.js'
import { updateFreckles } from '../../../modules/freckle-api.js'

const { uuid } = require('uuidv4');

var _ = require('lodash');

const R = require('ramda');
const MAX_UNDO_LENGTH = 10

export const ContextAction = {
	addKeyframe: "addKeyframe",
	updateKeyframe: "updateKeyframe",
	addProduct: "addProduct",
	setCurrentVideo: "setCurrentVideo",
	deleteGroup: "deleteGroup",
	setGroupProperties: "setGroupProperties",
	setGroupsOrder: "setGroupsOrder",
	setRequestedPlayheadTime: "setRequestedPlayheadTime",
	setSelectedGroup: "setSelectedGroup",
	setDurationTime: "setDurationTime",
	deleteFreckle: "deleteFreckle",
	deleteFreckles: "deleteFreckles",
	undoAction: "undoAction",
	redoAction: "redoAction",
	addRange: "addRange",
	updateRange: "updateRange",
	deleteRanges: "deleteRanges",
	setPausedAt: "setPausedAt",
	setProductType: "setProductType",
}

export const EditorContext = createContext();

export function EditorContextProvider(props) {
	const [state, dispatch] = useReducer((state, action)=>{
		let newState = {...state}

		var groups = R.clone(state.groups)
		var groupsFuture = [...state.groupsFuture]
		var groupsPast = [...state.groupsPast]

		switch(action.type){

			case ContextAction.setPausedAt:
				return ({
					...state,
					pausedAt: action.payload
				})

			case ContextAction.setGroupProperties:
				if(state.selectedGroupId != null){
					const freckle = action.payload

					groupsPast.push(R.clone(groups))
					if(groupsPast.length > MAX_UNDO_LENGTH) {
						groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
					}

					groupsFuture = []

					const groupMatch = groups.find((group)=>{ return group.id === state.selectedGroupId })      
					groupMatch.properties = { ...groupMatch.properties, ...action.payload} 

					updateFreckles(state.currentVideo.id, groups)

					return ({
						...state,
						groups: groups,
						groupsPast: groupsPast,
						groupsFuture: groupsFuture
					})


				}
				break

			case ContextAction.addRange:
				if(state.selectedGroupId != null){
					groupsPast.push(R.clone(groups))
					if(groupsPast.length > MAX_UNDO_LENGTH) {
						groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
					}

					groupsFuture = []

					const group = groups.find((group)=>{ return group.id === state.selectedGroupId })

					group.ranges.push({
						id: uuid(),
						...action.payload, 
					})

					updateFreckles(state.currentVideo.id, groups)

					return ({
						...state,
						groups: groups,
						groupsPast: groupsPast,
						groupsFuture: groupsFuture
					})

				}
				return state

			case ContextAction.deleteRanges:
				const { rangedeletes, skipHistory } = action.payload

				if (!skipHistory) {
					groupsPast.push(R.clone(groups))
					if(groupsPast.length > MAX_UNDO_LENGTH){ 
						groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
					}
					groupsFuture = []
				}

				// group deletes by groupid
				const rangeDeleteGroups = _.groupBy(rangedeletes, function(item){ return item.groupid })

				Object.keys(rangeDeleteGroups).forEach(groupid=>{
					const ids = new Set(rangeDeleteGroups[groupid].map(item=> item.id))
					
					const frames = groups.find((group)=>{ return group.id === groupid }).ranges 
					var newFrames = [...frames].filter((loc)=>{ return !ids.has(loc.id) })
					newFrames.sort((a, b) => (a.ts > b.ts) ? 1 : -1)
					newFrames = sanitizeFrames(newFrames)

					const groupMatch = groups.find((group)=>{ return group.id === groupid })      
					groupMatch.ranges = newFrames
				})

				updateFreckles(state.currentVideo.id, groups)

				return ({
					...state,
					groups: groups,
					groupsPast: groupsPast,
					groupsFuture: groupsFuture
				});

			case ContextAction.updateRange:
				const { rangeId, rangeUpdates } = action.payload
				if(state.selectedGroupId != null){
					groupsPast.push(R.clone(groups))
					if(groupsPast.length > MAX_UNDO_LENGTH) {
						groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
					}

					groupsFuture = []

					const group = groups.find((group)=>{ return group.id === state.selectedGroupId })

					let range = group.ranges.find(range=> range.id == rangeId)
					if (range) {
						Object.keys(rangeUpdates).forEach(key=>{
							range[key] = rangeUpdates[key]
						})
					}

					updateFreckles(state.currentVideo.id, groups)

					return ({
						...state,
						groups: groups,
						groupsPast: groupsPast,
						groupsFuture: groupsFuture
					})

				}
				return state

			case ContextAction.updateKeyframe:
				const { id, updates } = action.payload
				if(state.selectedGroupId != null){
					groupsPast.push(R.clone(groups))
					if(groupsPast.length > MAX_UNDO_LENGTH) {
						groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
					}

					groupsFuture = []

					const group = groups.find((group)=>{ return group.id === state.selectedGroupId })
					let keyframe = group.locations.find(kf=> kf.id == id)
					if (keyframe) {
						Object.keys(updates).forEach(key=>{
							keyframe[key] = updates[key]
						})
					}

					group.locations = sanitizeFrames(group.locations)

					updateFreckles(state.currentVideo.id, groups)

					return ({
						...state,
						groups: groups,
						groupsPast: groupsPast,
						groupsFuture: groupsFuture
					})

				}
				return state

			case ContextAction.addKeyframe:
				const selectedGroup = state.groups.find(group=> group.id === state.selectedGroupId)
				if(state.selectedGroupId != null && selectedGroup && selectedGroup.itemType == "hotspot"){
					let newKeyframes = action.payload
					if(!Array.isArray(newKeyframes)) {
						newKeyframes = [newKeyframes]
					}

					// add ids to these freckles
					newKeyframes.forEach(kf=> kf.id = uuid())

					const newTimestamps = newKeyframes.map(freckle=> freckle.ts)

					const keyframes = state.groups.find((group)=>{ return group.id === state.selectedGroupId }).locations 

					var combinedKeyframes = [...keyframes].filter((loc)=>{ return !newTimestamps.includes(loc.ts) })
					combinedKeyframes = combinedKeyframes.concat(newKeyframes)
					combinedKeyframes.sort((a, b) => (a.ts > b.ts) ? 1 : -1)

					combinedKeyframes = sanitizeFrames(combinedKeyframes)

					groupsPast.push(R.clone(groups))
					if(groupsPast.length > MAX_UNDO_LENGTH) {
						groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
					}

					groupsFuture = []

					const groupMatch = groups.find((group)=>{ return group.id === state.selectedGroupId })      
					groupMatch.locations = combinedKeyframes

					updateFreckles(state.currentVideo.id, groups)

					return ({
						...state,
						groups: groups,
						groupsPast: groupsPast,
						groupsFuture: groupsFuture
					})
				}
				return state

			case ContextAction.setProductType:
				const updateGroup = groups.find(group=> group.id === state.selectedGroupId)
				if (updateGroup) {
					groupsPast.push(R.clone(groups))
					if(groupsPast.length > MAX_UNDO_LENGTH){ 
						groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
					}

					groupsFuture = []

					updateGroup.itemType = action.payload

					updateFreckles(state.currentVideo.id, groups)

					return ({
						...state,
						groups: groups,
						groupsPast: groupsPast,
						groupsFuture: groupsFuture
					})
				}
				return state

			case ContextAction.addProduct:
				const payload = {
					itemType: action.itemType,
					...action.payload,
					properties: {
						hotspotType: "circleProductImage",
						color: "#ffffff",
						accentColor: "#000000"
					},
					locations: [],
					ranges: [],
				}

				groupsPast.push(R.clone(groups))
				if(groupsPast.length > MAX_UNDO_LENGTH){ 
					groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
				}

				groupsFuture = []

				groups.splice(action.payload.index, 0, payload)

				updateFreckles(state.currentVideo.id, groups)

				return ({
					...state, 
					groups: groups,
					groupsPast: groupsPast,
					groupsFuture: groupsFuture,
					selectedGroupId: action.payload.id
				})


			case ContextAction.setCurrentVideo:
				let savedGroups = (!!action.payload.data && action.payload.data.length > 0) ? JSON.parse(action.payload.data) : []
				savedGroups = savedGroups.map(group=>{
					if(!!!group.properties) group.properties = {
						hotspotType: "circleProductImage",
						color: "white"
					}
					if (!group.itemType) {
						group.itemType = "hotspot"
					}
					if (!group.ranges) {
						group.ranges = []
					}
					group.locations.forEach(kf=>{
						if (!kf.id) kf.id = uuid()
					})
					return group
				})

				const selectedGroupId = (savedGroups.length > 0) ? savedGroups[0].id : null
				const currentVideo = {...action.payload}
				return {
					...state,
					currentVideo: currentVideo,
					selectedGroupId: selectedGroupId,
					groups: savedGroups
				}

			case ContextAction.deleteGroup:
				groupsPast.push(R.clone(groups))
				if(groupsPast.length > MAX_UNDO_LENGTH){
					groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
				}
				groupsFuture = []

				const currentSelectedIdx = state.groups.findIndex(group=>group.id === action.payload.id)
				let newSelectedIdx = currentSelectedIdx - 1
				if(newSelectedIdx < 0) newSelectedIdx = 0

				groups = groups.filter((group)=>{ return group.id !== action.payload.id })

				const newSelectedGroupId = !!groups.length ? groups[newSelectedIdx].id : null

				updateFreckles(state.currentVideo.id, groups)

				return ({
					...state,
					groups: groups,
					groupsPast: groupsPast,
					groupsFuture: groupsFuture,
					selectedGroupId: newSelectedGroupId
				})
				break

			case ContextAction.setGroupsOrder:
				groupsPast.push(R.clone(groups))
				if(groupsPast.length > MAX_UNDO_LENGTH){ 
					groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
				}
				groupsFuture = []

				const orderGroupId = action.payload.groupId
				const newIndex = action.payload.index

				const group = groups.filter((group)=>group.id === orderGroupId)[0]
				const oldIndex = groups.indexOf(group)

				groups.splice(newIndex, 0, groups.splice(oldIndex, 1)[0])

				updateFreckles(state.currentVideo.id, groups)

				return ({
					...state,
					groups: groups,
					groupsPast: groupsPast,
					groupsFuture: groupsFuture
				});

			case ContextAction.setRequestedPlayheadTime:
				return {...state, requestedPlayheadTime: action.payload}

			case ContextAction.setSelectedGroup:
				return {...state, selectedGroupId: action.payload}

			case ContextAction.setDurationTime:
				return {...state, durationTime: action.payload}

			case ContextAction.deleteFreckles:
				const deletes = action.payload

				groupsPast.push(R.clone(groups))
				if(groupsPast.length > MAX_UNDO_LENGTH){ 
					groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
				}
				groupsFuture = []

				// group deletes by groupid
				const deleteGroups = _.groupBy(deletes, function(item){ return item.groupid })

				Object.keys(deleteGroups).forEach(groupid=>{
					const ids = new Set(deleteGroups[groupid].map(item=> item.id))
					
					const frames = state.groups.find((group)=>{ return group.id === groupid }).locations 
					var newFrames = [...frames].filter((loc)=>{ return !ids.has(loc.id) })
					newFrames.sort((a, b) => (a.ts > b.ts) ? 1 : -1)
					newFrames = sanitizeFrames(newFrames)

					const groupMatch = groups.find((group)=>{ return group.id === groupid })      
					groupMatch.locations = newFrames
				})

				updateFreckles(state.currentVideo.id, groups)

				return ({
					...state,
					groups: groups,
					groupsPast: groupsPast,
					groupsFuture: groupsFuture
				});


			case ContextAction.deleteFreckle:
				const freckle = action.payload

				const frames = state.groups.find((group)=>{ return group.id === action.groupId }).locations 

				var newFrames = [...frames].filter((loc)=>{ return loc.ts !== freckle.ts })
				newFrames.sort((a, b) => (a.ts > b.ts) ? 1 : -1)

				newFrames = sanitizeFrames(newFrames)

				groupsPast.push(R.clone(groups))
				if(groupsPast.length > MAX_UNDO_LENGTH){ 
					groupsPast = groupsPast.slice(1, MAX_UNDO_LENGTH-1)
				}
				groupsFuture = []

				const groupId = action.groupId
				const groupMatch = groups.find((group)=>{ return group.id === groupId })      
				groupMatch.locations = newFrames

				updateFreckles(state.currentVideo.id, groups)

				return ({
					...state,
					groups: groups,
					groupsPast: groupsPast,
					groupsFuture: groupsFuture
				});

			case ContextAction.undoAction:
				if(groupsPast.length > 0){
			        // move current groups to future
			        groupsFuture.push(R.clone(groups))
			        // set current groups from last past
			        groups = groupsPast[groupsPast.length-1]
			        // remove last item in past
			        groupsPast.pop()

			        let newSelectedGroupId = state.selectedGroupId
			        if(!groups.find(group=> group.id === state.selectedGroupId)){
			        	newSelectedGroupId = null
			        }

			        updateFreckles(state.currentVideo.id, groups)
					return ({
						...state,
						groupsFuture: groupsFuture,
						groupsPast: groupsPast,
						groups: groups,
						selectedGroupId: newSelectedGroupId
					})
			    }
				return state

			case ContextAction.redoAction:
				if(groupsFuture.length > 0){
			        // move current groups to past
			        groupsPast.push(R.clone(state.groups))
			        // set current groups from last future
			        groups = groupsFuture[groupsFuture.length-1]
			        // remove last item in future
			        groupsFuture.pop()

			        updateFreckles(state.currentVideo.id, groups)
					return ({
						...state,
						groupsFuture: groupsFuture,
						groupsPast: groupsPast,
						groups: groups
					})
			    }
				return state
		}

	}, 
	{
		currentVideo: null,
		durationTime: 0,

		groups: [], 
		groupsFuture: [],
		groupsPast: [],

		selectedGroupId: null,
		requestedPlayheadTime: null,	

		pausedAt: 0,
	})

	return (
		<EditorContext.Provider value={{
			state, 
			dispatch,
			...state
		}}>
		{props.children}
		</EditorContext.Provider>
	)
}

export function useEditorContext() {
	return useContext(EditorContext)
}

