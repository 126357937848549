import React from 'react'
import { AppContextProvider } from './js/components/Context/AppContext'
import App from './App'

export default function AppWrapper(props){
	return(
		<AppContextProvider>
		<App/>
		</AppContextProvider>
	)
}