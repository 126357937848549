import React, { Component } from 'react';
import styled from 'styled-components'
import Button from './Button'
import { ClickAwayListener } from '@material-ui/core'
import { CSSTransition } from 'react-transition-group'

const Background = styled.div`
position: fixed;
left: 0;
top: 0;
width: 100%;
height: 100vh;
background-color: rgba(0,0,0,0.5);
z-index: 1000;
display: flex;
justify-content: center;
align-items: center;
transition: 0.25s;
pointer-events: ${props=> props.display ? 'auto':'none'};
opacity: ${props=> props.display ? 1:0};
`

const Container = styled.div`
background-color: #2f2f2f;
padding: 10px 15px;
display: flex;
flex-direction: column;
max-width: 600px;
width: 85%;
transition: 0.25s;

border-radius: 8px;

pointer-events: ${({ state }) => (state === "entered" ? 'auto':'none')};
opacity: ${({ state }) => (state === "entered" ? 1:0)};
transform: ${({ state }) => (state === "entered" ? 'scale(1)':'scale(0.95)')};
`

const Header = styled.div`
flex-grow: 0;
`

const Contents = styled.div`
flex-grow: 1;
padding: 25px 20px;
min-height: 100px;
`

const Footer = styled.div`
flex-grow: 0;
width: 100%;
height: 45px;
display: flex;
align-items: center;
justify-content: space-between;
`

export default function Modal(props){
	return(
	<Background {...props}>
		<CSSTransition
	    in={props.display}
	    timeout={props.display ? 0 : 250}
	    unmountOnExit 
	    mountOnEnter>
	    {(state) =>
			<ClickAwayListener onClickAway={props.onCancel}>
			<Container {...props} state={state}>
				<Contents>
				{ props.children }
				</Contents>
				<Footer>
					<Button ghost onClick={props.onCancel}>Cancel</Button>
					<Button loading={props.loading} onClick={props.onOkay}>
						{!!props.okayText ? props.okayText : "Okay"}
					</Button>
				</Footer>
			</Container>	
			</ClickAwayListener>
		}
		</CSSTransition>
	</Background>
	)
}