import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactResizeDetector from 'react-resize-detector';

const DefaultWidth = 300;

const ArrowMargin = 20

const Popover = styled.div`
width: 100%;
height: 100%;
flex: 1;`

const TooltipContainer = styled.div`
color: black;
text-align: left;
position: fixed;
left: ${props=> props.position.x + "px"};
top: ${props=> props.position.y + "px"};
width: ${props=> props.width + "px"};
z-index: 20000;
pointer-events: ${props=> props.open ? 'auto' : 'none'};
opacity: ${props=> props.open ? '1' : '0'};
transform: ${props=> props.open ? 'scale(1)' : 'scale(0.98)'};
transition: opacity 0.25s, transform 0.25s;

display: ${props=> (props.position.x !== 0 && props.position.y !== 0) ? 'block':'none'};

&:before {
	content: '';
	position: absolute;
	
	${props=>{
		switch(props.placement) {
			case "bottom":
				return `
width: 0;
height: 0;
border-left: 10px solid transparent;
border-right: 10px solid transparent;
border-bottom: 10px solid white;
left: calc(50% - 10px);
top: -10px;`
			case "top":
			return `
width: 0;
height: 0;
border-left: 10px solid transparent;
border-right: 10px solid transparent;
border-top: 10px solid #eee;
left: calc(50% - 10px);
top: 100%;`
			case "right":
			return `
width: 0;
height: 0;
border-top: 10px solid transparent;
border-right: 10px solid white;
border-bottom: 10px solid transparent;
left: -10px;
top: calc(50% - 10px);`
			case "left":
			return `
width: 0;
height: 0;
border-top: 10px solid transparent;
border-left: 10px solid white;
border-bottom: 10px solid transparent;
left: 100%;
top: calc(50% - 10px);`
		}
	}}
}
`

export default function SimpleTooltip(props) {
	const childRef = useRef(null);
	const tooltipRef = useRef(null)

	const [position, setPosition] = useState({x: 0, y: 0});

	let _children
	if (props.childRef) {
		_children = props.children
	} else {
		_children = React.Children.map(props.children, (child) => {
		    return React.cloneElement(child, {
		        ref: childRef
		    })
		})
	}

	useEffect(()=>{
		if (props.childRef && props.childRef.current && props.childRef.current !== childRef.current) {
			childRef.current = props.childRef.current
		}
	}, [props.childRef, childRef])

	const alignPosition = () => {
		if (childRef.current) {
			const bounds = childRef.current.getBoundingClientRect()
			const ttBounds = tooltipRef.current.getBoundingClientRect()

			let x, y

			switch(props.placement) {
				case "top":
					x = bounds.x + (bounds.width / 2) - (ttBounds.width / 2)
					y = bounds.y - ttBounds.height - ArrowMargin
					break
				case "bottom":
					x = bounds.x + (bounds.width / 2) - (ttBounds.width / 2)
					y = bounds.y + bounds.height + ArrowMargin
					break
				case "right":
					x = bounds.x + bounds.width + ArrowMargin
					y = bounds.y + (bounds.height / 2) - (ttBounds.height / 2)
					break
				case "left":
					x = bounds.x - ttBounds.width - ArrowMargin
					y = bounds.y + (bounds.height / 2) - (ttBounds.height / 2)
					break
			}
			setPosition({x,y})
		}
	}

	useEffect(()=>{
		alignPosition()
	}, [childRef])

	useEffect(()=>{
		console.log("position: " + JSON.stringify(position))
	}, [position])

	return(
		<Popover
	      opened={props.open}
	      placement={props.placement}
	      withArrow
	    >
	    	<TooltipContainer
	    	key={props.passKey}
	    	ref={tooltipRef}
			position={position}
			{...props}>
			<ReactResizeDetector handleWidth handleHeight onResize={()=>alignPosition()}>
			{ props.tooltip }
			</ReactResizeDetector>
			</TooltipContainer>

			<ReactResizeDetector handleWidth handleHeight onResize={()=>alignPosition()}>
			{ _children }
		    </ReactResizeDetector>
	    </Popover>
    )
}