import React from 'react';
import { render } from "react-dom";
import './index.css';
import AppWrapper from './AppWrapper';
import * as serviceWorker from './serviceWorker';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if(process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: "https://87554819c1434870804e8ebcd876f083@o499465.ingest.sentry.io/5578012",
		autoSessionTracking: true,
		integrations: [
			new Integrations.BrowserTracing(),
		],
		tracesSampleRate: 1.0,
	})
}

render(
	<AppWrapper />
,
  document.getElementById("root")
);

serviceWorker.unregister();

