const R = require('ramda');

export function sanitizeFrames(frames) {
	const newFrames = R.clone(frames)
	newFrames.forEach((frame, i)=>{
		if(i == 0){
			frame.t = 0

		}else if(i > 0){
			const lastFrame = newFrames[i-1]
			if(frame.t !== 2 && (lastFrame.t === 0 || lastFrame.t === 1)) {
				frame.t = 1
			} else if(lastFrame.t === 2) {
				frame.t = 0
			}

		}
	})
	return newFrames
}