import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import PickerMenu from '../../PickerMenu'

const Container = styled.div`
border-radius: 8px;
background-color: #1F1F1F;
overflow: hidden;
display: flex;
flex-direction: column;
`

const Header = styled.div`
background-color: #222222;
height: 60px;
padding: 0 20px;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
`

const Title = styled.h4`
font-family: Signika;
font-weight: 700;
font-size: 14px;
color: rgba(255,255,255,0.5);
`

const Content = styled.div`
width: 100%;
`

export default function DashboardItem(props){
	const getPicker = () => {
		if(!props.options) return <span/>
		return(
			<PickerMenu 
			options={props.options} 
			selectedOptionId={props.selectedOptionId}
			onChange={props.onChange}/>
		)
	}

	return(
		<Container>
			<Header>
			{ getPicker() }
			<Title>{ props.title }</Title>
			</Header>
			<Content>
			{ props.children }
			</Content>
		</Container>
	)
}