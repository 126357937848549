import React, { Component } from 'react';
import { deleteTemplate, publishMicrosite } from '../modules/freckle-api.js'
import { withRouter } from 'react-router-dom'
import { logEvent } from '../helpers/analytics.js'
import { CSSTransition } from 'react-transition-group'
import AddTemplate from './AddTemplate'
import ColumnResizer from "react-column-resizer";
import { Tooltip } from '@material-ui/core';
import TemplateItem from './TemplateItem'
import Button from './Button'
import "../../styles/Templates.scss"

import { AppContext, AppAction } from './Context/AppContext'

const moment = require("moment")

export default class Templates extends Component {
	static contextType = AppContext

	constructor(props, context) {
		super(props, context);
		this.state = {
			addingTemplate: false,
			publishingMicrositeId: null,
		}
		this.context.syncTemplates()
	}

	templatesList = () => {
		if(!this.context.state.allStores.length || !this.context.state.selectedStore) {
			return
		}
		// TODO: use TemplateItem's here...after finishing that component
		const store = this.context.state.allStores.find(s => s.domain === this.context.state.selectedStore.domain)
		if(!store) return <span/>
		let baseUrl = "freckle.shop"
		if(store.isDomainLinked) baseUrl = store.domain
		const templates = this.context.state.templates.filter(template => template.shopUrl === this.context.state.selectedStore.domain)
		if(templates.length === 0) {
			console.log("no templates are here")
			return(
				<div className="zero-state">
					<img className="zero-state-icon" src="/no-themes.svg"/>
					<h3>No Landing Pages Yet!</h3>
		  			<p>Create your first landing page.</p>
		  			<Button
		  			ghost
			        onClick={()=>{ this.setState({addingTemplate: true}) }}>
			        CREATE A LANDING PAGE
			        </Button>
				</div>
			)
		}
		const rows = templates.map(template => {
			return(
				<TemplateItem
				baseUrl={baseUrl}
				didSelectItem={()=>{
					this.props.history.push("/page/" + template.id)
				}}
				didSelectPublish={()=>{
					this.publish(template)
				}}
				onDelete={()=>{
					this.deleteTemplate(template.id)
				}}
				publishingMicrositeId={this.state.publishingMicrositeId}
				microsite={template}/>
			)
		})

		return (
			<div className="templates-list">
			{rows}
			</div>
		)
	}

	publish = (microsite) => {
		this.setState({publishingMicrositeId: microsite.id}, async ()=>{
			try {
				const res = await publishMicrosite(microsite.id)
				document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text": `${microsite.title} has been published!`, "type":"success"}}))
			} catch(e) {
				document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text": "Publish failed.", "type":"error"}}))
			}
			this.setState({publishingMicrositeId: null})
			this.context.syncTemplates()
		})
	}

	deleteTemplate = async (id) => {
		await deleteTemplate(id)
		this.context.syncTemplates()
	}

	openTemplate = (e, id) => {
		if(e.target.className !== "action") this.props.history.push("/page/" + id)
	}

	closeOverlay = e => {
		if(e.target.className === "overlay-pane") this.setState({addingTemplate: false})
	}

	render() {
		const { data } = this.state
		const overlayClass = (this.state.addingTemplate) ? "overlay-pane" : "overlay-pane hidden"
		console.log("context: " + this.context.state)
		return (
			<div className="templates-pane">
				<div className={overlayClass} onClick={this.closeOverlay}>
		          <CSSTransition in={this.state.addingTemplate} timeout={500} classNames="add-video" unmountOnExit>
		         	<AddTemplate onCancel={()=>{ 
		         		this.setState({addingTemplate: false})
		         	}} onAdd={()=>{ 
		         		this.setState({addingTemplate: false})
		         		this.context.syncTemplates()
		         	}}/>
		          </CSSTransition>
		        </div>
				<div className="middle-pane">
			        <div className="templates-header">
			          <h2>Landing Pages</h2>
			          <Button 
			          large
			          imgUrl="/new-item.svg"
			          onClick={()=>{this.setState({addingTemplate: true})}}>
			          New Landing Page
			          </Button>
			        </div>
			        { this.templatesList() }
		        </div>
		    </div>			
		)
	}
}
