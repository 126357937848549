import React from 'react';
import MainEditor from './MainEditor'
import { EditorContextProvider } from './Context/EditorContext'

import { AppContext, AppAction, useAppContext } from '../Context/AppContext'

export default function Editor(props){
	const context = useAppContext() 
	return (
		<EditorContextProvider>
		<MainEditor {...props} appContext={context}/>
		</EditorContextProvider>
	)
}