import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import DashboardItem from './DashboardItem'
import { abbreviateNumber } from '../../../helpers/abbreviate'
const Table = styled.table`
width: 100%;
border-collapse:collapse;
`
const Thead = styled.thead`
background-color: #2F2F2F;
height: 30px;
font-weight: 400;
font-size: 14px;
`
const Th = styled.th`
text-align: left;
&:first-child {
  padding-left:20px;
  padding-right:20px;
}
`
const Td = styled.td`
text-align: left;
height: 60px;
width: 5%;
&:first-child {
	padding-left:20px;
	padding-right:0;
}
`

const Trh = styled.tr`
background-color: #2F2F2F;
height: 30px;
font-weight: 400;
font-size: 14px;
`
const Tdh = styled.td`
text-align: left;
height: 30px;
&:first-child {
  padding-left:20px;
  padding-right:0;
}
`

const Tbody = styled.tbody`

`
const Tr = styled.tr`
&:nth-child(odd){
  background-color: #252525;
}
&:nth-child(even){
  background-color: #1F1F1F;
}
`

const H4 = styled.h4`
width: 100%;
text-align: center;
font-weight: 400;
font-size: 14px;
opacity: 0.2;
`

export default function MicrositeLeaderboardItem(props){

	const getTable = () => {
		return(
		<Table>
			<Trh>
				<Tdh>Site Name</Tdh>
				<Tdh>Views</Tdh>
				<Tdh>Cart Adds</Tdh>
				<Tdh>Checkouts</Tdh>
				<Tdh>Conversion</Tdh>
				<Tdh>Average Cart Value</Tdh>
			</Trh>
		{
			props.microsites.map(site=>{
				return(
					<Tr>
						<Td>{ site.title }</Td>
						<Td>{ site.view }</Td>
						<Td>{ site.add_to_cart }</Td>
						<Td>{ site.checkout }</Td>
						<Td>{ (100*parseFloat(site.checkout)/parseFloat(site.view)).toFixed(1) }%</Td>
						<Td>${ !!site.avg_cart_value ? site.avg_cart_value.toFixed(2) : " N/A" }</Td>
					</Tr>
				)
			})
		}
		</Table>
		)
	}

	return(
		<DashboardItem
		title={props.title}>
		{ getTable() }
		{
			!!!props.microsites.length && <H4>No events have been recorded for this account.</H4>
		}
		</DashboardItem>
	)
}