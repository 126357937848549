import React, { useState, useEffect } from 'react';
import Help from '../../Help'
import { FreckleTooltip } from '../../FreckleTooltip'
import searchWorker from '../../../workers/search.worker'
import styled from 'styled-components'

import { AppContext, AppAction, useAppContext } from '../../Context/AppContext'

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

const SCROLL_BATCH_SIZE = 50

const Container = styled.div`
	background-color: #222222;
	width: 300px;
	flex-shrink: 0;
	height: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const Header = styled.div`
	width: 100%;
	height: 80px;
	background-color: #1E1E1E;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-bottom: 1px solid #252525;
`

const H3 = styled.h3`
	padding:0;
	margin: 0;
	display:flex;
	align-items:center;
	padding-left: 25px;
	color: white;
	margin: 0;
	margin-top:15px;
	font-size: 1em;
	font-weight: 700;
	user-select: none;
`

const Input = styled.input`
	background-color: transparent;
	outline: 0;
	border: 0;
	color: white;
	padding: 10px;
	margin: 0;
	font-family: "Signika";
	font-size: 13px;
	margin-left:25px;
	height:35px;

	background: url(/search-icon.svg);
	background-repeat: no-repeat;
	background-size: 10px;
	background-position: center left;
	padding-left:20px;
`

const List = styled.div`
	height: 100%;
	width: 100%;
	overflow: scroll;
	overflow-x: hidden;
	padding-top:5px;
`

const ProductItem = styled.div`
	height: 45px;
	line-height: 20px;
	text-align: left;
	width: calc(300px - 35px);
	color: white;
	font-size: 13px;
	line-height: 22px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px 15px;
	padding-left:20px;
	user-select: none;
	cursor: pointer;
	border-bottom: 2px solid transparent;
	overflow: hidden;
	transition: 0.2s;

	pointer-events: ${props=> props.selected ? 'none':'auto'};
	opacity: ${props=> props.selected ? '0.5':'1'};

	&:hover{
		background-color: rgba(255,255,255,0.025);
	}
`

const ProductItemImg = styled.img`
	height: 35px;
	width: 35px;
	object-fit: cover;
	background-color: white;
	border-radius: 4px;
	margin-right: 15px;
`

const Label = styled.label`
	padding-right: 30px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	pointer-events: none;
`

export default function ProductsPane(props) {
	const context = useAppContext()
	const [filterString, setFilterString] = useState("")
	const [itemDisplayLimit, setItemDisplayLimit] = useState(SCROLL_BATCH_SIZE)
	const [searchResults, setSearchResults] = useState([])

	const [searchWorkerConfigured, setSearchWorkerConfigured] = useState(false)
	const [searchWorkerInstance, setSearchWorkerInstance] = useState(null)

	useEffect(()=>{
		let _searchWorkerInstance = new searchWorker()
		if(!!context.state.products.length){ 
			_searchWorkerInstance.postMessage({type: 'populate', list: context.state.products})
			setSearchWorkerConfigured(true)
		}
		_searchWorkerInstance.addEventListener('message', (e) => {
			if(e.data.type === "searchResults") {
				const results = e.data.data.map(item=>item.item)
				setSearchResults(results)
			}
	    })

	    setSearchWorkerInstance(_searchWorkerInstance)

		return ()=>{
			_searchWorkerInstance.terminate()
		}
	}, [])

	useEffect(()=>{
		if(!searchWorkerConfigured){
			if(!!context.state.products.length && !!searchWorkerInstance) {
				searchWorkerInstance.postMessage({type: 'populate', list: context.state.products})
				setSearchWorkerConfigured(true)
			}
		}
	})

	const getProductsList = () => {
		let allProducts
		if(filterString.length > 0){
			allProducts = searchResults
		} else {
			allProducts = Object.values(context.state.products).slice(0, itemDisplayLimit)
		}

		const selectedProductIds = props.selectedProducts.map(product=> product.id)

		return allProducts.map((product, i)=>{
			var imageURL = null
			if(product.imageUrl.length > 0){
				imageURL = product.imageUrl
			}

			return(
				<FreckleTooltip title={"Click to add " + product.title} arrow placement="right">
				<ProductItem 
				selected={selectedProductIds.includes(product.id)}
                key={product.id}
                id={product.id}
                onDoubleClick={()=>{}}
                onClick={()=>{
					props.onSelectProduct(product)
				}}>
					<ProductItemImg src={imageURL}/>
					<Label>{ product.title }</Label>
				</ProductItem>
				</FreckleTooltip>
			)
		})
	}

	const searchChanged = (e) => {
		setFilterString(e.target.value)
		searchWorkerInstance.postMessage({type: 'search', searchString: e.target.value})
	}

  const handleScroll = (e) => {
  	const obj = e.target
  	if( obj.scrollTop === (obj.scrollHeight - obj.offsetHeight)){
  		const newLimit = Math.min(itemDisplayLimit + SCROLL_BATCH_SIZE, context.state.products.length)
  		setItemDisplayLimit(newLimit)
  	}
  }

  return(
  	<Container>
  		<Header>
  			<H3>Products</H3>
  			<Input placeholder="Search Products" onChange={searchChanged}/>
  		</Header>
  		<List onScroll={handleScroll}>
  		{ getProductsList() }
  		</List>
  	</Container>
  )

}
