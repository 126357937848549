import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { verifyAccount } from '../modules/freckle-api'
import { BeatLoader } from "react-spinners";
import Button from './Button'

const STATE = {
	waiting: 0,
	success: 1,
	failed: 2
}

const Container = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	font-family: "Signika";
`

const H3 = styled.h3`
	padding: 20px;
	margin: 0;
	color: white;
	font-size: 35px;
`

const P = styled.p`
	padding: 0;
	padding-bottom: 30px;
	margin: 0;
	color: white;
	font-size: 18px;
`

const Icon = styled.img`
	height: 40px;
`

export default function VerifyResult(props) {
	const [state, setState] = useState(STATE.waiting)

	useEffect(()=>{
		verifyAccount(props.match.params.email, props.match.params.code).then(()=>{
			setState(STATE.success)	
		}).catch((e)=>{
			setState(STATE.failed)
		})
	}, [])

	const getStatus = () => {
		switch(state){
			case STATE.waiting:
				return(
					<BeatLoader size="5px" color="white"/>
				)
			case STATE.success:
				return (
					<React.Fragment>
					<Icon src="/done.svg"/>
					<H3>Success!</H3>
					<P>Yeehaw!  Your Freckle account has been activated.</P>
					<Button
					onClick={()=>{
						window.location.href = "/login"
					}}>SIGN IN</Button>
					</React.Fragment>
				)
			case STATE.failed:
				return (
					<React.Fragment>
					<H3>Uh oh...</H3>
					<P>We were not able to activate your account.  Contact hello@freckle.shop.</P>
					</React.Fragment>
				)
		}
	}

	return(
		<Container>
		{ getStatus() }
		</Container>
	)
}