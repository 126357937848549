import React, { Component } from 'react'
import { FreckleTooltip } from './FreckleTooltip'
import '../../styles/Help.scss'

export default class Help extends Component {
	render(){
		return(
			<FreckleTooltip {...this.props}>
			<div className="help-widget">?</div>
			</FreckleTooltip>
		)
	}
}