import React, { Component } from 'react'
import styled, { css, keyframes } from 'styled-components'

var moment = require('moment'); 

const Container = styled.div`
	color: white;
	font-size: 12px;
	text-align: left;
	height: 35px;
	line-height: 35px;
	cursor: pointer;
	transition: 0.15s;

	font-size: 13px;

	padding-left: 5px;

	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;

	position: relative;
	user-select: none;

	overflow: visible;

	transition: 0.15s;
`

const ClickArea = styled.div`
	display: block;
	height: 10px;
	width: 10px;
	cursor: pointer;
	transform: scale(1.0);
	transition: 0.2s;
	z-index: 11;
	pointer-events: none;
	border-radius: 5px;

	background-color: ${props=>{
		switch(props.type){
			case "start":
				return '#7ED321'
			case "tween":
				return 'white'
			case "end":
				return '#F74743'
		}
	}};
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: row;
	width: auto;
	margin-left: auto;
	transition: 0.15s opacity;
`

const Action = styled.div`
	opacity: 0.5;
	transition: 0.15s opacity;
	margin-right: 5px;
	height:25px;
	line-height: 25px;
	border-radius: 12.5px;
	padding: 0 10px;
	font-size: 11px;
	font-weight: bold;
	transition: 0.2s;
	background-color: transparent;

	display: flex;
	align-items: center;

	box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
`

const TopLine = styled.div`
	position: absolute;
	left: 9px;
	top: 0;
	height:50%;
	width: 2px;
	background-color: #353535;
	z-index: 10;
	pointer-events: none;
	display: ${props=> props.display?"block":"none"};
`

const BottomLine = styled.div`
	position: absolute;
	left: 9px;
	top: 50%;
	height:50%;
	width: 2px;
	background-color: #353535;
	z-index: 10;
	pointer-events: none;

	display: ${props=> props.display?"block":"none"};
`

const Timestamp = styled.div`
	margin-left:15px;
	pointer-events: none;
`

const PathButton = styled.div`
	font-family: "Signika";
	font-weight: bold;
	font-size: 12px;
	outline: none;
	border: none;
	background-color: ${props=> props.closed ? '#4A90E2' : '#F74743'};
	border: 2px solid transparent;
	border-radius: 3px;
	color: white;
	cursor: pointer;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 18px;
	transition: 0.15s;
	margin-right: 10px;
`

export default class TimelineItem extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			hovering: false
		}
	}

	clickedDelete = (e) => {
		console.log(e.target.className)
		this.props.delete(this.props.loc)
	}

	didSelect = (e) => {
		if(!!e.target.getAttribute('aria-timelineitem')) {
			this.props.selected(this.props.loc)
		}
	}

	markAsEnd = (e) => {
		this.props.updateMark(this.props.loc)
	}

	render() {
		const loc = this.props.loc
		var sectionType
		var showsTop, showsBottom
		switch(loc.t){
			case 0:
				sectionType = "start"
				showsTop = false
				showsBottom = true
				break;
			case 1:
				sectionType = "tween"
				showsTop = true
				showsBottom = true
				break;
			case 2:
				sectionType = "end"
				showsTop = true
				showsBottom = false
				break;
			default:
				break;
		}
		const dateString = moment((new Date).clearTime().addSeconds(loc.ts)).format("mm:ss.SS");

		return (
			<Container 
			aria-timelineitem
			onMouseOver={()=>{this.setState({hovering: true})}} onMouseOut={()=>{this.setState({hovering: false})}} onClick={this.didSelect}>
				<ClickArea type={sectionType}/>

				<TopLine display={showsTop}/>
				<BottomLine display={showsBottom}/>

				<Timestamp>{ dateString }</Timestamp>

				<ButtonContainer style={{opacity: this.state.hovering?1:0}}>
					{(this.props.loc.t !== 0) && (<PathButton closed={(this.props.loc.t !== 2)} onClick={this.markAsEnd}>
						{(this.props.loc.t === 2) ? "OPEN PATH" : "CLOSE PATH"}
					</PathButton>)}
					<Action onClick={this.clickedDelete}>
					<img className="trash-icon" src="/trash-icon.svg"/>
					</Action>
				</ButtonContainer>
			</Container>	
		)
	}
}


