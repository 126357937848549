import styled from 'styled-components';
import React from 'react'

const Container = styled.div`
transform: ${props=> props.small ? 'scale(0.75)':'scale(1)'};
height: 20px;
width: 45px;
border-radius: 1000px;
border: 3px solid ${props=> props.enabled ? '#4da0ff' : '#2f2f2f'};
background-color: ${props=> props.enabled ? '#4da0ff' : '#2f2f2f'};
cursor: pointer;
position: relative;
transition: 0.25s;
box-shadow: ${props=> props.enabled ? '#4da0ff 0px 0px 20px -5px' : 'none'};
`

const Knob = styled.div`
height: 20px;
width: 20px;
border-radius: 1000px;
background-color: white;
transition: 0.25s;
pointer-events: none;
position: absolute;
top: 0;
left: ${props=> props.enabled ? 'calc(100% - 20px)' : '0px'};
box-shadow: ${props=> props.enabled ? '4da0ff 0px 0px 10px -5px' : 'none'};
`

export default function Switch(props) {
	return (
		<Container
		{...props}
		onClick={()=> props.onChange(!props.enabled)}>
		<Knob
		{...props}/>
		</Container>
	)
}