import React, { useState, useEffect, useRef, useCallback } from 'react';

import { Tooltip } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { FreckleTooltip } from '../FreckleTooltip'

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ResizePanel from "react-resize-panel"

import { logEvent } from '../../helpers/analytics.js'

import { useControlPress } from '../../helpers/useKeyPress'

import { Rnd } from 'react-rnd'
import { Resizable } from "re-resizable";

import Button from '../Button'

import Selecto from "react-selecto";

import styled from 'styled-components'

import { EditorContext, ContextAction, useEditorContext } from './Context/EditorContext'
import { useAppContext, AppAction } from '../Context/AppContext'

import "../../../styles/input.css"
import "../../../styles/Stage.scss"

import TutorialTooltip from '../tutorial/TutorialTooltip'
import { EditorTutorialState } from '../tutorial/tutorial'

let moment = require('moment')

const R = require('ramda');

const RangeBoxViz = styled.div`
background-color: ${props=> props.selected ? '#4da0ff' : '#5f5f5f'};
border-radius: 4px;
width: 100%;
height: 30px;
position: relative;
border: 1px solid ${props=> props.selected ? '#4da0ff' : '#5f5f5f'};
box-shadow: ${props=> props.selected ? '0px 0px 20px -5px #3781D8' : 'none'};
pointer-events: none;
margin-top: -5px;

&:before {
	content: '';
	position: absolute;
	top: 5px;
	left: 2.5px;
	width: 2px;
	height: 20px;
	background-color: ${props=> props.selected ? '#153d6a' : '#2f2f2f'};
	border-radius: 100px;
}

&:after {
	content: '';
	position: absolute;
	top: 5px;
	right: 3px;
	width: 2px;
	height: 20px;
	background-color: ${props=> props.selected ? '#153d6a' : '#2f2f2f'};
	border-radius: 100px;
}
`

const RangeBoxTooltipContainer = styled.div`
background-color: transparent;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 10px;
text-align: center;

b {
	padding: none;
	margin: none;
	text-align: center;
}

span {
	padding: none;
	margin: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.line {
	width: 1px;
	height: 30px;
	background-color: #dbdbdb;
}
`

function RangeBoxTooltip(props) {
	return (
		<RangeBoxTooltipContainer>
			<span>
			<b>Tag Start</b> 
			<span>{moment((new Date).clearTime().addSeconds(props.inTime)).format('mm:ss.SS')}</span>
			</span>

			<div className="line"/>

			<span>
			<b>Tag End</b>
			<span>{moment((new Date).clearTime().addSeconds(props.outTime)).format('mm:ss.SS')}</span>
			</span>
		</RangeBoxTooltipContainer>
	)
}

function RangeBox(props) {
	const [hovering, setHovering] = useState(false)
	const [_,_forceReload] = useState(null)
	const forceReload = () => _forceReload(Math.random())
	const [currentRange, setCurrentRange] = useState(props.range)

	useEffect(()=>{
		setCurrentRange(props.range)
	}, [props.range])

	return (
		<Rnd
			data-selectable="true"
			data-type="range"
			data-groupid={props.group.id}
			data-rid={props.range.id}
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		  default={{
		    x: props.x,
		    y: 0,
		    width: props.width,
		    height: 60,
		  }}
		  bounds="parent"
		  dragAxis="both"
		  enableResizing={{left: true, right: true}}
		  onDragStart={()=>{
		  	props.onDragStart()
		  }}
		  onDrag={(e, d)=>{
		  	const newStart = (d.x / props.stageWidth) * props.duration
		  	setCurrentRange({
  				...props.range,
  				end: props.range.end - props.range.start + newStart,
  				start: newStart
  			})
		  }}
		  onResize={(e, direction, ref, delta, position)=>{
		  	const secAdjust = (delta.width / props.stageWidth) * props.duration
		  	switch(direction) {
		  		case "right":
		  			setCurrentRange({
		  				...props.range,
		  				end: props.range.end + secAdjust
		  			})
		  			break
		  		case "left":
		  			setCurrentRange({
		  				...props.range,
		  				start: props.range.start - secAdjust
		  			})
		  			break
		  	}
		  }}
		  onDragStop={(e, d)=> {
		  	props.onDragEnd(d.x)
		  }}
		  onResizeStart={()=>{
		  	props.onResizeStart()
		  }}
		  onResizeStop={(e, direction, ref, delta, position)=>{
		  	const rect = ref.getBoundingClientRect()
		  	const newEndX = rect.width + position.x
		  	props.onResizeEnd(position.x, newEndX)
		  }}
		  onMouseOver={()=>{
		  	setHovering(true)
		  }}
		  onMouseOut={()=>{
		  	setHovering(false)
		  }}
		>
			<FreckleTooltip
			placement="top" 
			arrow 
			open={hovering} 
			title={
				<RangeBoxTooltip
				inTime={currentRange.start}
				outTime={currentRange.end}/>
			}>
		  <RangeBoxViz selected={props.selected}/>
		  </FreckleTooltip>
		</Rnd>
	)
}

const RangePanelContainer = styled.div`
height: 55px;
display: flex;
justify-content: flex-start;
align-items: center;
border-bottom: 2px solid transparent;
position: relative;
`

const NewRangeIndicator = styled.div`
position: absolute;
top: 0;
left: 0;
height: 55px;
width: 2px;
background-color: #19F4FF;
pointer-events: none;
border-radius: 1000px;
display: ${props=>props.visible ? 'block':'none'};

&::before {
	content: '+';
	line-height: 20px;
	border-radius: 1000px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "arial";
	font-size: 20px;
	color: #19F4FF;
	font-weight: 700;
	height: 20px;
	width: 20px;
	background-color: #1f1f1f;
	border: 2px solid #19F4FF;
	position: absolute;
	top: calc(50% - 10px);
	left: -11px;
}
`

function RangePanel(props){
	const [hovering, setHovering] = useState(false)
	const indicatorRef = useRef(null)
	const containerRef = useRef(null)
	return(
		<RangePanelContainer
		ref={containerRef}
		onMouseOver={(e)=>{
			const _hovering = e.target === containerRef.current
			setHovering(_hovering)
			if(_hovering){
				let rect = containerRef.current.getBoundingClientRect();
				let x = e.clientX - rect.left - 1;
				indicatorRef.current.style.transform = `translateX(${x}px)`
			}
		}}
		onMouseOut={(e)=>{
			setHovering(false)
		}}
		onMouseMove={(e)=>{
			if(hovering) {
				let rect = containerRef.current.getBoundingClientRect();
				let x = e.clientX - rect.left - 1;
				indicatorRef.current.style.transform = `translateX(${x}px)`
			}
		}}
		onClick={(e)=>{
			if (hovering && !props.disableAdd) {
				let rect = containerRef.current.getBoundingClientRect();
				let x = e.clientX - rect.left - 1;
				props.onAdd(x)
			}
		}}>
		<NewRangeIndicator
		visible={hovering && !props.disableAdd} 
		ref={indicatorRef}/>
		{ props.children }
		</RangePanelContainer>
	)
}

function MenuToolbar(props) {
	const context = useEditorContext()
	const appContext = useAppContext()

	let buttons = []

	const group = context.state.groups.find(g=> g.id === context.state.selectedGroupId)

	const [_, _forceReload] = useState(null)
	const forceReload = () => _forceReload(Math.random)

	useEffect(()=>{
		if(props.videoNode) {
			forceReload()
		}
	}, [props.videoNode, props.duration])

	const closeCurrentPath = () => {
		if (!props.videoNode) return
		if(context.state.selectedGroupId != null){
			const ts = props.videoNode.currentTime
			const locs = R.clone(context.state.groups.find((group)=>{ return context.state.selectedGroupId === group.id }).locations)
			.filter((loc)=>{ return loc.ts <= ts })
			if(!!locs.length){
				const lastLoc = R.clone(locs[locs.length-1])
				lastLoc.ts = ts
				lastLoc.t = 2
				context.dispatch({type: ContextAction.addKeyframe, payload: lastLoc})
			}
		}
	}

	let mayClosePath = false
	if (props.videoNode &&  props.videoNode.paused && context.state.selectedGroupId) {
		const locs = context.state.groups
		.find((group)=>{ return context.state.selectedGroupId === group.id }).locations
		.filter((loc)=> loc.ts <= props.videoNode.currentTime)

		// if there are no previous keyframes, disabled=true
		if(!!locs.length) {
			// if the last location exists, and is not an endpoint, disabled=false
			const lastLoc = locs[locs.length - 1]
			switch(lastLoc.t) {
				case 0:
					if(lastLoc.ts == props.videoNode.currentTime){ 
						mayClosePath = false
					} else {
						mayClosePath = true
					}
					break
				case 1:
					mayClosePath = true
					break
				case 2:
					mayClosePath = false
					break
			}
		} else {
			mayClosePath = false
		}
	}

	// undo and redo
	buttons.push(
		<Button 
		key="undo"
		small
		disabled={!context.state.groupsPast.length}
		onClick={()=> context.dispatch({ type: ContextAction.undoAction }) }>
		Undo
		</Button>
	)
	buttons.push(
		<Button 
		key="redo"
		small 
		disabled={!context.state.groupsFuture.length}
		onClick={()=> context.dispatch({ type: ContextAction.redoAction }) }>
		Redo
		</Button>
	)

	if (group && group.itemType == 'hotspot') {
		buttons.push(
			<Button 
			key="closepath"
			small 
			disabled={!mayClosePath}
			onClick={closeCurrentPath}>
			Close Path
			</Button>
		)
	}

	if (group && group.itemType == 'list' && props.videoNode) {
		buttons.push(
			<Button 
			key="addrange"
			small 
			disabled={!props.videoNode.paused}
			onClick={()=>{
				context.dispatch({ type: ContextAction.addRange, payload: { start: props.videoNode.currentTime, end: props.videoNode.currentTime + 1 }})	
				if (appContext.state.editorTutorialState == EditorTutorialState.addSegment) {
      		appContext.dispatch({ type: AppAction.setEditorTutorialState, payload: EditorTutorialState.editSegment });
      	}
			}}>
			Add Tag
			</Button> 
		)
	}

	// delete selected
	if (props.hasSelections) {
		buttons.push(
			<Button 
			key="deleteseleections"
			small 
			backgroundColor="red"
			onClick={props.onDeleteSelection}>
			Delete Selection
			</Button>
		)
	}

	return (
		<div className="menu-toolbar">
				
			<div className="left">
				{ buttons }
			</div>

			<div className="right">
				<FreckleTooltip arrow placement="top" title="Drag to adjust stage timescale.">
					<input className="range" type="range" min="15" max="100" defaultValue="50" onChange={props.onUpdateScale}/>
				</FreckleTooltip>
			</div>
		</div>
	)
}

const SearchInput = styled.input`
width: 100%;
height: calc(100% - 3px);
border: none;
outline: none;
background-color: transparent;
color: white;
padding-left: 20px;
font-family: "Signika";
font-size: 14px;

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255,255,255,0.2);
}
`

export default function Stage(props){
	const context = useEditorContext()
	const appContext = useAppContext()

	const animationFrameRequestId = useRef(null)

	const timelineRef = useRef(null)
	const namesRef = useRef(null)
	const focusedScrollSection = useRef(null)

	const timelineContainerRef = useRef(null)
	const playheadRef = useRef(null)

	const [scale, setScale] = useState(0.5)
	const [tickWidth, setTickWidth] = useState(75)
	const [scrubbing, setScrubbing] = useState(false)

	const [movingRange, setMovingRange] = useState(false)

	const [selectedKeyframes, setSelectedKeyframes] = useState(new Set())
	const [selectedRanges, setSelectedRanges] = useState(new Set())

	const isControlPressed = useControlPress()

	const [filterString, setFilterString] = useState("")

	const bookendScrollTimerRef = useRef(null)


	const alignPlayhead = () => {
		if(!!props.videoNode){
			const playheadTime = props.videoNode.currentTime
			const timeline = timelineContainerRef.current
			const playhead = playheadRef.current

			if(timeline && playhead){
				const viewWidth = timeline.getBoundingClientRect().width
				const playheadX = playheadTime * tickWidth

				if(playheadX > timeline.scrollLeft + viewWidth && !props.videoNode.paused) {
					timeline.scrollLeft = timeline.scrollLeft + viewWidth
				}

				const xPos = playheadTime * tickWidth
				playheadRef.current.style.left = xPos+"px"
			}
		}

		animationFrameRequestId.current = requestAnimationFrame(alignPlayhead)
	}

	const deleteSelected = () => {
		const kfdeletes = Array.from(selectedKeyframes).map(kf=>{
			const comps = kf.split(":")
			return {
				groupid: comps[0],
				id: comps[1]
			}
		})

		const rangedeletes = Array.from(selectedRanges).map(rangeData=>{
			const comps = rangeData.split(":")
			return {
				groupid: comps[0],
				id: comps[1]
			}
		})

		deleteItems(kfdeletes, rangedeletes)
	}

	const onKeyPress = (e)=>{
		if(document.activeElement.tagName === "INPUT" && document.activeElement.type === "text") return
		if(!e.metaKey && e.code === 'Backspace') {
			deleteSelected()
		}
		if (e.code == "Enter") {
			timelineRef.current.parentElement.scrollLeft = 0
		}
	}
	useEffect(()=>{
		document.addEventListener('keydown', onKeyPress)

		return ()=> document.removeEventListener('keydown', onKeyPress)
	}, [selectedKeyframes, selectedRanges])

	useEffect(()=>{
		snapToPlayhead()
	}, [scale, tickWidth])

	useEffect(()=>{
		animationFrameRequestId.current = requestAnimationFrame(alignPlayhead)

		return ()=>{
			cancelAnimationFrame(animationFrameRequestId.current)
		}
	})

	useEffect(()=>{
		const handleMouseUp = ()=>{
			if (scrubbing) {
				endScrub()
			}
		}

		document.addEventListener('mouseup', 
		handleMouseUp, 
		false)

		const handleMouseMove = (e)=>{
			if (scrubbing) {
				scrub(e)
			}
		}

		document.addEventListener('mousemove', 
		handleMouseMove, 
		false)

		return ()=>{
			document.removeEventListener('mouseup', 
			handleMouseUp,
			false)

			document.removeEventListener('mousemove', 
			handleMouseMove,
			false)
		}
	}, [scrubbing])

	const getTicks = () => {
		const numTicks = Math.max(Math.ceil(props.duration), 40)
		var ticks = []
		const showsSubticks = scale > 0.25
		const subtickClass = showsSubticks ? "subtick" : "subtick hidden"
		const subsubtickClass = "subsubtick hidden"//(state.scale > 0.6) ? "subsubtick" : "subsubtick hidden"
		for(let i=0; i<numTicks; i++){
			var labelText = (new Date).clearTime().addSeconds(i).toString('mm:ss')
			if(scale < 0.3 && (i % 2 == 0) || i == 0){
				labelText = ""
			}
			ticks.push(
				<div key={"tick:"+i} className="tick" style={{minWidth: (tickWidth-1) + "px", width: (tickWidth-1) + "px"}}>
					<label>{ labelText }</label>
					<div className={subtickClass}></div>
					<div className={subtickClass}></div>
					<div className={subtickClass}></div>
				</div>
			)
		}
		return(
			<div className="ticks-container" onMouseDown={clickedTicks}>
				{ ticks }
			</div>
		)
	}

	const getGroupPanels = () => {
		// assumes a tick is a second
		const stageWidth = props.duration*tickWidth

		if (!props.duration) return <span/>

		const panels = context.state.groups.filter(group=> filterString.length==0 || group.title.toLowerCase().includes(filterString.toLowerCase())).map((group)=>{
			let sections = []
			
			if (group.itemType == "list") {
				// if we're dealing with a list-only product
				sections = group.ranges.map(range=>{
					const x = stageWidth * (range.start / props.duration)
					const width = stageWidth * ((range.end - range.start) / props.duration)
					const selected = selectedRanges.has(`${group.id}:${range.id}`)

					return <RangeBox 
					duration={props.duration}
					stageWidth={stageWidth}
					selected={selected}
					range={range}
					key={range.id + scale}
					group={group}
					x={x} 
					width={width}
					onDragStart={()=>{
						setMovingRange(true)
						if (context.state.selectedGroupId !== group.id) {
							context.dispatch({type: ContextAction.setSelectedGroup, payload: group.id})
						}
					}}
					onDragEnd={newX=>{
						setMovingRange(false)
						const newStartTime = props.duration * (newX / stageWidth)
						const newEndTime = props.duration * ((newX + width) / stageWidth)
						context.dispatch({ type: ContextAction.updateRange, payload: { rangeId: range.id, rangeUpdates: { start: newStartTime, end: newEndTime }}})

						if (appContext.state.editorTutorialState == EditorTutorialState.editSegment) {
		      		appContext.dispatch({ type: AppAction.setEditorTutorialState, payload: EditorTutorialState.addSegmentHotkey });
		      	}
					}}
					onResizeStart={()=>{
						setMovingRange(true)
						if (context.state.selectedGroupId !== group.id) {
							context.dispatch({type: ContextAction.setSelectedGroup, payload: group.id})
						}
					}}
					onResizeEnd={(newStartX, newEndX)=>{
						setMovingRange(false)
						const newStartTime = props.duration * (newStartX / stageWidth)
						const newEndTime = props.duration * (newEndX / stageWidth)
						context.dispatch({ type: ContextAction.updateRange, payload: { rangeId: range.id, rangeUpdates: { start: newStartTime, end: newEndTime }}})	

						if (appContext.state.editorTutorialState == EditorTutorialState.editSegment) {
		      		appContext.dispatch({ type: AppAction.setEditorTutorialState, payload: EditorTutorialState.addSegmentHotkey });
		      	}
					}}/>
				})

				return (
					<RangePanel 
					key={"panel:"+group.id}
					disableAdd={movingRange || !isControlPressed}
					onAdd={x=>{
						const newStartTime = props.duration * (x / stageWidth)
						context.dispatch({type: ContextAction.setSelectedGroup, payload: group.id})
						context.dispatch({ type: ContextAction.addRange, payload: { start: newStartTime, end: newStartTime + 1 }})	
					}}>
					{ sections }
					</RangePanel>
				)
			
			} else {
				// if we're dealing with a product hotspot
				for(let i=0; i<group.locations.length; i++) {
					const loc = group.locations[i]
					const x = (loc.ts / props.duration) * stageWidth
					if(i===0) {
						let kfClass = "click-area start"
						if(selectedKeyframes.has(`${group.id}:${loc.id}`)) {
							kfClass += " selected"
						}
						sections.push(
							<div key={"section:"+loc.ts} className="section start" style={{left: (x+5) + "px"}}>
								<FreckleTooltip interactive placement="top" arrow title={
									<div className="keyframe-tooltip">
									<div style={{display:'flex', justifyContent: 'space-between'}}>
										<div className="keyframe-tooltip">
											<div style={{fontWeight:'bold'}}>
												Path Start
											</div>
											<div className="keyframe-tooltip-time">
												{ moment((new Date).clearTime().addSeconds(loc.ts)).format('mm:ss.SS') }
											</div>
										</div>
										<button 
										className="delete-keyframe-button"
										onClick={()=>{
											deleteFreckles([{groupid: group.id, id: loc.id}])
										}}>
										<img src="/trash-icon-black.svg"/>
										</button>
									</div>
									</div>
								}>
									<div
									data-selectable="true"
									data-groupid={group.id}
									data-kfid={loc.id}
									data-type="keyframe"
									onClick={()=>{ 
										props.videoNode.currentTime = loc.ts
									}} className={kfClass}/>
								</FreckleTooltip>
							</div>
						)
					} else {
						const lastLoc = group.locations[i-1]
						var lastX = (lastLoc.ts / props.duration) * stageWidth+5
						var width = ((loc.ts - lastLoc.ts) / props.duration) * stageWidth  

						var sectionType
						let sectionDesc
						switch(loc.t){
							case 0:
								sectionType = "start"
								sectionDesc = (
									<div className="keyframe-tooltip">
									<div style={{display:'flex', justifyContent: 'space-between'}}>
										<div className="keyframe-tooltip">
											<div style={{fontWeight:'bold'}}>
												Path Start
											</div>
											<div className="keyframe-tooltip-time">
												{ moment((new Date).clearTime().addSeconds(loc.ts)).format('mm:ss.SS') }
											</div>
										</div>
										<button 
										className="delete-keyframe-button"
										onClick={()=>{
											deleteFreckles([{groupid: group.id, id: loc.id}])
										}}>
										<img src="/trash-icon-black.svg"/>
										</button>
									</div>
									</div>
								)
								lastX += width
								width = 0
								break;
							case 1:
								sectionDesc = (
									<div className="keyframe-tooltip">
										<div style={{display:'flex', justifyContent: 'space-between'}}>
											<div className="keyframe-tooltip">
												<div style={{fontWeight:'bold'}}>
													Midpoint
												</div>
												<div className="keyframe-tooltip-time">
													{ moment((new Date).clearTime().addSeconds(loc.ts)).format('mm:ss.SS') }
												</div>
											</div>
											<button 
											className="delete-keyframe-button"
											onClick={()=>{
												deleteFreckles([{groupid: group.id, id: loc.id}])
											}}>
											<img src="/trash-icon-black.svg"/>
											</button>
										</div>
									<button 
									title="Close Path" 
									className="path-button"
									onClick={()=>{
										context.dispatch({type: ContextAction.updateKeyframe, payload: {id: loc.id, updates: {t: 2}}})
									}}>
										CLOSE PATH
									</button>
									</div>
								)
								sectionType = "tween"
								break;
							case 2:
								sectionDesc = (
									<div className="keyframe-tooltip">
									<div style={{display:'flex', justifyContent: 'space-between'}}>
										<div className="keyframe-tooltip">
											<div style={{fontWeight:'bold'}}>
												Path End
											</div>
											<div className="keyframe-tooltip-time">
												{ moment((new Date).clearTime().addSeconds(loc.ts)).format('mm:ss.SS') }
											</div>
										</div>
										<button 
										className="delete-keyframe-button"
										onClick={()=>{
											deleteFreckles([{groupid: group.id, id: loc.id}])
										}}>
										<img src="/trash-icon-black.svg"/>
										</button>
									</div>
									<button 
									title="Open Path" 
									className="path-button closed"
									onClick={()=>{
										context.dispatch({type: ContextAction.updateKeyframe, payload: {id: loc.id, updates: {t: 1}}})
									}}>
										OPEN PATH
									</button>
									</div>
								)
								sectionType = "end"
								break;
							default:
								break;
						}

						const sectionClass = "section " + sectionType
						let kfClass = "click-area " + sectionType
						if(selectedKeyframes.has(`${group.id}:${loc.id}`)) {
							kfClass += " selected"
						}
						sections.push(
							<div key={"section:"+loc.ts} className={sectionClass} onClick={()=>{ 
								context.dispatch({type: ContextAction.setSelectedGroup, payload: group.id})
							}} style={{left: lastX + "px", width: width + "px"}}>
								<FreckleTooltip interactive placement="top" arrow title={sectionDesc}>
									<div 
									data-selectable="true"
									data-groupid={group.id}
									data-kfid={loc.id}
									data-type="keyframe"
									onClick={()=>{ 
										props.videoNode.currentTime = loc.ts
									}} className={kfClass}/>
								</FreckleTooltip>
							</div>
						)
					}
				}

				const panelClass = (group.id === context.state.selectedGroupId) ? "panel selected" : "panel"
				return (
					<div key={"panel:"+group.id} className={panelClass}>
					{ sections }
					</div>
				)

			}
		})

		return (
			<div 
			className="group-panels-container">
			{ panels }
			</div>
		)
	}

	const getGroupNamesPanels = () => {
		let panels
		if(!!context.state.groups.length){
			panels = context.state.groups.filter(group=> filterString.length==0 || group.title.toLowerCase().includes(filterString.toLowerCase())).map((group, index)=>{
				const panelClass = (context.state.selectedGroupId === group.id) ? "name-panel selected" : "name-panel"
				// TODO: memoize
				const product = props.appContext.state.products.find((prod)=>prod.id === group.id)
				var prodName = null, prodImgUrl = null

				if(product != null) {
					prodName = product.title
					prodImgUrl = product.imageUrl
				}

				return(
					<Draggable key={group.id} draggableId={group.id} index={index}>
						{(provided, snapshot) => {
								return (
									<FreckleTooltip placement="right" arrow title={ prodName }>
									<div 
									ref={provided.innerRef}
				                    {...provided.draggableProps}
				                    {...provided.dragHandleProps}
				                    className={panelClass} 
				                    onMouseDown={()=>{ 
				                    	context.dispatch({type: ContextAction.setSelectedGroup, payload: group.id})
				                    }}>
										<img className="product-image" src={prodImgUrl}/>
										<span className="product-name">{ prodName }</span>
									</div>
									</FreckleTooltip>
								)
							}
						}
					</Draggable>
				)
			})

		} else {
			// add placeholder with instructions here
			panels = (
				<div className="placeholder">
				<h3>Timeline Empty</h3>
				<p>
				Add products from the <br/>Product Library.
				</p>
				</div>
			)
		}

		return(
				<Droppable 
				droppableId="droppable"
				>
					{(provided, snapshot) => {
						return(
							<div 
							style={{backgroundColor: snapshot.isDraggingOver?'#2f2f2f':'transparent'}}
							{...provided.droppableProps}
	              			ref={provided.innerRef}
	              		 	className="group-name-panels-container" 
	              		 	onScroll={snapScrollFromNames}
	              		 	onMouseOver={()=> focusedScrollSection.current = namesRef.current}>
	              		 		<div ref={namesRef} style={{'width':'100%', 'height':'100%'}}>
								{ panels }
								<div style={{height: '20px'}}></div>
								{ provided.placeholder }
								</div>
							</div>
						)
						}
					}
				</Droppable>
		)
	}

	// TODO: move the filtering logic into reducer, remove from here
	// TOOD: make this work for a set of group/ts combinations, not just one
	const deleteFreckles = (deletes) => {
		setSelectedKeyframes(new Set())
		context.dispatch({type: ContextAction.deleteFreckles, payload: deletes})
	}

	const deleteRanges = (deletes) => {
		setSelectedRanges(new Set())
		context.dispatch({type: ContextAction.deleteRanges, payload: {rangedeletes: deletes}})
	}

	const deleteItems = (kfdelete, rangedeletes) => {
		setSelectedKeyframes(new Set())
		setSelectedRanges(new Set())
		context.dispatch({type: ContextAction.deleteFreckles, payload: kfdelete})
		context.dispatch({type: ContextAction.deleteRanges, payload: {skipHistory: !!kfdelete.length, rangedeletes: rangedeletes}})
	}

	const clickedTicks = (e) => {
	    scrub(e)
	    setScrubbing(true)
	}

	const moveScrub = (e) => {
		if(scrubbing) scrub(e)
	}

	const endScrub = (e) => {
		if(scrubbing){
			if (e) scrub(e)
			setScrubbing(false)
		}
		clearInterval(bookendScrollTimerRef.current)
		bookendScrollTimerRef.current = null
	}

	const nudgeRight = () => {
		if(props.videoNode.currentTime == props.videoNode.duration) return

		const stageWidth = props.duration*tickWidth
		const timeShift = (5 / stageWidth) * props.videoNode.duration
		timelineContainerRef.current.scrollLeft = timelineContainerRef.current.scrollLeft + 5
		props.videoNode.currentTime += timeShift
	}

	const nudgeLeft = () => {
		if(props.videoNode.currentTime == 0 || timelineContainerRef.current.scrollLeft == 0) return
		const stageWidth = props.duration*tickWidth
		const timeShift = (5 / stageWidth) * props.videoNode.duration
		timelineContainerRef.current.scrollLeft = timelineContainerRef.current.scrollLeft - 5
		props.videoNode.currentTime -= timeShift
	}

	const scrub = (e) => {
		// const rect = e.target.getBoundingClientRect();
		const rect = timelineContainerRef.current.getBoundingClientRect()
		const x = e.clientX - rect.left + timelineContainerRef.current.scrollLeft; 
		const stageWidth = props.duration*tickWidth
		const xPerc = Math.min((x / stageWidth), 1.0)

		const timer = bookendScrollTimerRef.current

		const xInContainer = e.clientX - rect.left
		if (xInContainer > rect.width - 20) {
			if (!timer) bookendScrollTimerRef.current = setInterval(nudgeRight, 50)
		} else if(xInContainer < 20) {
			if (!timer) bookendScrollTimerRef.current = setInterval(nudgeLeft, 50)
		} else {
			clearInterval(bookendScrollTimerRef.current)
			bookendScrollTimerRef.current = null
		}

		props.videoNode.currentTime = xPerc * props.duration
	}

	const getPlayhead = () => {
		return(
			<div key="playhead" ref={playheadRef} className="playhead">
				<div className="topper"></div>
				<div className="line"></div>
			</div>
		)
	}

	const updateScale = (e) => {
		const newScale = e.target.value / 100
		const tickWidth = newScale * 150

		setScale(newScale)
		setTickWidth(tickWidth)
	}

	const snapToPlayhead = () => {
		const timeline = timelineContainerRef.current
		const playhead = playheadRef.current

		if (!playhead || !timeline) return

		const playheadRect = playhead.getBoundingClientRect()
		const timelineRect = timeline.getBoundingClientRect()
		let xPos = timeline.scrollLeft + playheadRect.left - timelineRect.left - timelineRect.width/2
		timeline.scrollLeft = xPos
	}

	const snapScrollFromTimeline = (e) => {
		if (focusedScrollSection.current != timelineRef.current) return
		namesRef.current.parentElement.scrollTop = e.target.scrollTop
	}

	const snapScrollFromNames = (e) => {
		if (focusedScrollSection.current != namesRef.current) return
		timelineRef.current.scrollTop = e.target.scrollTop
	}

	const dndEnded = (result) => {
	    // dropped outside the list
	    if (!result.destination) {
	      return;
	    }

	    if(result.source.droppableId === "droppable" && result.destination.droppableId === "droppable") {
	    	context.dispatch({type: ContextAction.setGroupsOrder, payload: {groupId: result.draggableId, index: result.destination.index}})
	    }
	}

	
		const numTicks = Math.ceil(props.duration)
		const stageWidth = numTicks*tickWidth

		const rangeBoxes = document.querySelectorAll(`[data-type*="range"]`)

		return (
			<DragDropContext onDragEnd={dndEnded}>


			<TutorialTooltip
			placement={'top'}
			steps={[EditorTutorialState.addSegment, EditorTutorialState.editSegment, EditorTutorialState.addSegmentHotkey]}>

			<div className="stage-container">

				<MenuToolbar
				hasSelections={!!(selectedRanges.size + selectedKeyframes.size)}
				onDeleteSelection={deleteSelected}
				onUpdateScale={updateScale}
				videoNode={props.videoNode}/>

				<div className="active-stage">

					<div className="names-container">
						<div className="toolbar">
							<SearchInput
							placeholder="Filter Products"
							onChange={e=>{
								const search = e.target.value
								setFilterString(search)
							}}/>
						</div>

						{ getGroupNamesPanels() }
					</div>


					<div
					className="stage-timeline-container" 
					ref={timelineContainerRef}
					onMouseMove={moveScrub} 
					onMouseUp={endScrub} 
					>
							
							<div
							className="timeline-content" 
							style={{width: stageWidth+"px", minWidth:'100%'}} 
							onScroll={snapScrollFromTimeline} 
							onMouseOver={()=> focusedScrollSection.current = timelineRef.current}
							ref={timelineRef}>
								{!movingRange && !isControlPressed && <Selecto
								toggleContinueSelect={"shift"}
								selectableTargets={[".timeline-content", ".click-area", ...rangeBoxes]}
								hitRate={1}
								onSelect={(e)=>{

									const timestamps = new Set(e.selected.filter(item=> item.dataset.type == 'keyframe').map(item=> `${item.dataset.groupid}:${item.dataset.kfid}`))
									setSelectedKeyframes(timestamps)

									const ranges = new Set(e.selected.filter(item=> item.dataset.type == 'range').map(item=> `${item.dataset.groupid}:${item.dataset.rid}`))
									setSelectedRanges(ranges)

								}}/>}
								{ getGroupPanels() }

							</div>

						<div className="timeline-overlay">
							{ getTicks() }

							{ getPlayhead() }
						</div>

					</div>

				</div>
			</div>		

			</TutorialTooltip>

			</DragDropContext>
		)
	
}

