import React, { Component } from 'react';
import Header from './Header'
import LibraryItem from './LibraryItem'
import LibraryPane from './LibraryPane'
import AddVideo from './AddVideo'
import EmbedVideo from './EmbedVideo'
import Settings from './Settings'
import ProductsManagerView from './ProductsManagerView'
import Button from './Button'
import localforage from "localforage"
import { BarLoader, SyncLoader, BeatLoader } from "react-spinners";

import { logEvent } from '../helpers/analytics.js'

import { allVideos, createVideo, deleteVideo, subscribeToVideoChanges, publishVideo } from '../modules/freckle-api.js'

import { CSSTransition } from 'react-transition-group'

import { withRouter } from 'react-router-dom'

import { AppContext, AppAction } from './Context/AppContext'

import '../../styles/Library.scss';

export const SETTINGS_PAGE = "SETTINGS_PAGE"
export const PRODUCTS_PAGE = "PRODUCTS_PAGE"
export const VIDEOS_PAGE = "VIDEOS_PAGE"


export default class Library extends Component {
  static contextType = AppContext

  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedVideo: null,
      addingVideo: false,
      embeddingVideo: false,
      versionToEmbed: null,
      publishing: false,
      previewing: false,
      firstLoadComplete: false
    }

    this.context.syncVideos()
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    console.log("unsubbing")
  }

  getVideosGrid = () => {
    if (!this.context.state.selectedStore) {
      return <span/>
    }
    
    const videos = this.context.state.videos.filter(video => video.shopUrl === this.context.state.selectedStore.domain)
    var videoContents = null
    if(videos.length > 0) {
      videoContents = (
        <div className="videos-list">
          {
            videos.map((video)=>{
              return(
                <LibraryItem 
                showsDetailsButton
                key={video.id} 
                selected={false} 
                video={video} 
                onDelete={()=>{
                  deleteVideo(video.id).then((response)=>{
                    this.context.syncVideos()
                  })
                }}
                singleClicked={()=>{
                  const { history } = this.props
                  if(history) history.push("/video/" + video.id)
                  logEvent("Select Video", {"videoId": video.id})
                }}/>
              )
            })
          }
        </div>
      ) 
    } else if(this.context.state.hasLoadedVideos) {
      videoContents = (
        <div className="videos-loading-container">
        <img className="zero-state-icon" src="/shuttle.svg"/>
        <h3>Upload your first video!</h3>
        <p>Get started by uploading your first video.</p>
        <Button
        ghost
        onClick={()=>{ this.setState({addingVideo: true}) }}>
        UPLOAD A VIDEO
        </Button>
        </div>
      )
    } else {
      videoContents = <div className="videos-loading-container"><BeatLoader color="#ffffff" size="5px"/><h3 className="loading">Loading Videos</h3></div>
    }

    return(
      <div className="videos-list-container">
        <div className="videos-header">
          <h2>Videos</h2>
          <Button 
          large 
          imgUrl="/new-item.svg"
          onClick={()=>{ this.setState({addingVideo: true}) }}>
           { //<img src="/new-item.svg"/>
         }
          New Video
          </Button>
        </div>
        { 
          videoContents
        }
      </div>
    )
  }

  showAddVideo = () => {
    this.setState({addingVideo: true})
  }

  attemptToDeselectItem = (e) => {
    if(e.target.className === "videos-list" || e.target.className === "lib-middle-pane" || e.target.className === "videos-list-container") {
      this.setState({selectedVideo: null})
    }
  }

  archiveSelectedVideo = () => {
    logEvent("Archive Video", {"videoId": this.state.selectedVideo.id})
    this.setState({selectedVideo: null})
    deleteVideo(this.state.selectedVideo.id).then((response)=>{
      this.context.syncVideos()
    })
  }

  previewSelectedVideo = (videoId) => {
    this.setState({previewing: true})
    logEvent("Preview Video", {videoId: this.state.selectedVideo.id})
  }

  getRightPane = () => {
    return(
      <CSSTransition in={this.state.selectedVideo !== null} timeout={500} classNames="lib-right-pane" unmountOnExit>
        <div className="lib-right-pane">
          <LibraryPane 
          history={this.props.history}
          video={this.state.selectedVideo} 
          templates={this.props.templates}
          onEdit={async ()=>{
            const { history } = this.props
            if(history) history.push("/video/" + this.state.selectedVideo.id)
          }} onPreview={this.previewSelectedVideo} onArchive={this.archiveSelectedVideo} clickedPublish={this.clickedPublish} clickedEmbed={this.clickedEmbed}/>
        </div>
      </CSSTransition>
    )
  }

  closeOverlay = (e) => {
    if(e.target.className === "overlay-pane"){
      this.setState({addingVideo: false, embeddingVideo: false, previewing: false})
    }
  }

  render() {
    var loadingMessage = this.state.publishing ? "Publishing..." : "Loading..."
    const overlayClass = (this.state.addingVideo || this.state.embeddingVideo || this.state.previewing || this.state.publishing) ? "overlay-pane" : "overlay-pane hidden"

    return(
      <div className="library-container" onClick={this.attemptToDeselectItem}>
        <div className={overlayClass} onClick={this.closeOverlay}>
          <CSSTransition in={this.state.addingVideo} timeout={500} classNames="add-video" unmountOnExit>
            <AddVideo onCancel={()=>{ 
              this.setState({addingVideo: false}) 
            }} onAdded={()=>{ 
              this.setState({addingVideo: false}) 
              this.context.syncVideos()
            }}/>
          </CSSTransition>
          <CSSTransition in={this.state.embeddingVideo} timeout={500} classNames="add-video" unmountOnExit>
            <EmbedVideo video={this.state.selectedVideo} version={this.state.versionToEmbed} onDone={()=>{ this.setState({embeddingVideo: false}) }}/>
          </CSSTransition>
          <CSSTransition in={this.state.publishing} timeout={500} classNames="add-video" unmountOnExit>
            <div className="publishing-container">
              <div className="modal">
                <h4>{loadingMessage}</h4>
                <SyncLoader size="10" color="#444444"/>
              </div>
            </div>
          </CSSTransition>
        </div>

        <div className="main-content">
          <div className="lib-middle-pane" onClick={this.attemptToDeselectItem}>
            { this.getVideosGrid() }
          </div>
          
          { this.getRightPane() }

        </div>
      </div>  
    )
  }

}
