import React from 'react'
import styled from 'styled-components'
import { BeatLoader } from "react-spinners";

const Container = styled.div`
	width: 100%;
	height: 100vh;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
`

export default function LoadingView(props){
	return(
		<Container>
		<BeatLoader size="5px" color="white"/>
		</Container>
	)
}