import React, { Component } from 'react';
import "../../../styles/VideoPlayer.scss"
import memoize from "memoize-one"
import ClosePathButton from './ClosePathButton'
import { FreckleTooltip } from '../FreckleTooltip'

import Freckle, { DragFreckle } from './Freckle'
import Hotspot, { DragHotspot } from './Hotspot/Hotspot'

import videojs from 'video.js'
import { Menu, MenuItem } from '@material-ui/core'
import 'datejs'
import ReactResizeDetector from 'react-resize-detector';

import { EditorContext, ContextAction } from './Context/EditorContext'

import styled from 'styled-components';

const NUDGE_SIZE = 1/30
// const NUDGE_SIZE = 0.1

const R = require('ramda');
var moment = require('moment');


const ProductCountBadge = styled.div`
position: absolute;
right: 20px;
bottom: 20px;
background-color: white;
padding: 5px 5px 5px 10px;
border-radius: 1000px;
font-family: "Signika";
font-size: 14px;
font-weight: 700;
opacity: ${props=> !!props.visible ? '1':'0'};
transform: ${props=> !!props.visible ? 'scale(1)':'scale(0.95)'};
transition: 0.15s;
cursor: default;
display: flex;
align-items: center;

span {
	float: right;
	background-color: #1F1F1F;
	color: white;
	border-radius: 1000px;
	margin-left: 8px;
	padding: 2px;
	height: 15px;
	min-width: 15px;
	font-size: 12px;

	display: flex;
	align-items: center;
	justify-content: center;
}
`


export default class VideoPlayer extends Component {
	static contextType = EditorContext

	constructor(props, context) {
		super(props, context);

		this.state = {
			portrait: false,
			aspectRatio: 1.0,
			freckleLayerHeight: 0,
			freckleLayerWidth: 0,
			currentTime: 0,
			totalTime: 0,
			playing: false,
			requestedPlayheadTime: null,
			showsDropdownMenu: false,
			menuPosition: null,
			hotspotSize: 40,
			numberVisibleProducts: 0,
			videoCanPlay: false,
		}

		this.animationFrameRequestId = null

		this.freckleRefs = []

		this.videoPlayer = React.createRef();
		this.freckleLayer = React.createRef();
		this.currentTimeRef = React.createRef();

		this.dragFreckle = React.createRef()
	}

	componentWillUnmount() {
		cancelAnimationFrame(this.animationFrameRequestId)

		this.videoPlayer.removeEventListener("play", this.playEventFired);
        this.videoPlayer.removeEventListener("pause", this.pauseEventFired);
		this.videoPlayer.removeEventListener("canplay", this.loadedmetadataFired)
		document.removeEventListener('keydown', this.handleKeypress)
		document.removeEventListener('keyup', this.handleKeyup)
	}
	
	componentDidMount() {
		this.resizedVideo()
		document.addEventListener('keydown', this.handleKeypress)
		document.addEventListener('keyup', this.handleKeyup)

		this.props.videoNode(this.videoPlayer)

		// TORTURE THE VIDEO PLAYER
		// setInterval(()=>{
		// 	if (this.videoPlayer && this.videoPlayer.duration) {
		// 		try {
		// 			// const val = Math.random() * this.videoPlayer.duration
		// 			let val = this.videoPlayer.currentTime - 0.25
		// 			if (val < 1) val = this.videoPlayer.duration
		// 			this.videoPlayer.currentTime = val
		// 		} catch(e) {
		// 			debugger
		// 		}
		// 	}
		// }, 100)
	}

	handleKeyup = (e) => {
		if(e.code === "ArrowRight" && !e.shiftKey && !e.metaKey) {
			if(this.rightNudgeTimer){
				clearTimeout(this.rightNudgeTimer)
				this.rightNudgeTimer = null
			}
		
			// nudge video forward
			let righttarget
			if(this.videoPlayer.playbackRate === 1){
				righttarget = (Math.round(this.videoPlayer.currentTime / NUDGE_SIZE) + 1) * NUDGE_SIZE
			} else {
				righttarget = Math.round(this.videoPlayer.currentTime / NUDGE_SIZE) * NUDGE_SIZE
			}

			this.videoPlayer.currentTime = Math.max(0, righttarget)

			this.videoPlayer.muted = false
			this.videoPlayer.playbackRate = 1
			this.videoPlayer.pause()
		}
	}

	handleKeypress = (e) =>{
		if(document.activeElement.tagName === "INPUT" && document.activeElement.type === "text") return
		const code = e.code
		if(e.metaKey){
			switch(code){
				case "Backspace":
				this.deleteSelectedProduct()
				break;
			case "KeyZ":
				// undo
				this.context.dispatch({type: ContextAction.undoAction})
				e.preventDefault()
				break;
			case "KeyY":
				// redo
				this.context.dispatch({type: ContextAction.redoAction})
				e.preventDefault()
				break;
			case "ArrowLeft":
				this.snapToLastLocation(e.shiftKey)
				e.preventDefault()
				break;
			case "ArrowRight":
				this.snapToNextLocation(e.shiftKey)
				e.preventDefault()
				break;	
			}

		} else if(e.shiftKey){
			switch(code){
			case "ArrowLeft":
				// nudge video back
				this.videoPlayer.currentTime -= 1.0
				e.preventDefault()
				break;
			case "ArrowRight":
				// nudge video forward
				this.videoPlayer.currentTime += 1.0
				e.preventDefault()
				break;
			}

		} else {
			switch(code){
			case "Enter":
				if(this.state.playing) this.videoPlayer.pause()
				this.videoPlayer.currentTime = 0;

				e.preventDefault()
				break;
			case "ArrowUp":
				// send to previous group
				this.selectPreviousGroup()
				e.preventDefault()
				break;
			case "ArrowDown":
				// send to next group
				this.selectNextGroup()
				e.preventDefault()
				break;
			case "ArrowLeft":
				// nudge video back
				const lefttarget = (Math.round(this.videoPlayer.currentTime / NUDGE_SIZE) - 1) * NUDGE_SIZE
				this.videoPlayer.currentTime = Math.max(0, lefttarget)

				// this.frames.seekBackward(1)

				e.preventDefault()
				break;
			case "ArrowRight":
				if(!!!this.rightNudgeTimer) {
					this.rightNudgeTimer = setTimeout(()=>{
						this.videoPlayer.muted = true
						this.videoPlayer.playbackRate = 0.33
						this.videoPlayer.play()
					}, 250)
				}

				e.preventDefault()
				break;
			case "Space":
				// toggle play/pause
				this.togglePlay()
				e.preventDefault()
				break;
			case "KeyE":
				this.closeCurrentPath()
				break;
			}

		}
		
	}

	snapToNextLocation = (snapToBookend) => {
		const group = this.context.state.groups.find((group)=> group.id === this.context.state.selectedGroupId )
		if(group) {
			const ts = this.videoPlayer.currentTime
			const nextLocation = group.locations.find((loc)=> loc.ts > ts && (!snapToBookend || loc.t !== 1))
			if(nextLocation) {
				this.videoPlayer.currentTime = nextLocation.ts
			}
		}
	}

	snapToLastLocation = (snapToBookend) => {
		const group = this.context.state.groups.find((group)=> group.id === this.context.state.selectedGroupId )
		if(group) {
			const ts = this.videoPlayer.currentTime

			const reversed = [...group.locations]
			reversed.reverse()
			const revIdx = reversed.findIndex((loc)=> loc.ts < ts && (!snapToBookend || loc.t !== 1))
			if(revIdx !== -1){
				const idx = group.locations.length - 1 - revIdx
				if(idx >= 0) {
					const prevLocation = group.locations[idx]
					this.videoPlayer.currentTime = prevLocation.ts
				}
			}
		}
	}

	selectNextGroup = () => {
		const currentGroupIndex = this.context.state.groups.findIndex((group)=> group.id === this.context.state.selectedGroupId )
		if(currentGroupIndex !== -1){
			var newSelectedGroup
			// if we're not on the last group
			if(currentGroupIndex < this.context.state.groups.length-1){
				newSelectedGroup = this.context.state.groups[currentGroupIndex+1]
			} else {
				newSelectedGroup = this.context.state.groups[0]
			}
			this.context.dispatch({type: ContextAction.setSelectedGroup, payload: newSelectedGroup.id})
		}
	}

	selectPreviousGroup = () => {
		const currentGroupIndex = this.context.state.groups.findIndex((group)=> group.id === this.context.state.selectedGroupId )
		if(currentGroupIndex !== -1){
			var newSelectedGroup
			// if we're not on the last group
			if(currentGroupIndex > 0){
				newSelectedGroup = this.context.state.groups[currentGroupIndex-1]
			} else {
				newSelectedGroup = this.context.state.groups[this.context.state.groups.length-1]
			}
			this.context.dispatch({type: ContextAction.setSelectedGroup, payload: newSelectedGroup.id})
		}
	}


	// binary search of ordered/deduped ranges would be faster, but this gets it done for now
	getNumberOfProducts = () => {
		let numVisibleProducts = 0
		this.context.state.groups.forEach(group=> {
			if (group.itemType == 'list') {
				const hasActiveRange = !!group.ranges.filter(range=>{
					return this.videoPlayer.currentTime >= range.start && this.videoPlayer.currentTime <= range.end 
				}).length
				if (hasActiveRange) numVisibleProducts += 1
			}

			if (group.itemType == 'hotspot') {
				const lastKf = [...group.locations].reverse().find(kf=> kf.ts <= this.videoPlayer.currentTime)
				if (lastKf) {
					const hasActiveHotspot = [0,1].includes(lastKf.t)
					if (hasActiveHotspot) numVisibleProducts += 1 
				} 
			}
		})
		return numVisibleProducts
	}


	// TODO: When last keyframe is at the very end of the video, overlapping keyframes default to the "default" end keyframe, so the keyframe fades out very slowly.
  generateAnimationCSS = memoize(
  	(groups, totalTime) => {
  		if(totalTime === 0) return ""

	    let animationIncrement = 0.001
	    let keys = groups.map((group)=>{ return group.id })
	    
	    let animations = keys.map(key => {
	      let frames = groups.find((group)=>{ return group.id === key }).locations
	      let cssFrames = frames.map(frame => {
	          let percent = (frame.ts / totalTime) * 100
	          switch(frame.t) {
	            case 0:            
	              return `${Math.max(percent-animationIncrement, 0)}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 0.0; transform: scale(0); } 
						  ${percent}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 1.0; transform: scale(1); }`
	              break;
	            case 1:
		              return `${percent}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 1.0; transform: scale(1); }`
	              break;
	            case 2:
	              return `${percent}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 1.0; transform: scale(1); } 
						  ${percent+animationIncrement}% { opacity: 0.0; transform: scale(0); }`
	              break;  
	            default:
	              break;
	          }
	      })
	      const lastFrame = frames[frames.length-1]
	      if(!!lastFrame && lastFrame.t !== 2) cssFrames.push(`${100-animationIncrement}% { left: ${lastFrame.x*100}%; top: ${(lastFrame.y)*100}%; opacity: 1.0; transform: scale(1); }`)
	      let animationString = `@keyframes anim-${key}-freckle { 0% { opacity: 0.0; transform: scale(0); } ${cssFrames.join("\n")} 100% { opacity: 0.0; transform: scale(0); } }`
	      return animationString
	    })
	    const fullCss = animations.join("\n")
	    return fullCss
	  }
	)

  	playEventFired = () =>{
  		this.setState({playing: true})
  		this.props.playStateChange(true)
  	}

  	pauseEventFired = () => { 
		this.setState({playing: false})
		this.props.playStateChange(false)
    }

	loadedmetadataFired = (e) => { 
		if (this.state.totalTime > 0) return

		const height = e.target.videoHeight
		const width = e.target.videoWidth
		const portrait = (height > width)
		const aspectRatio = width / height

		const player = this.videoPlayer

		if(!!!this.animationFrameRequestId) this.animationFrameRequestId = requestAnimationFrame(this.updateCurrentTime.bind(this))

		// this.props.setDurationTime(player.duration)
		this.context.dispatch({ type: ContextAction.setDurationTime, payload: player.duration  })

		this.props.loadedMetadata(e)

		this.setState({portrait: portrait, aspectRatio: aspectRatio, totalTime: player.duration, videoCanPlay: true})

		this.resizedVideo()
	}

	configureEventListeners = () => {
		const player = this.videoPlayer
        player.addEventListener("play", this.playEventFired, true);
        player.addEventListener("pause", this.pauseEventFired, true);
		player.addEventListener("canplay", this.loadedmetadataFired)
	}

	markCurrentAsEnd = () => {
		if(this.context.state.selectedGroupId != null){ 
			const ts = this.videoPlayer.currentTime
			const locs = R.clone(this.context.state.groups.find((group)=>{ return this.context.state.selectedGroupId === group.id }).locations)
			const loc = locs.find((loc)=>{ return loc.ts === ts })
			if(loc != null){
				loc.t = (loc.t===2) ? 1 : 2
				this.context.dispatch({type: ContextAction.addKeyframe, payload: loc})
			}
		}
	}

	closeCurrentPath = () => {
		if(this.context.state.selectedGroupId != null){
			const ts = this.videoPlayer.currentTime
			const locs = R.clone(this.context.state.groups.find((group)=>{ return this.context.state.selectedGroupId === group.id }).locations)
			.filter((loc)=>{ return loc.ts <= ts })
			if(!!locs.length){
				const lastLoc = R.clone(locs[locs.length-1])
				lastLoc.ts = ts
				lastLoc.t = 2
				this.context.dispatch({type: ContextAction.addKeyframe, payload: lastLoc})
			}
		}
	}

	deleteCurrentLocation = () => {
		if(this.context.state.selectedGroupId != null){
			const ts = this.videoPlayer.currentTime
			const locs = this.context.state.groups.find((group)=>{ return this.context.state.selectedGroupId === group.id }).locations
			const loc = locs.find((loc)=>{ return loc.ts === ts })
			if(loc != null){
				this.context.dispatch({type: ContextAction.deleteFreckle, payload: loc})
			}
		}
	}

	deleteSelectedProduct = () => {
		if(this.context.state.selectedGroupId !== null){
			const group = this.context.state.groups.find((group)=>{ return group.id === this.context.state.selectedGroupId })
			this.context.dispatch({type: ContextAction.deleteGroup, payload: group})
		}
	}

	togglePlay = () => {
		if(this.state.playing){
			this.videoPlayer.pause()
		} else {
			this.videoPlayer.play()
		}
	}

	resizedVideo = () => {
		// const aspectRatio = width / height

		const player = this.videoPlayer
		var height = player.getBoundingClientRect().height
		var width = player.getBoundingClientRect().width

		const containerAspectRatio = width / height

		if(this.state.aspectRatio <= containerAspectRatio) {
			width = this.state.aspectRatio * height
		} else {
			height = width / this.state.aspectRatio
		}

		let hotspotSize = width * 0.05
		if(hotspotSize < 30) hotspotSize = 30
		if(hotspotSize > 50) hotspotSize = 50

		this.setState({freckleLayerHeight: height, freckleLayerWidth: width, hotspotSize: hotspotSize})
	}

	// TODO: this is triggered when clicking outside the active area, too
	clickedFreckleLayer = (e) => {
		if(e.target.className !== "freckle-layer" && e.target.className !== "inner-freckle-layer") return
		if(!!!this.context.state.selectedGroupId) return

	    const rect = e.target.getBoundingClientRect();
		const x = e.clientX - rect.left; 
		const y = e.clientY - rect.top;  

		const xPerc = x / rect.width
		const yPerc = y / rect.height
		const ts = parseFloat(this.videoPlayer.currentTime.toFixed(6))

		const freckle = {ts: ts, t: 1, x: xPerc, y: yPerc}

		if(xPerc >= 0.0 && xPerc <= 1.0 && yPerc>=0.0 && yPerc<=1.0){
			this.context.dispatch({type: ContextAction.addKeyframe, payload: freckle})
		}
	}

	handleContextClick = (e, groupId) => {
		e.preventDefault()

		if(!!!this.context.state.selectedGroupId || this.context.state.selectedGroupId !== groupId){
			this.context.dispatch({type: ContextAction.setSelectedGroup, payload: groupId})
		}

		const freckleLayerRect = this.freckleLayer.current.getBoundingClientRect();
		const xWithinFreckleLayer = (e.clientX - freckleLayerRect.left) / freckleLayerRect.width
		const yWithinFreckleLayer = (e.clientY - freckleLayerRect.top) / freckleLayerRect.height

		this.setState({
			menuPosition: {x: e.clientX, y: e.clientY, xFrame: xWithinFreckleLayer, yFrame: yWithinFreckleLayer},
			showsDropdownMenu: true
		})
	}

	getFreckles = memoize((draggingFreckle, groups, totalTime, selectedGroupId, hotspotSize) => {
		var dur = 0
		if(this.videoPlayer != null) dur = this.videoPlayer.duration

		return this.context.state.groups.filter(group=> group.itemType == 'hotspot').map((group)=>{
			var freckleRef = this.freckleRefs[group.id]
			if(freckleRef == null) {
				freckleRef = React.createRef();
				this.freckleRefs[group.id] = freckleRef
			}
			const selected = (selectedGroupId === group.id)

			const content = (
				<Hotspot
				size={ this.state.hotspotSize }
				type={ group.properties.hotspotType }
				color={ group.properties.color } 
				accentColor={ group.properties.accentColor }
				freckleRef={freckleRef}
				selected={selected} 
				group={group} 
				duration={dur}
				onDragStart={this.handleFreckleDragStart}
				onContextMenu={(e)=> this.handleContextClick(e, group.id)}/>
			)
			if(this.state.draggingFreckle) return content
			if(selected) return content
			return (
				<React.Fragment>
				{ content }
				</React.Fragment>
			)
		})
	})

	updateFrecklePosition = () => {
		if(this.videoPlayer){
			let offset = this.videoPlayer.currentTime
			if(offset === this.videoPlayer.duration) offset = this.videoPlayer.duration - 0.001
			Object.keys(this.freckleRefs).forEach((key)=>{
				const freckleRef = this.freckleRefs[key]
				if(freckleRef.current != null){
					freckleRef.current.style.animationDelay = -offset + "s"
				}
	    	})
		}
	}

	updateCurrentTime = () => {
		if(this.videoPlayer){
			const offset = this.videoPlayer.currentTime
			const currentTimeString = moment((new Date).clearTime().addSeconds(offset)).format('mm:ss.SS')
			this.currentTimeRef.current.innerHTML = currentTimeString

			this.updateFrecklePosition()

			const numProducts = this.getNumberOfProducts()
			if (numProducts != this.state.numberVisibleProducts) {
				this.setState({ numberVisibleProducts: numProducts })
			}

			this.animationFrameRequestId = requestAnimationFrame(this.updateCurrentTime.bind(this))
		}
	}

	handleDragEnd = (e) => {
		if(!this.state.draggingFreckle) return
		this.setState({draggingFreckle: false})

		// TOOD: Take where in the freckle the user clicked, it jumps sometimes
		const {x, y} = this.mousePositionInFreckleLayer
		const ts = parseFloat(this.videoPlayer.currentTime.toFixed(6))

		const freckle = {ts: ts, t: 1, x: x, y: y}

		if(x >= 0.0 && x <= 1.0 && y>=0.0 && y<=1.0){
			this.context.dispatch({type: ContextAction.addKeyframe, payload: freckle})
		}

		this.mouseInFreckleOffset = null
	}

	handleFreckleDragStart = (e, groupId) => {
		if(!!!this.context.state.selectedGroupId || this.context.state.selectedGroupId !== groupId){
			this.context.dispatch({type: ContextAction.setSelectedGroup, payload: groupId})
		}
		this.setState({draggingFreckle: true})
		// get position of click within freckle to adjust drop position
		const freckle = this.freckleRefs[groupId]
		const rect = freckle.current.getBoundingClientRect()
		const xWithinFreckle = (e.clientX - rect.left) - rect.width/2
		const yWithinFreckle = (e.clientY - rect.top) - rect.height/2
		this.mouseInFreckleOffset = {x: xWithinFreckle, y: yWithinFreckle}
		// get start position of mouse in freckle layer
		const freckleLayerRect = this.freckleLayer.current.getBoundingClientRect();
		const xWithinFreckleLayer = (e.clientX - this.mouseInFreckleOffset.x - freckleLayerRect.left)
		const yWithinFreckleLayer = (e.clientY - this.mouseInFreckleOffset.y - freckleLayerRect.top)
		this.dragFreckle.current.style.transform = `translateX(${xWithinFreckleLayer}px) translateY(${yWithinFreckleLayer}px)`

		this.mousePositionInFreckleLayer = {x: xWithinFreckleLayer/freckleLayerRect.width, y: yWithinFreckleLayer/freckleLayerRect.height}
	}

	handleMouseMouse = (e) => {
		let xShift = 0
		let yShift = 0
		if(!!this.mouseInFreckleOffset) {
			xShift = this.mouseInFreckleOffset.x
			yShift = this.mouseInFreckleOffset.y
		}
		const rect = this.freckleLayer.current.getBoundingClientRect();
		const x = (e.clientX - xShift - rect.left)
		const xPerc =  x/rect.width
		const y = (e.clientY - yShift - rect.top)
		const yPerc = y/rect.height
		this.mousePositionInFreckleLayer = {x: xPerc, y: yPerc}

		if(this.state.draggingFreckle) {
			this.dragFreckle.current.style.transform = `translateX(${x}px) translateY(${y}px)`
			this.freckleRefs[this.context.state.selectedGroupId].current.style.visibility = 'hidden'
		}
	}

	handleMouseUp = (e) => {
		this.mouseInFreckleOffset = null
		if(this.state.draggingFreckle){
			this.setState({draggingFreckle: false})
			this.freckleRefs[this.context.state.selectedGroupId].current.style.visibility = 'visible'

			const ts = parseFloat(this.videoPlayer.currentTime.toFixed(6))

			const freckle = {ts: ts, t: 1, x: this.mousePositionInFreckleLayer.x, y: this.mousePositionInFreckleLayer.y}
			this.context.dispatch({type: ContextAction.addKeyframe, payload: freckle})

		} else {
			const ts = parseFloat(this.videoPlayer.currentTime.toFixed(6))
			const payload = {...this.mousePositionInFreckleLayer, ts: ts}
			this.props.mouseUpOnFreckleLayer(payload)
		}
	}

	isClosePathButtonDisabled = () => {
		// if there is no selected product or the video is playing, disabled=true
		if(this.state.playing || !!!this.context.state.selectedGroupId) return true
		const locs = this.context.state.groups
		.find((group)=>{ return this.context.state.selectedGroupId === group.id }).locations
		.filter((loc)=> loc.ts <= this.videoPlayer.currentTime)

		// if there are no previous keyframes, disabled=true
		if(!!!locs.length) return true

		// if the last location exists, and is not an endpoint, disabled=false
		const lastLoc = locs[locs.length - 1]
		switch(lastLoc.t) {
			case 0:
				if(lastLoc.ts < this.videoPlayer.currentTime) return false
				break
			case 1:
				if(lastLoc.ts <= this.videoPlayer.currentTime) return false
				break
			case 2:
				return true
		}

		// in all other cases, return true
		return true
	}

	render() {
		// const requestedTime = this.context.state.requestedPlayheadTime
		// if((!!requestedTime || requestedTime === 0) && requestedTime !== this.videoPlayer.currentTime) {
		// 	this.videoPlayer.currentTime = requestedTime
		// }

		const group = this.context.state.groups.find((group)=> group.id === this.context.state.selectedGroupId )

		const freckleCss = this.generateAnimationCSS(this.context.state.groups, this.state.totalTime)
		const playClass = this.state.playing ? "control pause" : "control play"

		const videoUri = `https://stream.freckle.shop/${this.props.video.uuid}/mp4/base.mp4`
		return (
			<div 
			key={this.props.video.id+":videoplayer"} 
			className="player-container"
			style={{
				opacity: this.state.videoCanPlay ? 1:0,
				transform: this.state.videoCanPlay ? 'scale(1)':'scale(0.95)',
			}}>

				<ReactResizeDetector handleWidth handleHeight onResize={this.resizedVideo}>
					<div className="video-container" onClick={this.clickedFreckleLayer}>
						
						<div className="vjs" data-vjs-player>
							<video preload="meta" ref={ node => this.videoPlayer = node } onLoadStart={this.configureEventListeners} controls={false}>
							  <source src={videoUri}/>
							</video>
						</div>

						<div 
						id="freckle-shift" 
						className="freckle-layer" 
						onMouseMove={this.handleMouseMouse}
						onMouseUp={this.handleMouseUp}
						style={{
							width: this.state.freckleLayerWidth+"px", 
							height: this.state.freckleLayerHeight+"px", 
							marginLeft:-(this.state.freckleLayerWidth/2)+"px",
							marginTop:-(this.state.freckleLayerHeight/2)+"px"
						}}>

						<Menu
						className="freckle-menu"
						anchorReference="anchorPosition"
				        anchorPosition={
				        	this.state.menuPosition ? { top: this.state.menuPosition.y, left: this.state.menuPosition.x } : undefined  
				        }
						open={this.state.showsDropdownMenu}
						onClose={()=> this.setState({showsDropdownMenu: false}) }
						>
						<MenuItem
						className="freckle-menu-item"
						onClick={()=>{
							const freckleLayerRect = this.freckleLayer.current.getBoundingClientRect();

							const selectedFreckle = this.freckleRefs[this.context.state.selectedGroupId]
							const freckleRect = selectedFreckle.current.getBoundingClientRect()
							const center = {
								x: (freckleRect.x - freckleLayerRect.x + 0.5 * freckleRect.width) / freckleLayerRect.width, 
								y: (freckleRect.y - freckleLayerRect.y + 0.5 * freckleRect.height) / freckleLayerRect.height
							}
							this.props.onBeginTracking(center)
							this.setState({showsDropdownMenu: false})

						}}>Track from here</MenuItem>
						<MenuItem
						className="freckle-menu-item red"
						onClick={()=>{
							this.setState({showsDropdownMenu: false})
						}}>Delete hotspot</MenuItem>
						</Menu>

							<style>{ freckleCss }</style>
							<div 
							className="inner-freckle-layer" 
							ref={this.freckleLayer}>
 
 							<FreckleTooltip arrow title="In a Landing Page, this button will reveal a list of all products visible in the current frame.">
							<ProductCountBadge
							visible={!!this.state.numberVisibleProducts}>
						 	View Products
						 	<span>
						 	{ this.state.numberVisibleProducts }
						 	</span>
							</ProductCountBadge>
							</FreckleTooltip>

							{ this.getFreckles(this.state.draggingFreckle, this.context.state.groups, this.state.totalTime, this.context.state.selectedGroupId, this.state.hotspotSize) }
							
							{!!group && <DragHotspot
							group={group}
							size={ this.state.hotspotSize }
							type={ group.properties.hotspotType }
							color={ group.properties.color }
							accentColor={ group.properties.accentColor }
							dragging={this.state.draggingFreckle}
							freckleRef={this.dragFreckle}/>}
							</div>
						</div>

								
					</div>
				</ReactResizeDetector>
				<div className="info" style={{width: this.state.freckleLayerWidth+"px"}}>
					<div className="left">
						<span className="currenttime" ref={this.currentTimeRef}>00:00.00</span>
						&nbsp;/&nbsp;
						<span className="totaltime">{ moment((new Date).clearTime().addSeconds(this.state.totalTime)).format('mm:ss.SS') }</span>
					</div>
					<div className="controls">
						<FreckleTooltip arrow title="Nudge Video Back">
							<button className="control rev" onClick={()=>{this.videoPlayer.currentTime -= 0.03}}/>
						</FreckleTooltip>
						<FreckleTooltip arrow title={this.state.playing ? "Pause Video" : "Play Video"}>
							<button onClick={this.togglePlay} className={playClass}/>
						</FreckleTooltip>
						<FreckleTooltip arrow title="Nudge Video Forward">
							<button className="control fwd" onClick={()=>{this.videoPlayer.currentTime += 0.03}}/>
						</FreckleTooltip>
					</div>
					<div className="right">
					{/*<ClosePathButton
					onClick={this.closeCurrentPath}
					disabled={this.isClosePathButtonDisabled()}/>*/
				}
					</div>
				</div>
			</div>			
		)
	}
}

