import React from 'react'
import styled from 'styled-components'

const moment = require('moment');

const Item = styled.div`
	width: 100%;
	height: 90px;
	border-bottom: 3px solid #111111;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`

const Thumbnail = styled.img`
	height: 60px;
	width: 60px;
	margin-left: 15px;
	margin-right: 20px;
	flex: 0;
	border-radius: 8px;
	user-select: none;
	pointer-events: none;
	object-fit: cover;
`

const Details = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
`

const ProjectTitleLabel = styled.h4`
	margin: 0;
	border: 0;
	color: white;
	line-height: 30px;
`

const ProjectEditedLabel = styled.h4`
	margin: 0;
	border: 0;
	font-weight: 300;
	color: white;
	opacity: 0.5;
	line-height: 30px;
	font-size: 14px;
`

export default function ProjectItem(props){
	return (
		<Item>
		<Thumbnail src={`https://image.mux.com/${props.project.muxPlaybackId}/thumbnail.jpg?width=180&height=180`}/>
		<Details>
			<ProjectTitleLabel>{ props.project.title }</ProjectTitleLabel>
			<ProjectEditedLabel>Last updated {moment(props.project.updatedAt).fromNow()}</ProjectEditedLabel>
		</Details>
		</Item>
	)
}