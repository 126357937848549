import mixpanel from 'mixpanel-browser';

mixpanel.init('87e9f8473c81236a22214f8ee1c5f858', {ignore_dnt: true, debug: (process.env.NODE_ENV === 'development')}); 

export function identify(identifier, data){
	mixpanel.identify(identifier);
	if(!!data) mixpanel.people.set(data);
}

export function logEvent(eventName, data) {
	mixpanel.track(eventName, data);
}