import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { AppContext, AppAction, useAppContext } from './Context/AppContext'
import { BeatLoader } from "react-spinners";
import Button from './Button'
import * as API from '../modules/freckle-api.js'
import * as Auth from '../modules/auth'

const Plans = Object.freeze([
{
	name: "Starter",
	imgSrc: "/starter-tier.svg",
	price: "19.99",
	description: "Best for small brands that are just getting started.",
},
{
	name: "Growth",
	imgSrc: "/growth-tier.svg",
	price: "99.00",
	description: "More features, more themes, unlimited pageviews.",
},
{
	name: "Agency",
	imgSrc: "/agency-tier.svg",
	price: "599.99",
	description: "For digital agencies with multiple clients.",
},
])

const Enter = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

const Container = styled.div`
padding: 20px;
width: calc(100vw - 40px);
min-height: calc(100vh - 40px);
height: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
font-family: "Signika";

animation: ${Enter} 1.0s normal forwards;

gap: 50px;

@media (max-aspect-ratio: 1/1) {
  justify-content: flex-start;
}

h3 {
	margin: 0;
	padding: 0 0 20px 0;
  font-size: 35px;
  color: white;
  text-align: center;
}

h5 {
	color: white;
	padding: 0;
	margin: 0;
	font-size: 20px;
	font-weight: 300;
}

p {
	margin: 0;
	padding: 0;
	color: #9f9f9f;
	text-align: center;
}
`

const PlansContainer = styled.div`
display: flex;
flex-direction: row;
gap: 20px;

@media (max-aspect-ratio: 1/1) {
  flex-direction: column;
  gap: 10px;
}
`

const PlanItemContainer = styled.div`
width: 250px;
display: flex;
flex-direction: column;
align-items: center;
max-width: calc(100 - 40px);
background-color: ${props=> props.selected ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.05)'};
border-radius: 8px;
cursor: pointer;
padding: 20px;
margin: 20px 0 10px 0;

font-family: Signika;
transition: 0.25s;

transform: ${props=> props.selected ? 'scale(1.025)':'scale(1)'};

h3 {
	color: ${props=> props.selected ? '#3781D8':'white'} !important;
	padding: 0;
	margin: 0;
	font-size: 40px;
	font-weight: 700;
	margin: 20px 0 10px 0;
}

h4 {
	color: ${props=> props.selected ? 'black':'white'} !important;
	padding: 0;
	margin: 0;
}

p {
	color: #8f8f8f;
	text-align: center;
	padding: 20px 0 10px 0;
}

box-shadow: ${props=> props.selected ? '0px 0px 30px -10px #3781D8' : 'rgba(0,0,0,0.15) 0 2px 10px'};


filter: ${props=> props.selected ? 'grayscale(0)':'grayscale(1)'};

&:hover {
	background-color: ${props=> props.selected ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.1)'};
}

img {
	height: 150px;
	width: 150px;
	border-radius: 1000px;
	margin: 0 20px 0 10px;
	margin-bottom: ${props=> props.selected ? '-20px' : '0'};
	box-shadow: 0px 0px 50px -15px ${props=> props.selected ? 'rgba(0,0,0,0.5)' : 'transparent'};
	transition: 0.5s;
	transform: ${props=> props.selected ? 'scale3d(1,1,1) translateY(-30px) rotateY(360deg) rotateZ(-15deg);':'scale3d(0.75,0.75,0.75)'};
}

@media (max-aspect-ratio: 1/1) {
  width: calc(100% - 40px);
}

&::after {
  content: "Current Plan";
  background-color: #3781D8;
  color: white;
  font-weight: bold;
  border-radius: 1000px;
  padding: 5px 10px;
  position: absolute;
  top: ${props=> props.selected ? '120px' : '160px'};
  transform: ${props=> props.selected ? 'scale(1)' : 'scale(0.75)'};
  transition: 0.5s;
  width: 100px;
  left: calc(50% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: ${props=> (props.plan.name == props.currentPlanName) ? 1:0};
}
`

function PlanItem(props) {
	const { plan } = props
	return (
		<PlanItemContainer {...props}>
		<img src={ plan.imgSrc }/>
		<h3>{ plan.name }</h3>
		<h4>${ plan.price } / mo</h4>
		<p>{ plan.description }</p>
		</PlanItemContainer>
	)
}

const Welcome = styled.h4`
color: white;
text-align: center;
margin: 0;
padding: 0;
`

const OnboardingHeader = styled.div`
position: absolute;
top: 0;
left: 0;
width: calc(100% - 40px);
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
gap: 15px;
padding: 20px 20px;
background-color: #121212;
border-bottom: 1px solid rgba(255, 255, 255, 0.025);
`

export default function PlanPickerView(props) {
	const context = useAppContext()
	const [selectedPlanName, setSelectedPlanName] = useState(null)

	const [loading, setLoading] = useState(false)

	useEffect(()=>{
		try {
			setSelectedPlanName(context.state.user.plan || "Growth")
		} catch(e) {}
	}, [context.state.user])

	const submitSelection = () => {
		API.createCheckoutSession(selectedPlanName).then(data=>{
      const redirUrl = data.checkout_url
      window.location = redirUrl
    })
	}

	const submitUpdatedSelection = () => {
		setLoading(true)
		API.changePlan(selectedPlanName).then(()=>{
			setLoading(false)
		})
	}

	let hasPlan = false
	try {
		hasPlan = !!context.state.user.stripeSubscriptionId
	}catch(e){}

	let currentPlanName = null
	try {
		if (hasPlan) currentPlanName = context.state.user.plan
	} catch(e) {}

	const backButton = (
		<Button
		backgroundColor="#1f1f1f"
		color="#6f6f6f"
		onClick={()=>{
		  props.history.push("/settings")
		}}>
		⟨ &nbsp; Back to Settings
		</Button>
	)

	const { user } = context.state

	const logoutButton = (
		<OnboardingHeader>
		<Welcome>Welcome, { user && user.firstName }!</Welcome>
		<Button
		backgroundColor="#1f1f1f"
		color="#6f6f6f"
		onClick={()=>{
		  Auth.signOut()
		}}>
		Logout
		</Button>
		</OnboardingHeader>
	)

	return (
		<Container>
		{hasPlan && backButton}
		{!hasPlan && logoutButton}
		<span>
		<h3>{ hasPlan ? "Change your plan" : "Confirm your plan"}</h3>
		<p>💖 { hasPlan ? "Change your subscription any time!" : "Try Freckle for 14 days for free, then cancel any time."}</p>
		</span>
		<PlansContainer>
		{
			Plans.map(plan=> <PlanItem 
				currentPlanName={currentPlanName}
				selected={selectedPlanName == plan.name} 
				plan={plan}
				onClick={()=> setSelectedPlanName(plan.name)}/>)
		}
		</PlansContainer>
		
		{!hasPlan && <Button 
		large
		onClick={submitSelection}>Start my 14 day trial</Button>}

		{hasPlan && <Button 
		disabled={selectedPlanName == currentPlanName}
		large
		onClick={submitUpdatedSelection}>
		{loading ? <BeatLoader color="#ffffff" size="5px"/> : <span>Update Subscription</span>}
		</Button>}

		</Container>
	)
}