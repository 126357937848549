import React, { Component } from 'react';
import Header from './Header'
import TextInput from './TextInput'
import { BeatLoader } from "react-spinners";
import Dropdown from 'react-dropdown';

import { Menu, MenuItem } from '@material-ui/core'

import {Collapse} from 'react-collapse'

import '../../styles/LibraryPane.scss';

import Button from './Button'

import { logEvent } from '../helpers/analytics.js'

import memoize from "memoize-one"

import { FreckleTooltip } from './FreckleTooltip'
import Help from './Help'

import styled, { css, keyframes } from 'styled-components'

var moment = require('moment');

const DetailsButtonIcon = styled.img`
  max-width: 20px;
  opacity: 0.5;
  pointer-events: none;
`

const DetailsButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 1000px;
  height: 40px;
  width: 40px;
  transition: 0.2s;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;

  &:hover {
    background-color: #4f4f4f;
  }
`


export default class LibraryPane extends Component {

  constructor(props, context) {
    super(props, context);

    this.menuButtonRef = React.createRef()
    
    this.state = {
      video: null,
      fetchingPublishedVideos: false,
      publishing: false,
      publishedVideos: [],
      selectedTemplate: null,
      showProducts: true,
      showMicrosites: true,
    }
  }

  componentDidMount() {
    // this.getPublishedVideos()
  }

  static getDerivedStateFromProps(props, state) {
   if(props.video && (!state.video || state.video.id !== props.video.id)) {
      return {video: props.video, publishedVideos: []}
    }
    return null
  }

  copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  getPlayHeader = () => {
    if(this.state.video === null) return <span/>
    return(
      <div className="pane-header-container">
        <img className="video-poster" src={`https://image.mux.com/${this.state.video.muxPlaybackId}/thumbnail.jpg?width=500&height=500`}/>
        <div className="pane-header-details">
          <div className="pane-header-text">
            <div className="name">
              { this.state.video.title }
            </div>
          </div>
          <div className="updated-label">Last updated {moment(this.state.video.updatedAt).fromNow()}</div>
        </div>
        <div className="right">
          <DetailsButton 
          onClick={()=> this.setState({showingMenu: true}) }
          ref={this.menuButtonRef}>
          <DetailsButtonIcon src="/menu-icon.svg"/>
          </DetailsButton>

          <Menu
          className="freckle-menu"
          anchorEl={this.menuButtonRef.current}
          open={this.state.showingMenu}
          onClose={()=> this.setState({showingMenu: false}) }
          >
          <MenuItem
          fullWidth
          className="freckle-menu-item"
          onClick={this.props.onEdit}>Edit Interactive Video</MenuItem>
          <MenuItem
          className="freckle-menu-item red"
          onClick={()=>{
            this.setState({showingMenu: false})
            this.props.onArchive()
          }}>Delete</MenuItem>
          </Menu>

        </div>
      </div>
    )
  }

  productsList = () => {
    const { video } = this.state
    if(!!!video) return <span/>

    const products = JSON.parse(video.data)
    return(
      <div className="products-list">
      <div className="section-header" onClick={()=>{ 
        this.setState({showProducts: !this.state.showProducts})
      }}>
      <img className="icon" src="/products-icon-library.svg"/>
      <label>Tagged Products</label>
      <div className={`arrow ${this.state.showProducts?'down':'up'}`}/>
      </div>
      <Collapse isOpened={this.state.showProducts}>
      <div className="items">
      {
        products.map(product => {
          return (
            <div className="item" key={product.id}>
            <img src={product.imageUrl}/>
            <h4>{ product.title }</h4>
            </div>
          )
        })
      }
      </div>
      </Collapse>
      </div>
    )
  }

  micrositesList = () => {
    const { video } = this.state
    if(!!!video) return <span/>

    const { microsites } = video
    return(
      <div className="products-list">
      <div className="section-header" onClick={()=>{ 
        this.setState({showMicrosites: !this.state.showMicrosites})
      }}>
      <img className="icon" src="/templates-icon-library.svg"/>
      <label>Linked Microsites</label>
      <div className={`arrow ${this.state.showMicrosites?'down':'up'}`}/>
      </div>
      <Collapse isOpened={this.state.showMicrosites}>
      <div className="items">
      {
        microsites.map(microsite => {
          return (
            <div 
            onClick={()=>{
              this.props.history.push(`/microsite/${microsite.id}`)
            }}
            className="microsite-item" 
            key={microsite.id}>
            <h4>{ microsite.title }</h4>
            <h5><img style={{float: 'left', opacity: '0.5', 'marginRight': '5px', height: '10px'}} src="/clock.svg"/> { !!microsite.publishedAt ? `Last Published ${moment(microsite.publishedAt).fromNow()}` : "Not Published" }</h5>
            </div>
          )
        })
      }
      </div>
      </Collapse>
      </div>
    )
  }

  render(){
    return(
      <div className="library-pane-container">
        { this.getPlayHeader() }

        <div className="details">
          <Button invert onClick={this.props.onEdit} margin='0 20px 0 20px'><img src="/edit-icon.svg"/> Edit Interactive Video</Button>
          <div className="video-options-list">
            { this.productsList() }
            { this.micrositesList() }
          </div>
        </div>

      </div>      
    )
  }

}
