import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const ResizablePaneContainer = styled.div`
width: 100%;
min-height: 20vh;
max-height: 80vh;
height: ${props=>props.height + "px"};
position: relative;
`

const TopHandle = styled.div`
position: absolute;
width: 100%;
background-color: transparent;
height: 8px;
top: 0px;
left: 0px;
z-index: 10000;
cursor: row-resize;`

export default function ResizablePane(props){
	const [height, setHeight] = useState(props.defaultHeight || 300);

	const [dragState, setDragState] = useState(null);

	useEffect(()=>{
		const handleMouseMove = (e)=>{
			if (!dragState) return
			const newHeight = dragState.height + (dragState.y - e.clientY);
			setHeight(newHeight);
		};

		const handleMouseUp = (e)=>{
			if (!dragState) return
			setDragState(null);
		};

		document.addEventListener('mousemove', handleMouseMove, false);
		document.addEventListener('mouseup', handleMouseUp, false);

		return ()=>{
			document.removeEventListener('mousemove', handleMouseMove, false);
			document.removeEventListener('mouseup', handleMouseUp, false);
		}
	}, [dragState])

	return (
		<ResizablePaneContainer
		height={height}
		style={{
			pointerEvents: !!dragState ? 'none' : 'auto',
			userSelect: !!dragState ? 'none' : 'auto',
		}}
		{...props}>
		<TopHandle
		onMouseDown={(e)=>{
			setDragState({
				y: e.clientY,
				height: height,
			});
		}}/>
		{ props.children }
		</ResizablePaneContainer>
	)
}