import React, { Component } from 'react';
import Header from './Header'
import LibraryItem from './LibraryItem'
import LibraryPane from './LibraryPane'
import AddVideo from './AddVideo'
import EmbedVideo from './EmbedVideo'
import Settings from './Settings'
import Library from './Library'
import Templates from './Templates'
import AccountPicker from './AccountPicker'
import ProductsManagerView from './ProductsManagerView'
import { BarLoader, SyncLoader } from "react-spinners";

import AddStoreView from './AddStoreView'

import * as Auth from '../modules/auth'

import Dashboard from './Analytics/Dashboard'

import LoadingView from './LoadingView'

import { logEvent } from '../helpers/analytics.js'

import { allVideos, createVideo, deleteVideo, subscribeToVideoChanges, publishVideo } from '../modules/freckle-api.js'

import { CSSTransition } from 'react-transition-group'

import { withRouter } from 'react-router-dom'

import Button from './Button'

import styled from 'styled-components';

import { AppContext } from './Context/AppContext'

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";

import '../../styles/Home.scss';

export const SETTINGS_PAGE = "SETTINGS_PAGE"
export const PRODUCTS_PAGE = "PRODUCTS_PAGE"
export const VIDEOS_PAGE = "VIDEOS_PAGE"

const LogoContainer = styled.div`
padding-bottom: 25px;
padding-top: 25px;
display: flex;
justify-content: flex-start;
margin-left: 60px;
`

function Logo(props){
  return(
    <LogoContainer>
    <img src="/freckle-logo.svg"/>
    </LogoContainer>
  )
}

export default class Home extends Component {
  static contextType = AppContext

  constructor(props, context) {
    super(props, context);

    this.state = {
      addingVideo: false,
      ready: false,
    }
  }

  async componentDidMount() {
    const user = await this.context.fetchUser(true)
    this.setState({ready: true})
  }

  closeOverlay = (e) => {
    if(e.target.className === "overlay-pane"){
      this.setState({addingVideo: false})
    }
  }

  render() {

    if(!this.state.ready) {
      return(
        <LoadingView/>
      )
    }

    const { user } = this.context.state

    const overlayClass = (this.state.addingVideo) ? "overlay-pane" : "overlay-pane hidden"
    return(
      <div className="library-container">
        <div className="main-content"> 

          <div className="lib-left-pane">

            <Logo/>

            <AccountPicker/>

            <div className="gutter-list">
              <div className={(this.props.location.pathname.includes('/videos')) ? "item selected" : "item"} onClick={()=>{ this.props.history.push('/videos') }}>
                <img src="/videos-icon.svg"/>
                Videos
              </div>
              <div className={(this.props.location.pathname.includes('/pages')) ? "item selected" : "item"} onClick={()=>{ this.props.history.push('/pages') }}>
                <img style={{marginBottom:"0px", transform:"scale(0.9)"}} src="/templates-icon-library.svg"/>
                Landing Pages
              </div>
              <div className={(this.props.location.pathname.includes('/products')) ? "item selected" : "item"} onClick={()=>{ this.props.history.push('/products') }}>
                <img style={{marginBottom:"2.5px"}} src="/products-icon-library.svg"/>
                Products
              </div>
              <div className={(this.props.location.pathname.includes('/analytics')) ? "item selected" : "item"} onClick={()=>{ this.props.history.push('/analytics') }}>
                <img style={{marginBottom:"2.5px"}} src="/analytics-icon-library.svg"/>
                Analytics
              </div>
              <div className={(this.props.location.pathname.includes('/settings')) ? "item selected" : "item"} onClick={()=>{ this.props.history.push('/settings') }}>
                <img style={{marginBottom:"0px"}} src="/settings-icon.svg"/>
                Settings
              </div>
            </div>

            <div className="footer">
            <p>Welcome, { user && user.firstName }</p>

            <Button 
            color="#7f7f7f"
            backgroundColor="#2f2f2f"
            hoverBackgroundColor="#E73C7E"
            hoverTextColor="white"
            onClick={Auth.signOut}>
            Logout
            </Button>
            </div>

          </div>

          <div className={overlayClass} onClick={this.closeOverlay}>
            <CSSTransition in={this.state.addingVideo} timeout={500} classNames="add-video" unmountOnExit>
              <AddVideo onCancel={()=>{ this.setState({addingVideo: false}) }} onAdded={()=>{ this.setState({addingVideo: false}) }}/>
            </CSSTransition>
          </div>

          <div className="home-content-pane">
            <Switch>
              <Route exact path="/">
                <Redirect to="/videos" />
              </Route>
              <Route path={`/videos`} component={Library} />
              <Route path={`/settings`} component={Settings} />
              <Route path={`/products`} component={ProductsManagerView} />
              <Route path={`/pages`} component={Templates} />
              <Route path={`/analytics`} component={Dashboard}/>
            </Switch>
          </div>
          

        </div>
      </div>  
    )
  }

}

