import localforage from "localforage"
import axios from 'axios'
import moment from 'moment'
import * as Sentry from "@sentry/react";

export async function isShopifyStore(store) {
  const url = `https://` + store + `/products.json`
  try {
    const result = await axios.get(url)
    return result.status < 300
  } catch (e) {
    throw e
  }
}

export async function syncProducts(store, force=false, callback) {
  const shopifyUrl = store.domain
  console.log("START STORE SYNC " + shopifyUrl)

  const lastSyncDate = await localforage.getItem(`${shopifyUrl}-sync-date`)

  localforage.getItem(`synced-products-${shopifyUrl}`, async (err, value) => {
    if(!err && value) {
      const localData = JSON.parse(value)
      callback({cached: true, data: localData}, null)
    } else {
      callback({cached: true, data: []}, null)
    }

    const threshDate = moment().subtract(1, 'days')
    const lastSyncDateWrap = moment(lastSyncDate)
    const cacheBusted = lastSyncDateWrap.valueOf() < threshDate.valueOf()

    if((force || cacheBusted) || err || !!!value){
      console.log(`remote syncing ${shopifyUrl} | ${force} | ${err} | ${!!value}`)
      if(shopifyUrl === null){
        callback({cached: false, data: []}, new Error("No shopify store URL specified.")) 
        return
      }
      var offset = 1
      
      var allProducts = []
      while(true){
        const batchUrl = `https://` + shopifyUrl + `/products.json?limit=200&page=${offset}`

        try {
          const results = await axios.get(batchUrl)
          if(results && results.data.products) {
            const products = results.data.products.map((product)=>{

              let sizedImageUrl, fullSizedImageUrl
              try {
                fullSizedImageUrl = product.images[0].src
                const imageComps = product.images[0].src.split("/")
                const fileComps = imageComps[imageComps.length-1].split(".")
                sizedImageUrl = imageComps.slice(0,-1).join("/") + "/" + fileComps.slice(0,-1).join(".") + "_400x400." + fileComps[fileComps.length-1]
              } catch(e) {
                sizedImageUrl = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                fullSizedImageUrl = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                Sentry.captureException(e);
              }

              const newProd = {
                id: product.id.toString(),
                title: product.title,
                price: product.variants[0].price,
                currency: 'USD',
                linkUrl: `https://` + shopifyUrl + `/products/${product.handle}`,
                imageUrl: sizedImageUrl,
                fullSizeImageUrl: fullSizedImageUrl,
                skus: product.variants.map(v=>v.sku)
              }
              return newProd
            })

            if(products.length > 0){
              allProducts.push(...products)
            } else {
              localforage.setItem(`synced-products-${shopifyUrl}`, JSON.stringify(allProducts), function (err) {})
              localforage.setItem(`${shopifyUrl}-sync-date`, new Date(), function (err) {})
              console.log("done syncing products")
              callback({cached: false, data: allProducts}, null)
              break
            }
          } else {
            callback({cached: false, data: []}, new Error(`${shopifyUrl} isn't a Shopify store.  Sync failure.`))
            return
          }

        } catch(error) {
          Sentry.captureException(error);
          callback({cached: false, data: []}, new Error(`${shopifyUrl} is not a Shopify store.  Sync failed.`))
          return
        }

        offset += 1
      }
      
    }

  })
}