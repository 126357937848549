import React, { Component, useRef, useState } from 'react';
import TextInput from './TextInput'
import { PropagateLoader } from "react-spinners"
import { BarLoader, PulseLoader } from "react-spinners";
import { CSSTransition } from 'react-transition-group'
import localforage from "localforage"
import { updateUser } from '../modules/auth'
import * as API from '../modules/freckle-api'
import { AppContext, AppAction } from './Context/AppContext'
import Button from './Button'
import styled from 'styled-components'
import { Menu, MenuItem } from '@material-ui/core'
import { FreckleTooltip } from './FreckleTooltip'
import Modal from './Modal'
import AddStore from './AddStore'

import '../../styles/Settings.scss';

const H2 = styled.h2`
padding: 0;
margin: 0;
margin-bottom: 20px;
`
const P = styled.p`
font-size: 16px;
padding: 0;
margin: 0;
margin-top: 5px;
`

const StoreList = styled.div`
display: flex;
flex-direction: column;
border-top: 1px solid #111;
background-color: #1a1a1a;
border-radius: 8px;
overflow: hidden;
box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
`
const StoreItemContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
height: 55px;
padding: 5px 15px;
transition: 0.2s;

border-bottom: 1px solid #111;
`
const StoreItemSection = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
`
const DetailsButtonIcon = styled.img`
  max-width: 20px;
  opacity: 0.5;
  pointer-events: none;
`
const DetailsButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 1000px;
  height: 40px;
  width: 40px;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;

  &:hover {
    background-color: #4f4f4f;
  }
`
const StoreItemLogo = styled.img`
height: 30px;
width: 30px;
background-color: white;
`

const StoreItemTitle = styled.div`
color: white;
margin-left: 15px;
margin-right: 15px;
`
const BadgeContainer = styled.div`
padding: 5px 10px;
border-radius: 4px;
background-color: ${props=> props.active ? "#0a3b00" : "#1f1400"};
color: ${props=> props.active ? "#2bff00" : "#ffa600"};
font-weight: 700;
font-size: 12px;
display: flex;
justify-content: center;
align-items: center;
vertical-align: baseline;
user-select: none;
margin: 0 10px 0 20px;
`
const BadgeIcon = styled.div`
height: 6px;
width: 6px;
border-radius: 100px;
margin-right: 5px;
background-color: ${props=> props.active ? "#2bff00" : "#ffa600"};
`

const InfoLink = styled.a`
color: white !important;
`

const CodeTable = styled.table`
background-color: #1f1f1f;
padding: 10px 20px;
margin: 20px 0;
text-align: left;
`
const CodeTr = styled.tr`
`
const CodeTh = styled.th`
padding: 5px 20px 5px 0;
`
const CodeTd = styled.td`
padding: 5px 20px 5px 0;
font-family: courier;
`

const Section = styled.div`
padding-bottom: 35px;
p {
  margin: 0;
  padding: 0;
  opacity: 0.5;
}
`
const SectionHeader = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 20px;
padding: 15px 0 25px 0;
`


function StoreItem(props){
  const { store } = props
  const menuButtonRef = useRef(null)
  const [showingMenu, setShowingMenu] = useState(false)

  return(
    <StoreItemContainer>
      <StoreItemSection>
        <StoreItemLogo src={`https://www.google.com/s2/favicons?sz=128&domain_url=${store.domain}`}/>
        <StoreItemTitle>{ store.domain }</StoreItemTitle>
        {!store.isDomainLinked && <Button small backgroundColor="#3f3f3f" onClick={props.onLinkDomain}>ADD DOMAIN</Button>}
      </StoreItemSection>
      <StoreItemSection>
        <DetailsButton 
        onClick={()=> setShowingMenu(true)}
        ref={menuButtonRef}>
          <DetailsButtonIcon src="/menu-icon.svg"/>
        </DetailsButton>
      </StoreItemSection>

      <Menu
      className="freckle-menu"
      anchorEl={menuButtonRef.current}
      open={showingMenu}
      onClose={()=>setShowingMenu(false)}
      >
      {!store.isDomainLinked && <MenuItem
      className="freckle-menu-item"
      onClick={()=>{
        props.onLinkDomain()
        setShowingMenu(false)
      }}>Add Domain</MenuItem>}
      {store.isDomainLinked && <MenuItem
      className="freckle-menu-item"
      onClick={()=>{
        setShowingMenu(false)
      }}>Remove Domain</MenuItem>}

      <MenuItem
      className="freckle-menu-item red"
      onClick={()=>{
        props.onDelete()
        setShowingMenu(false)
      }}>Delete</MenuItem>
      </Menu>

    </StoreItemContainer>
  )
}


export default class Settings extends Component {
  static contextType = AppContext

  constructor(props, context) {
    super(props, context);

    this.form = React.createRef();

    this.state = {
      user: null,
      settingsEdited: false,
      saving: false,
      addingStore: false,
      storeToLink: null,
      linkingDomain: false
    }
  }

  componentDidMount() {
    this.fetchAccountDetails()
  }

  getPlanName = () => {
    if(this.context.state.user === null) return "-"
    return this.context.state.user.plan
  }

  fetchAccountDetails = async (force) => {
    const _user = await this.context.fetchUser({bypassCache: true})
    this.setState({
        saving: false,
        settingsEdited: false
      })
  }

  getAccountPanel = () => {
    if(this.context.state.user === null){
      return <PulseLoader size="5" color="#444444"/>
    }

    return(
      <div className="account-pane">
        <div className="account-item">
          <label>Name</label> 
          <div className="item-value">{ this.context.state.user.firstName + " " + this.context.state.user.lastName }</div>
        </div>
        <div className="account-item">
          <label>Email</label> 
          <div className="item-value">{ this.context.state.user.email }</div>
        </div>
        <div className="account-item">
          <label>Plan</label> 
          <div className="item-value">
          {this.getPlanName()}
          <Button small backgroundColor="#3f3f3f" onClick={()=> this.props.history.push("/pickplan")}>SWITCH PLAN</Button>
          </div>
        </div>
      </div>
    )
  }

  formChanged = (e) => {
    if(!this.state.settingsEdited) {
      this.setState({settingsEdited: true})
    }
  }

  cancelEdit = () => {
    this.setState({
      settingsEdited: false
    })
    this.form.current.reset()
  }

  extractHostname = (url) => {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  removeStore = (storeToRemove) => {
    if(this.context.state.user.stores.length === 1){
      document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":"Your account must have at least one linked store at a time.", "type":"error"}}))
      return
    }
    this.setState({saving: true}, async ()=>{
      await API.deleteStore(storeToRemove)
      await this.fetchAccountDetails(true)
      this.context.bootstrapSelectedStore()
      this.setState({saving: false})
    })
  }

  activateStore = (storeToActivate) => {
    this.setState({saving: true}, async ()=>{
      try {
        await API.updateStore(storeToActivate, !storeToActivate.isActive)
      } catch(e) {
        document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":e.response.data.message, "type":"error"}}))
      }
      this.fetchAccountDetails(true)
      this.setState({saving: false})
    })
  }

  closeOverlay = (e) => {
    if(e.target.className === "overlay-pane"){
      this.setState({addingStore: false})
    }
  }

  render(){
    return(
      <div className="settings-pane">
        <div className="settings-header">
          <h2>Settings</h2>
        </div>

        <div className="settings-content-pane">
          <Section>
            <SectionHeader>
            <h4>Account</h4>
            </SectionHeader>
            { this.getAccountPanel() }
          </Section>

          <Section>
            <SectionHeader>
            <h4>Billing</h4>
            </SectionHeader>
            {!!this.context.state.user && !!this.context.state.user.config.enterprise && <p>
              You are on an Enterprise plan.  Contact your account manager for details.
            </p>}
            {!!this.context.state.user && !!!this.context.state.user.config.enterprise && <Button onClick={()=>{
              if(!!this.context.state.user.lastPaidAt){
                API.createCustomerPortalSession().then(data=>{
                  const redirUrl = data.portal_url
                  window.location = redirUrl
                })
              }
            }}>
            Manage Billing Details
            </Button>}
          </Section>

          <Section>
            <SectionHeader>
            <h4>Linked Stores</h4>
            <Button small invert onClick={()=> this.setState({addingStore: true})}>Add Store</Button>
            </SectionHeader>
            <StoreList>
            {
              !!this.context.state.user && this.context.state.user.stores.sort((a,b)=> new Date(b.createdAt) - new Date(a.createdAt)).map(store => {
                return <StoreItem 
                onDelete={()=>{
                  this.removeStore(store)
                }}
                onActivate={(active)=> this.activateStore(store, active) }
                onLinkDomain={()=> this.setState({storeToLink: store})}
                store={store}/>
              })
            }
            </StoreList>
          </Section>

        </div>

        <AddStore
        user={this.context.state.user}
        display={this.state.addingStore}
        onCancel={()=> this.setState({addingStore: false})}
        onDone={()=> {
          this.setState({addingStore: false}, ()=>{
            this.fetchAccountDetails()
          })
        }}/>

        <Modal 
        display={!!this.state.storeToLink}
        okayText="Add Domain"
        loading={this.state.linkingDomain}
        onOkay={()=>{
          this.setState({linkingDomain: true}, ()=>{
            API.linkStoreDomain(this.state.storeToLink).then((resp)=>{
              this.setState({storeToLink: null, linkingDomain: false})
              this.fetchAccountDetails()
            }).catch((e)=>{
              document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text":"Your CNAME record is not properly configured.", "type":"error"}}))
              this.setState({linkingDomain: false})
            })
          })
        }}
        onCancel={()=>{
          this.setState({storeToLink: null, linkingDomain: false})
        }}>
        <H2>Add Your Domain</H2>
        Once your domain is added, customers will be able to access your landing pages via https://watch.{this.state.storeToLink && this.state.storeToLink.domain}.
        <br/><br/>
        To start, <InfoLink target="_blank" href="https://www.godaddy.com/help/add-a-cname-record-19236">add the following CNAME record in your DNS settings.</InfoLink>

        <CodeTable>
        <CodeTr>
          <CodeTh>Type</CodeTh><CodeTh>Name</CodeTh><CodeTh>Value</CodeTh>
        </CodeTr>
        <CodeTr>
          <CodeTd>CNAME</CodeTd><CodeTd>watch</CodeTd><CodeTd>freckle-microsite.b-cdn.net</CodeTd>
        </CodeTr>
        </CodeTable>

        Once you've configured your new CNAME, click ADD DOMAIN to connect it to your Freckle account.
        </Modal>

        <CSSTransition in={this.state.settingsEdited} timeout={500} classNames="add-video" unmountOnExit>
          <div className="footer-menu">
            <button className="cancel-button" onClick={this.cancelEdit}>CANCEL</button>
            <button className="save-button"  onClick={this.saveEdit}>SAVE</button>
          </div>
        </CSSTransition>

        <div className={this.state.saving ? "saving-overlay" : "saving-overlay hidden"}>
          <BarLoader color="#4A90E2"/>
        </div>
      </div>
    )
  }

}

/*
<div className="store-list-container">
            {
              !!this.context.state.user && this.context.state.user.stores.map(store => {
                return <div className="item" key={store.domain}>
                <img src={`https://www.google.com/s2/favicons?sz=128&domain_url=${store.domain}`}/>
                { store.domain }
                <div className="delete" onClick={()=>{this.removeStore(store)}}>✖</div>
                </div>
              })
            }
            <div className="item add" onClick={()=>{ this.setState({addingStore: true}) }}>
            <div className="placeholder-image"/>
            ADD A NEW STORE
            </div>
            </div>
*/
