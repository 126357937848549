import React, { useState, useRef, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { getTemplate, updateTemplate, publishMicrosite } from '../../modules/freckle-api'
import Header from '../Header'
import SwatchColorPicker from '../SwatchColorPicker'
import FontPicker from '../FontPicker'
import ProjectItem from './ProjectItem'
import FilePicker from './FilePicker'
import GridSelector from './GridSelector'
import TextInput from '../TextInput'
import Slider from '../Slider'
import { ButtonIcon } from './Icons'
import { CartIcon } from './Icons'
import { AlignIcon } from './Icons'
import { hash } from '../../helpers/PayloadHasher'
import Help from '../Help'
import Checkbox from '@material-ui/core/Checkbox'
import ProductsList from './ProductsList'
import ProductsPicker from './ProductsPicker/ProductsPicker'
import PickerMenu from '../PickerMenu'
import { CSSTransition } from 'react-transition-group'
import { AppContext, AppAction, useAppContext } from '../Context/AppContext'
import { MicrositeTemplates } from './templates'
import Switch from '../Switch'


const VIEW_MODES = Object.freeze({
	mobile: 'mobile',
	desktop: 'desktop',
});

const ViewModePickerContainer = styled.div`
width: 120px;
height: 50px
display: flex;
flex-direction: row;
align-items: center;
flex-direction: center;
position: absolute;
top: 20px;
left: calc(50% - 60px);
z-index: 100;
border-radius: 6px;
overflow: hidden;`

const ModeButton = styled.button`
width: 50%;
height: 50px;
border: none;
outline: none;
cursor: pointer;
transition: 0.15s;

img {
	height: 20px;
	width: 20px;
	object-fit: contain;
}

background-color: ${props=>{
	if (props.buttonMode === props.mode) {
		return `#4f4f4f`;
	}
	return '#1f1f1f';
}};

&:hover {
background-color: ${props=>{
	if (props.buttonMode === props.mode) {
		return `#4f4f4f`;
	}
	return '#2f2f2f';
}}`

const ViewModePicker = (props) => {
	return (
		<ViewModePickerContainer>
			<ModeButton 
			buttonMode={VIEW_MODES.mobile}
			onClick={()=> props.onChange(VIEW_MODES.mobile)}
			{...props}>
			<img src="/mobile-mode.svg"/>
			</ModeButton>
			<ModeButton 
			buttonMode={VIEW_MODES.desktop} 
			onClick={()=> props.onChange(VIEW_MODES.desktop)}
			{...props}>
			<img src="/desktop-mode.svg"/>
			</ModeButton>
		</ViewModePickerContainer>
	)
}

const ButtonStyle = {
	square: "square",
	rounded: "rounded",
	pill: "pill",
}

const LogoPosition = {
	center: "center",
	left: "left",
}

const EnterAnimation = keyframes`
	0%{ opacity: 0; }
	100%{ opacity: 1; }
`

const Container = styled.div`
	animation: ${EnterAnimation} 1000ms normal forwards;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	font-family: "Signika";
	display: flex;
	flex-direction: column;
`

const EditingContext = styled.div`
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: row;
`

const LeftPane = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

const RightPane = styled.div`
 	flex: 0;
 	min-width: 450px;
 	height: calc(100vh - 65px);
 	background-color: #1f1f1f;
 	overflow: scroll;
`

const SectionContainer = styled.div`
	width: calc(100% - 20px);
	padding-left: 15px;
	border-bottom: 3px solid #111111;
	position: relative;
	transition: 0.4s;

	max-height: ${props=>{
		if (!props.height) return '0px'
		return props.open ? (props.height+'px'):'0px';
	}};
	padding-bottom: ${props=>{
		return props.open ? '25px':'0px';
	}};
	overflow: ${({ transitionState }) => (transitionState === "entered" ? 'visible' : 'hidden')};;
`

function Section(props) {
	const [open, setOpen] = useState(props.defaultOpen)
	const contentRef = useRef(null);

	let fullHeight = 0
	if (contentRef.current) {
		fullHeight = contentRef.current.scrollHeight
	}

	return(
		<React.Fragment>
		<SectionHeader onClick={()=>{
			setOpen(!open)
		}}>
		{ props.name }
		{props.helpCaption && <Help arrow title={props.helpCaption}/>}
		</SectionHeader>

		{
			/*TODO: finish transitioning to CSSTransition.  dont hide overflow, blocks dropdowns.*/
		}
		<CSSTransition
            in={open}
            timeout={250}>
            {(transitionState) => {
				return <SectionContainer 
				ref={contentRef} 
				height={fullHeight}
				open={open}
				transitionState={transitionState}>
				{ props.children }
				</SectionContainer>

			}}
		</CSSTransition>
		</React.Fragment>
	)
}

const ShimSection = styled.div`
	height: 300px;
`

const SectionHeader = styled.div`
	width: calc(100% - 15px);
	padding-left: 15px;
	height: 50px;
	background-color: #1b1b1b;
	color: #eeeeee;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 700;
	box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
	cursor: pointer;
	transition: background-color 0.25s;

	&:hover {
		background-color: #222;
	}
`

const SectionSubHeader = styled.div`
	width: 100%;
	height: 30px;
	background-color: transparent;
	color: #7f7f7f;
	display: flex;
	align-items: center;
	font-size: 13px;
	font-weight: 700;
	padding-top: 10px;
	padding-bottom: 5px;
`

const MicrositeFrame = styled.iframe`
	outline: none;
	border: none;
	background-color: white;
	box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.95);
	min-height: 500px;
	// transition: 0.5s;

	${props=>{
		if (props.mode == VIEW_MODES.mobile) {
return `
height: calc(100% - 200px);
max-height: 900px;
margin-top: 80px;
width: calc(0.55 * 70vh);
min-width: calc(0.55 * 500px);
max-width: 500px;`
		} else {
return `
height: calc(100% - 100px);
width: calc(100% - 20px);
min-width: 800px;
margin-top: 90px;`
		}
	}};

	box-shadow: rgba(0,0,0,0.15) 0 10px 30px 10px;
	border-radius: 5px;
`

const Shim = styled.div`
	height: 20px;
`

const CheckboxLineItem = styled.div`
	display: inline-block;
	margin-left: -5px;
	padding: 0;
	padding-bottom: 10px;
	padding-right: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
`

const CheckboxLabel =  styled.span`
	font-family: Signika;
	font-weight: 700;
	font-size: 14px;
	color: #9f9f9f;
`

const OverlayContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.2s;
	pointer-events: ${props=> !!props.active ? 'auto':'none'};
	background-color: ${props=> !!props.active ? 'rgba(0,0,0,0.7)':'transparent'};
`

export default function MicrositeEditor(props) {
	const [microsite, setMicrosite] = useState(null)
	const [selectedColorPicker, setSelectedColorPicker] = useState(null)
	const [styleData, setStyleData] = useState(null)
	const [videoData, setVideoData] = useState(null)

	const [saving, setSaving] = useState(false)
	const [updatedAt, setUpdatedAt] = useState(null)

	const [publishing, setPublishing] = useState(false)
	const [publishedAt, setPublishedAt] = useState(null)

	const [clearedMicrosite, setClearedMicrosite] = useState(false)

	const [lastSaveHash, setLastSaveHash] = useState(null)
	const [lastPublishHash, setLastPublishHash] = useState(null)

	const [videoProducts, setVideoProducts] = useState([])

	const [editingProducts, setEditingProducts] = useState(false)

	const [mode, setMode] = useState(VIEW_MODES.mobile);

	const iframeRef = useRef(null)

	const appContext = useAppContext()

	const updateProjectSchema = (inputData, defaultData) => {
		const data = {...inputData}
		const expectedKeys = Object.keys(defaultData)
		expectedKeys.forEach(key => {
			if(!(key in data)) {
				data[key] = defaultData[key]
			} else if (data[key] !== null && typeof data[key] === 'object' && data[key] !== null && Object.keys(data[key]).length > 0) {
				data[key] = updateProjectSchema(data[key], defaultData[key])
			} else if(data[key] === null) {
				data[key] = defaultData[key]
			}
		})
		return data
	}

	useEffect(()=>{
		if(!!microsite){
			const _videoData = generateVideoData(microsite.project)
			setVideoData(_videoData)
			setVideoProducts(_videoData.products)
		}
	}, [appContext.state.selectedStore])

	const reloadMicrosite = (clearCart=false) => {
		if(!videoData) {
			return
		}

		const payload = {
			styleData: styleData,
			videoData: videoData,
			clearCart
		}

		iframeRef.current.contentWindow.postMessage(payload, "*")
	}

	useEffect(()=>{
		reloadMicrosite()
	}, [styleData])

	useEffect(()=>{
		reloadMicrosite()
	}, [videoData])

	useEffect(()=>{
		getTemplate(props.match.params.micrositeId).then(async _microsite=>{
			setMicrosite(_microsite)

			if(appContext.state.selectedStore){
				const _videoData = generateVideoData(_microsite.project)
				setVideoData(_videoData)
				setVideoProducts(_videoData.products)
			}

			if(!!_microsite.publishedAt) setPublishedAt(new Date(_microsite.publishedAt))
			if(!!_microsite.publishChecksum) setLastPublishHash(_microsite.publishChecksum)

			setUpdatedAt(new Date(_microsite.updatedAt))

			const _styleData = updateProjectSchema(JSON.parse(_microsite.data), MicrositeTemplates[_microsite.data.name] || MicrositeTemplates.tempo)

			// setStyleData(MicrositeTemplates.bingBong)
			setStyleData(_styleData)

			setLastSaveHash(_microsite.saveChecksum)
		})
	}, [])

	useEffect(()=>{
		const timer = setInterval(saveTemplate, 1000)

		return () => {
			clearTimeout(timer);
		};
	})

	const generateVideoData = (project) => {
		const productsPayload = (JSON.parse(project.data) || []).map((freckle)=>{
			const manifestItem = {
				"id": freckle.id,
	          "gqlid": freckle.gqlid,
				"title": freckle.title,
				"price": freckle.price,
				"currency": 'USD',
				"imageUrl": freckle.imageUrl,
				"fullSizeImageUrl": freckle.fullSizeImageUrl,
				"linkUrl": freckle.linkUrl,
				"properties": freckle.properties,
				"locs": freckle.locations.map((loc)=> [loc.ts, loc.t, parseFloat(loc.x.toFixed(4)), parseFloat(loc.y.toFixed(4))] ),
				"ranges": freckle.ranges, 
				"itemType": freckle.itemType,
			}
			return manifestItem
		})
		const manifest = {
			"videoId": project.uuid,
			"storeUrl": project.shopUrl,
			"storeType":  appContext.state.selectedStore ? appContext.state.selectedStore.storeType : null,
			"products": productsPayload
	    }

	    return manifest
	}

	const micrositeUrl = () => {
		return `https://w.freckle.shop/preview`
		// return `http://localhost:1234/preview`
	}

	const updateSetting = (value, key) => {
		const keyComps = key.split(".")
		const data = {...styleData}
		let focused = data

		keyComps.slice(0,keyComps.length-1).forEach(comp => {
			focused = focused[comp]
		})
		focused[keyComps[keyComps.length-1]] = value

		setStyleData(data)
		setSelectedColorPicker(key)

		// reloadMicrosite()
	}

	const saveFromVisibility = async () => {
		if(document.visibilityState !== 'visible') await saveTemplate()
	}

	const saveTemplate = async () => {
		if(saving || !!!microsite || !!!styleData || !!!lastSaveHash) return

		const newHash = await hash(styleData)
		if(newHash === lastSaveHash) return 

		setSaving(true)
		const resp = await updateTemplate(microsite.id, {data: styleData})
		setSaving(false)

		setUpdatedAt(new Date())

		setLastSaveHash(newHash)
	}

	const settingsPane = () => {
		if(!!!styleData || !!!microsite) return <span/>
		return (
			<React.Fragment>
			<SectionHeader>Linked Video</SectionHeader>
			<ProjectItem project={microsite.project}/>

			<Section name="Page" helpCaption="Basic website settings.">
			<TextInput
	              name="pageTitle" 
	              label="Page Title"
	              help="The title of the page that appears in the tab or header of the browser window."
	              defaultValue={styleData.pageTitle}
	              onChange={(e)=>{
	              	updateSetting(e.target.value, "pageTitle")
	              }}/>


				<SectionSubHeader>
				Background Color
				<Help arrow title="Sets background color of the page."/>
				</SectionSubHeader>
				<SwatchColorPicker 
				property="backgroundColor" 
				color={styleData.backgroundColor} 
				selectedProperty={selectedColorPicker}
				onChange={updateSetting}/>
			</Section>

			<Section name="Video Player" helpCaption="Options for player display and behavior.">
			<SectionSubHeader>
				Playback
				<Help arrow title="Options dictating how the video is displayed and played within the microsite."/>
			</SectionSubHeader>
			<CheckboxLineItem>
				<Switch
				small
				enabled={styleData.playback.autoplay}
				onChange={val=>{
					updateSetting(val, "playback.autoplay")
				}}
				style={{color:'white'}}
				size="small"
				name="checked"
				/>
				<CheckboxLabel>Autoplay</CheckboxLabel>
			</CheckboxLineItem>

			<CheckboxLineItem>
				<Switch
				small
				enabled={styleData.playback.loop}
				onChange={val=>{
					updateSetting(val, "playback.loop")
				}}
				style={{color:'white'}}
				size="small"
				name="checked"
				/>
				<CheckboxLabel>Loop</CheckboxLabel>
			</CheckboxLineItem>

			<SectionSubHeader>
				Hotspot Display
				<Help arrow title="Options dictating how hotspots appear during playback."/>
			</SectionSubHeader>
			{
				/*
			<CheckboxLineItem>
				<Switch
				small
				enabled={styleData.playback.pauseToShopEnabled}
				onChange={val=>{
					updateSetting(val, "playback.pauseToShopEnabled")
				}}
				style={{color:'white'}}
				size="small"
				name="checked"
				/>
				<CheckboxLabel>Hide hotspots when video is playing</CheckboxLabel>
			</CheckboxLineItem>*/
		}
			<CheckboxLineItem>
				<Switch
				small
				enabled={styleData.playback.animateHotspots}
				onChange={val=>{
					updateSetting(val, "playback.animateHotspots")
				}}
				style={{color:'white'}}
				size="small"
				name="checked"
				/>
				<CheckboxLabel>Enable Hotspot Animation</CheckboxLabel>
			</CheckboxLineItem>

			{!!styleData.playback.animateHotspots && <React.Fragment>
			<SectionSubHeader>
			Hotspot Pulse Color
			<Help arrow title="Sets pulse color of product hotspots when animation is enabled."/>
			</SectionSubHeader>
			<SwatchColorPicker 
			property="playback.hotspotPulseColor" 
			color={styleData.playback.hotspotPulseColor} 
			selectedProperty={selectedColorPicker}
			onChange={updateSetting}/>
			</React.Fragment>}

			<SectionSubHeader>
			Player Controls Style
			<Help arrow title="Sets the style of the video player controls."/>
			</SectionSubHeader>
			<PickerMenu
			options={[
				{
					id: "basic",
					name: "Standard",
				},
				{
					id: "minimalist",
					name: "Minimalist",
				},
			]}
			selectedOptionId={styleData.playback.controlsStyle || "basic"}
			onChange={(value)=>{
				updateSetting(value, "playback.controlsStyle")
			}}
			/>

			<SectionSubHeader>
			Scrub Bar Color
			<Help arrow title="Sets color of the player scrub bar, used to display and control the current video time."/>
			</SectionSubHeader>
			<SwatchColorPicker 
			property="playback.scrubBarColor" 
			color={styleData.playback.scrubBarColor} 
			selectedProperty={selectedColorPicker}
			onChange={updateSetting}/>

			</Section>

			<Section name="Header">

				<SectionSubHeader>
				Header Height
				<Help arrow title="Sets the height of the page header."/>
				</SectionSubHeader>
				<PickerMenu
				options={[
					{
						id: "small",
						name: "Small",
						value: 35,
					},
					{
						id: "medium",
						name: "Medium",
						value: 50,
					},
					{
						id: "large",
						name: "Large",
						value: 75,
					},
				]}
				selectedOptionId={styleData.headerHeight || "medium"}
				onChange={(value)=>{
					updateSetting(value, "headerHeight")
				}}
				/>

				<SectionSubHeader>
				Background Color
				<Help arrow title="Sets the background color of the landing page header."/>
				</SectionSubHeader>
				<SwatchColorPicker 
				property="header.color" 
				color={styleData.header.color} 
				selectedProperty={selectedColorPicker}
				onChange={updateSetting}/>

				<SectionSubHeader>
				Logo Image
				<Help arrow title="Sets the logo image displayed in the landing page header."/>
				</SectionSubHeader>
				<FilePicker
				data={styleData.header.logo.data}
				scale={styleData.header.logo.size}
				didSelectImage={(data)=>{
					updateSetting(data, "header.logo.data")
				}}
				didChangeSize={(size)=>{
					updateSetting(size, "header.logo.size")
				}}/>

				<SectionSubHeader>
				Align Logo
				<Help arrow title="Sets the alignment of the header logo image."/>
				</SectionSubHeader>
				<GridSelector
				tabs={[
					{ id: "left", imageSrc: `data:image/svg+xml;base64,${btoa(AlignIcon.left)}`, name: "Left" },
					{ id: "center", imageSrc: `data:image/svg+xml;base64,${btoa(AlignIcon.center)}`, name: "Center" },
				]}
				selected={styleData.header.logo.position}
				didSelectId={(id)=>{
					updateSetting(id, "header.logo.position")
				}}/>

				<TextInput
	              name="logoLink" 
	              label="Logo Link URL"
	              help="The URL of the website the landing page will link to when the logo is clicked.  Leave it blank to disable."
	              defaultValue={styleData.header.logo.href}
	              onChange={(e)=>{
	              	updateSetting(e.target.value, "header.logo.href")
	              }}/>

				<SectionSubHeader>
				Cart Icon
				<Help arrow title="Sets the icon image for the cart button."/>
				</SectionSubHeader>
				<GridSelector
				tabs={[
					{ id: "pushCart", imageSrc: `data:image/svg+xml;base64,${btoa(CartIcon.pushCart)}`, name: "Push Cart" },
					{ id: "pushCart2", imageSrc: `data:image/svg+xml;base64,${btoa(CartIcon.pushCart2)}`, name: "Push Cart 2" },
					{ id: "bag", imageSrc: `data:image/svg+xml;base64,${btoa(CartIcon.bag)}`, name: "Bag" },
					{ id: "bag2", imageSrc: `data:image/svg+xml;base64,${btoa(CartIcon.bag2)}`, name: "Bag 2" },
					{ id: "bag3", imageSrc: `data:image/svg+xml;base64,${btoa(CartIcon.bag3)}`, name: "Bag 3" },
					{ id: "bag4", imageSrc: `data:image/svg+xml;base64,${btoa(CartIcon.bag4)}`, name: "Bag 4" },
					{ id: "bag5", imageSrc: `data:image/svg+xml;base64,${btoa(CartIcon.bag5)}`, name: "Bag 5" },
					{ id: "bag6", imageSrc: `data:image/svg+xml;base64,${btoa(CartIcon.bag6)}`, name: "Bag 6" },
					{ id: "bag7", imageSrc: `data:image/svg+xml;base64,${btoa(CartIcon.bag7)}`, name: "Bag 7" },
				]}
				selected={styleData.header.cart.icon}
				didSelectId={(id)=>{
					updateSetting(id, "header.cart.icon")
				}}/>

				<Slider 
				label="Cart Icon Size"
				onChange={(value, property)=>{
					updateSetting(value, property)
				}} property="header.cart.size" value={styleData.header.cart.size} max={100}/>


				<SectionSubHeader>
				Cart Icon Color
				<Help arrow title="Sets color of the cart icon."/>
				</SectionSubHeader>
				<SwatchColorPicker 
				property="header.cart.color" 
				color={styleData.header.cart.color} 
				selectedProperty={selectedColorPicker}
				onChange={updateSetting}/>


				<SectionSubHeader>
				Cart Count Color
				<Help arrow title="Sets the background color of the cart badge count, which indicates how many items are in the cart.  Add a product to your cart to view it."/>
				</SectionSubHeader>
				<SwatchColorPicker 
				property="colors.accentColor" 
				color={styleData.colors.accentColor} 
				selectedProperty={selectedColorPicker}
				onChange={updateSetting}/>
			</Section>

			<Section name="Products List">
				<SectionSubHeader>
				Product List
				<Help arrow title="Select which products to display in the carousel.  Defaults to show products shown in the linked video."/>
				</SectionSubHeader>
				<ProductsList 
				onEditList={()=> setEditingProducts(true)}
				products={[...videoProducts, ...Object.values(styleData.carousel.products)]}/>

				<SectionSubHeader>
				Product Listing Size
				<Help arrow title="Sets the size of products shown in your products list."/>
				</SectionSubHeader>
				<PickerMenu
				options={[
					{
						id: "small",
						name: "Small",
					},
					{
						id: "medium",
						name: "Medium",
					},
					{
						id: "large",
						name: "Large",
					},
				]}
				selectedOptionId={styleData.carousel.itemSize || "medium"}
				onChange={(value)=>{
					updateSetting(value, "carousel.itemSize")
				}}
				/>
			</Section>
	
			<Section name="Product Page">
				<SectionSubHeader>
				Variant Picker Style
				<Help arrow title="Sets the style of the variant/option picker component."/>
				</SectionSubHeader>
				<PickerMenu
				options={[
					{
						id: "dropdown",
						name: "Dropdown",
					},
					{
						id: "dropdown-rounded",
						name: "Rounded Dropdown",
					},
					{
						id: "boxes",
						name: "Boxes",
					},
				]}
				selectedOptionId={styleData.productView.variantPickerStyle || "boxes"}
				onChange={(value)=>{
					updateSetting(value, "productView.variantPickerStyle")
				}}
				/>

				<SectionSubHeader>
				Border Style
				<Help arrow title="Sets shape of the product view."/>
				</SectionSubHeader>
				<GridSelector
				tabs={[
					{ id: "0px", imageSrc: `/squared-view.svg`, name: "Squared" },
					{ id: "15px", imageSrc: `/rounded-view.svg`, name: "Rounded" },
				]}
				selected={styleData.productView.borderRadius}
				didSelectId={(id)=>{
					updateSetting(id, "productView.borderRadius")
				}}/>

				<br/>

				<CheckboxLineItem>
				<Switch
				small
				enabled={styleData.productView.addToCart.inline}
				onChange={val=>{
					updateSetting(val, "productView.addToCart.inline")
				}}
				style={{color:'white'}}
				size="small"
				name="checked"
				/>
				<CheckboxLabel>
				Show Cart Button Inline
				</CheckboxLabel>
			</CheckboxLineItem>

			</Section>

			<Section name="Fonts" helpCaption="Sets fonts used for header and body text throughout the landing page.">
				<SectionSubHeader>Heading Font</SectionSubHeader>
				<FontPicker selectedValue={styleData.headerText.font} onSelect={(font)=>{
					updateSetting(font, "headerText.font")
				}}/>

				<SectionSubHeader>Primary Font</SectionSubHeader>
				<FontPicker selectedValue={styleData.bodyText.font} onSelect={(font)=>{
					updateSetting(font, "bodyText.font")
				}}/>

			</Section>

			<Section name="Buttons" helpCaption="Sets style options used for buttons throughout the landing page.  Open a product page or view the cart to see a button.">
				<SectionSubHeader>
				Style
				<Help arrow title="Choose a rectangular, rounded, or pill shape for all buttons."/>
				</SectionSubHeader>
				<GridSelector
				tabs={[
					{ id: "square", imageSrc: `data:image/svg+xml;base64,${btoa(ButtonIcon.square)}`, name: "Square" },
					{ id: "rounded", imageSrc: `data:image/svg+xml;base64,${btoa(ButtonIcon.rounded)}`, name: "Rounded" },
					{ id: "pill", imageSrc: `data:image/svg+xml;base64,${btoa(ButtonIcon.pill)}`, name: "Pill" },
				]}
				selected={styleData.button.style}
				didSelectId={(id)=>{
					updateSetting(id, "button.style")
				}}/>

				<SectionSubHeader>
				Font
				<Help arrow title="Sets the font used for all buttons throughout the landing page."/>
				</SectionSubHeader>
				<FontPicker selectedValue={styleData.button.font} onSelect={(font)=>{
					updateSetting(font, "button.font")
				}}/>

				<SectionSubHeader>Text Color</SectionSubHeader>
				<SwatchColorPicker 
				property="button.textColor" 
				color={styleData.button.textColor} 
				selectedProperty={selectedColorPicker}
				onChange={updateSetting}/>

				<SectionSubHeader>Background Color</SectionSubHeader>
				<SwatchColorPicker 
				property="button.color" 
				color={styleData.button.color} 
				selectedProperty={selectedColorPicker}
				onChange={updateSetting}/>
			</Section>

			<Section name="Legal">
			<TextInput
              name="copyrightHolder" 
              label="Copyright Holder"
              help="The name of the copyright holder to be displayed in the landing page footer.  (© 2021...)"
              defaultValue={styleData.legal.copyright}
              onChange={(e)=>{
              	updateSetting(e.target.value, "legal.copyright")
              }}/>
            <TextInput
              name="privacyUrl" 
              label="Privacy Policy URL"
              help="Enter the URL for your company's Privacy Policy here."
              defaultValue={styleData.legal.privacyUrl}
              onChange={(e)=>{
              	updateSetting(e.target.value, "legal.privacyUrl")
              }}/>
            <TextInput
              name="termsUrl" 
              label="Terms of Service URL"
              help="Enter the URL for your company's Terms of Service here."
              defaultValue={styleData.legal.termsUrl}
              onChange={(e)=>{
              	updateSetting(e.target.value, "legal.termsUrl")
              }}/>
            <TextInput
              name="cookiesUrl" 
              label="Cookie Policy URL"
              help="Enter the URL for your company's Cookie Policy here."
              defaultValue={styleData.legal.cookiesUrl}
              onChange={(e)=>{
              	updateSetting(e.target.value, "legal.cookiesUrl")
              }}/>
			</Section>


			<Section name="Analytics">

			<SectionSubHeader>
              General
              </SectionSubHeader>
              <CheckboxLineItem>
				<Switch
				small
				enabled={styleData.analytics.showCookieConsent}
				onChange={val=>{
					updateSetting(val, "analytics.showCookieConsent")
				}}
				style={{color:'white'}}
				size="small"
				name="checked"
				/>
				<CheckboxLabel>
				Show Cookies Consent Banner
				</CheckboxLabel>
			</CheckboxLineItem>

			<SectionSubHeader>
			Freckle Analytics
			<Help arrow title="Enable Freckle to collect data on your behalf about your customers."/>
			</SectionSubHeader>
			<CheckboxLineItem>
				<Switch
				small
				enabled={styleData.analytics.freckle.enabled}
				onChange={val=>{
					updateSetting(val, "analytics.freckle.enabled")
				}}
				style={{color:'white'}}
				size="small"
				name="checked"
				/>
				<CheckboxLabel>Enabled</CheckboxLabel>
			</CheckboxLineItem>

			<SectionSubHeader>
			Facebook Ads Manager
			<Help arrow title="Enable data collection on your behalf by Facebook Ads Manager."/>
			</SectionSubHeader>
			<CheckboxLineItem>
				<Switch
				small
				enabled={styleData.analytics.facebook.enabled}
				onChange={val=>{
					updateSetting(val, "analytics.facebook.enabled")
				}}
				style={{color:'white'}}
				size="small"
				name="checked"
				/>
				<CheckboxLabel>Enabled</CheckboxLabel>
			</CheckboxLineItem>

			<TextInput
              name="fbpixelid" 
              label="Facebook Pixel ID"
              help="The Facebook pixel is a small piece of code that goes on your website. With the pixel, you can bring back website visitors, or find new people who will likely make a purchase or become a lead."
              defaultValue={styleData.analytics.facebook.fbpixelid || ""}
              onChange={(e)=>{
              	updateSetting(e.target.value, "analytics.facebook.fbpixelid")
              }}/>


            <SectionSubHeader>
			Google Analytics
			<Help arrow title="Enable data collection on your behalf by Google Analytics."/>
			</SectionSubHeader>
			<CheckboxLineItem>
				<Switch
				small
				enabled={styleData.analytics.google.enabled}
				onChange={val=>{
					updateSetting(val, "analytics.google.enabled")
				}}
				style={{color:'white'}}
				size="small"
				name="checked"
				/>
				<CheckboxLabel>Enabled</CheckboxLabel>
			</CheckboxLineItem>

			<TextInput
              name="fbpixelid" 
              label="Google Analytics Tracking ID"
              help="This unique ID is used to identify your landing page to Google.  It should look something like UA-123456-12."
              defaultValue={styleData.analytics.google.trackid || ""}
              onChange={(e)=>{
              	updateSetting(e.target.value, "analytics.google.trackid")
              }}/>


			</Section>

			<ShimSection/>

			</React.Fragment>
		)
	}

	const micrositeFrame = () => {
		if(!!!videoData) return <span/>
		return <MicrositeFrame 
				ref={iframeRef} 
				mode={mode}
				src={micrositeUrl()}
				onLoad={()=>{ 
					setTimeout(()=>{
						reloadMicrosite(!clearedMicrosite)
						setClearedMicrosite(true) 
					}, 250)
				}}/>
	}

	const publishEnabled = !!microsite && (lastPublishHash !== lastSaveHash || microsite.project.updatedAt > updatedAt)

	let extraProducts = []
	if(!!styleData){
		extraProducts = Object.values(styleData.carousel.products)
	}

	return (
		<Container>
		<Header 
		onPublishMicrosite={async ()=>{
			if(publishing) return
			setPublishing(true)
			try {
				const res = await publishMicrosite(microsite.id)
				setLastPublishHash(res.checksum)

				document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text": `${microsite.title} has been published!`, "type":"success"}}))
				setPublishedAt(new Date())
			} catch(e) {
				document.dispatchEvent(new CustomEvent("postMessage", {detail: {"text": "Publish failed.", "type":"error"}}))
			}
			setPublishing(false)
		}}
		showsPublishButton = {true}
		publishing = {publishing}
		publishEnabled = {
			publishEnabled
		}
		showsBack = {true} 
		currentVideo={microsite} 
		backDest="/pages"/>

		<EditingContext>
			<LeftPane>
			<ViewModePicker 
			mode={mode}
			onChange={newMode=>{
				setMode(newMode)
			}}/>
			{ micrositeFrame() }
			</LeftPane>
			<RightPane>
			{ settingsPane() }
			</RightPane>
		</EditingContext>

		<OverlayContainer active={editingProducts}>
			<CSSTransition in={editingProducts} timeout={500} classNames="add-video" unmountOnExit>
			<ProductsPicker
			onCancel={()=> setEditingProducts(false) } 
			productsInVideo={videoProducts} 
			extraProducts={extraProducts}
			onSelectProduct={(selectedProduct)=>{
				let newList = [...Object.values(styleData.carousel.products), selectedProduct]
				updateSetting(newList, "carousel.products")
			}}
			onDeleteProduct={(selectedProduct)=>{
				let newList = [...Object.values(styleData.carousel.products)].filter(product=> product.id !== selectedProduct.id)
				updateSetting(newList, "carousel.products")
			}}/>
			</CSSTransition>
		</OverlayContainer>

		</Container>
	)
}


