import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { styled as muiStyled } from '@material-ui/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useAppContext, AppAction } from '../Context/AppContext';
import ReactMarkdown from 'react-markdown/react-markdown.min';
import { EditorTutorialState, EditorTutorialContent } from './tutorial';
import Button from '../Button'

import SimpleTooltip from './Tooltip'

const CustomTooltip = muiStyled(Tooltip)(props=>({
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    fontFamily: "Signika",
    // boxShadow: "0px 0px 18px 0px rgba(0,0,0,0.15)",
    borderRadius: "6px",
    padding: 0,
    minWidth: props.minWidth + "px",
}));


const MarkdownContainer = styled.div`

`

const SkipButton = styled.button`
font-family: "Signika";
font-size: 12px;
font-width: 700;
padding: 5px 10px;
background-color: #1f1f1f;
color: rgba(255,255,255,0.5);
border-radius: 1000px;
outline: none;
border: none;
position: absolute;
top: -35px;
right: 0;
cursor: pointer;
transition: 0.15s;

&:hover {
  color: rgba(255,255,255,1.0);
  background-color: #3f3f3f;
}
`

const MarkdownContent = styled.div`
padding: 10px 15px 5px 15px;
background-color: white;

border-radius: 6px 6px 0 0;

h1,h2,h3,h4,h5 {
  margin: 0;
  padding: 5px 0 5px 0;
}
p {
  margin: 0;
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

img {
  max-width: 100%;
  margin: 0;
  padding: 0;
}
`
const Footer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
background-color: #eee;
padding: 10px 10px;
// margin: 0 10px;
border-radius: 0 0 6px 6px;
`

const Label = styled.div`
text-transform: uppercase;
font-size: 12px;
font-weight: 900;
font-family: "Signika";
color: #E73C7E;
padding-top: 2px;
`

const NoticeAnimation = keyframes`
0%, 100% {
  background-color: #E73C7E;
  box-shadow: none;
}
50% {
  background-color: #e94986;
  box-shadow: #E73C7E 0 0px 20px -2px;
}
`

const Shimmy = keyframes`
0% {
  transform: rotateZ(0deg);
}
40% {
  transform: rotateZ(0deg);
}
45% {
  transform: rotateZ(-20deg) scale(1.1);
  box-shadow: none;
}
50% {
  transform: rotateZ(20deg) scale(1.1);
}
55% {
  transform: rotateZ(-20deg) scale(1.1);
  box-shadow: 0 0 5px 5px rgba(255,255,255,0.25);
}
60% {
  transform: rotateZ(20deg) scale(1.2);
}
65% {
  transform: rotateZ(-20deg) scale(1.2);
}
70% {
  transform: rotateZ(20deg) scale(1.2);
}
75% {
  transform: rotateZ(-20deg) scale(1.2);
}
80% {
  transform: rotateZ(20deg) scale(1.2);
}
85% {
  transform: rotateZ(-20deg) scale(1.2);
}
90% {
  transform: rotateZ(20deg) scale(1.2);
}
95% {
  transform: rotateZ(-20deg) scale(1.1);
  box-shadow: 0 0 5px 5px rgba(255,255,255,0.25);
}
100% {
  transform: rotateZ(0deg) scale(1);
  box-shadow: none;
}
`

const Notice = styled.div`
// position: absolute;
// bottom: -40px;
// left: 0;
background-color: #E73C7E;
color: white;
width: calc(100% - 27.5px);
padding: 0 15px 0 12.5px;
height: 35px;
border-radius: 0 0 6px 6px;
display: flex;
justify-content: flex-start;
align-items: center;
font-weight: 700;
animation-name: ${NoticeAnimation};
 animation-duration: 3s;
 animation-iteration-count: infinite;
 margin-top: 5px;

 img {
  float: left;
  height: 12.5px;
  padding: 5px;
  margin-right: 10px;
  border-radius: 1000px;
  background-color: rgba(255,255,255,0.15);

  will-change: transform;
  animation: ${Shimmy} 2s ease-in-out forwards;
  animation-iteration-count: infinite;
 }
`

const TutorialTooltip = React.forwardRef((props, ref) => {
  const context = useAppContext();
  const [_, _forceReload] = useState(null)
  const forceReload = () => _forceReload(Math.random())

  const [step, setStep] = useState(props.steps[0])
  const [enabled, setEnabled] = useState(false)

  useEffect(()=>{
    if (props.transitionState == 'entered') {
      setTimeout(()=>{
        forceReload()
      }, 250)
    }
  }, [props.transitionState])

  useEffect(()=>{
    const transitionComplete = (!props.transitionState || props.transitionState == 'entered')

    if (props.steps.includes(context.state.editorTutorialState) && transitionComplete) {
      setStep(EditorTutorialContent[context.state.editorTutorialState])
      setEnabled(true)
    } else {
      setEnabled(false)
    }
  }, [context.state.editorTutorialState, props.transitionState])

  const getFooter = () => {
    if (step.nextStep) {
      return(<Footer>
      { 
        step.lastStep ? <Button
        onClick={()=>{
          context.dispatch({ type: AppAction.setEditorTutorialState, payload: step.lastStep })
        }}
        backgroundColor="#dbdbdb"
        color="#5f5f5f"
        hoverBackgroundColor="#E73C7E"
        hoverTextColor="white"
        small
        >
        Back
        </Button> : <span/>
      }
      { 
        step.nextStep ? <Button 
        onClick={()=>{
          context.dispatch({ type: AppAction.setEditorTutorialState, payload: step.nextStep })
        }}
        small
        >
        { step.nextStepLabel ? step.nextStepLabel : "Next" }
        </Button> : <span/>
      }
      </Footer>)
    }
    return null
  }

  const getNotice = () => {
    if (step.notice) {
      return (
        <Notice>
        <img src="/bell.svg"/>
        { step.notice }
        </Notice>
      )
    }
    return null
  }

	return (
    <React.Fragment>
  		<SimpleTooltip
      width={step.width || 350}
      key={step.id}
      passKey={step.id}
      {...props}
      interactive
      arrow
      open={enabled}
  		tooltip={
        <React.Fragment>

        <SkipButton
        onClick={()=>{
          context.dispatch({ type: AppAction.setEditorTutorialState, payload: EditorTutorialState.tutorialDone })
        }}>Skip Tutorial</SkipButton>

        <MarkdownContainer>
        <MarkdownContent>
        <Label>{ step.label }.</Label>
        <ReactMarkdown
        components={{
          img: ({node, ...props}) => {
            const { src } = node.properties
            function h() { return <img src={src}/> }
            return h()
          },
        }}>
        { step.markdown }
        </ReactMarkdown>
        
        </MarkdownContent>
        { getFooter() }

        { getNotice() }
        </MarkdownContainer>
        </React.Fragment>
      }>
      { props.children }
  		</SimpleTooltip>
    </React.Fragment>
	)
})

export default TutorialTooltip;