import React from 'react'
import Help from '../Help'
import TimelineItem from './TimelineItem'
import styled from 'styled-components'
import { EditorContext, useEditorContext, ContextAction } from './Context/EditorContext'

import 'datejs'


const Container =  styled.div`
	display: flex;
	flex-direction: column;
	align-items: space-between;
	justify-content: start;
	flex: 1;
	padding: 10px 25px;
	overflow: scroll;
`

const Title = styled.div`
	display:flex;
	align-items:center;
	margin: 0;
	margin-top:15px;
	font-size: 0.9em;
	font-weight: 700;
	padding-bottom:10px;
	user-select: none;
	color: #7f7f7f;
`

const ZeroState = styled.div`
	color: white;
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const H4 = styled.h4`
	margin: 0;
`

const P = styled.p`
	font-size: 14px;
	color: #777777;
	margin: 10px 30px;
`

export default function Timeline(props) {
	const context = useEditorContext()
	
	const getTimelineItems = () => {
		const group = context.state.groups.find((group)=>{ return context.state.selectedGroupId === group.id })
		if(group == null) return <span/>
		const frames = group.locations
		if(frames.length === 0) {
			return(
				<ZeroState>
					<H4>No Keyframes</H4>
					<P>Click on the product as it appears in the video player to add keyframes.</P>
				</ZeroState>
			)
		}
		return frames.map((loc)=>{
			return(
				<TimelineItem 
				key={loc.ts} 
				loc={loc} 
				duration={context.state.durationTime} 
				updateMark={updateMark} 
				delete={deleteTimelineItem} 
				selected={timelineItemSelected}/>
			)
		})
	}

	const updateMark = (loc) => {
		loc.t = (loc.t===2) ? 1 : 2
		context.dispatch({type: ContextAction.addKeyframe, payload: loc})
	}

	const deleteTimelineItem = (loc) => {
		context.dispatch({type: ContextAction.deleteFreckle, payload: loc})
	}

	const timelineItemSelected = (loc) => {
		context.dispatch({type: ContextAction.setRequestedPlayheadTime, payload: loc.ts})
	}


	return (
		<Container>
			<Title>
			Keyframes
			<Help arrow title="Keyframes mark where a product is located in your video at a particular time.  As your product moves, add keyframes to follow its movement."/>
			</Title>
			{ getTimelineItems() }
		</Container>			
	)

}


