export const EditorTutorialState = Object.freeze({
	addProduct: "addProduct",
	addSegment: "addSegment",
	addSegmentHotkey: "addSegmentHotkey",
	editSegment: "editSegment",
	enableHotspots: "enableHotspots",
	tutorialDone: "tutorialDone",
});

export const EditorTutorialContent = Object.freeze({
	[EditorTutorialState.addProduct]: {
		label: "Step 1",
		markdown: 
`## Add Products
Before you can tag products, you must add them to the Timeline.

To add, hover over products in the Product Library and click ADD.

![Add](/add-visible-range-demo.png)`,
		nextStep: EditorTutorialState.addSegment,
	},
	[EditorTutorialState.addSegment]: {
		label: "Step 2",
		markdown: 
`## Tag Products
Tag products to mark them visible.  Each tag has start and end time.

Click ADD TAG to add a tag at the current video time. 

Drag and resize tag segments in Timeline to adjust the start and end times.

As as many tags as you need!

![Tags](/add-tag-gif.gif)
`,
		lastStep: EditorTutorialState.addProduct,
		nextStep: EditorTutorialState.tutorialDone,
		width: 500,
		nextStepLabel: "Finished",
	},
	[EditorTutorialState.editSegment]: {
		label: "Step 3",
		markdown: 
`## Edit a Range
Click and drag a time range to move it.  Click and drag the edges to resize.

![Edit Range](/edit-visible-range-demo.svg)`,
		lastStep: EditorTutorialState.addSegment,
		nextStep: EditorTutorialState.addSegmentHotkey,
		width: 500,
	},
	[EditorTutorialState.addSegmentHotkey]: {
		label: "Step 4",
		markdown: 
`## Tag Products Faster
You can also add a time range by pressing the Control key (Command on Mac) and clicking on the timeline.

![Time Ranges](/visible-ranges-hotkey-demo.svg)`,
		lastStep: EditorTutorialState.editSegment,
		nextStep: EditorTutorialState.tutorialDone,
		width: 500,
		nextStepLabel: "Finished",
	},
});